const ModuleClasses = { 
  ASSOCIATIONS_CARD                        : "associationCard", 
  ATTRIBUTE_BOX                            : "attributeBox",
  ATTRIBUTE_CARD                           : "attributeCard",
  ATTRIBUTE_NAME_BOX                       : "attributeNameBox",
  BORDER_DIVIDER                           : "borderDivider",
  BUILDER_TAB                              : "builderTab",
  BUILDER_TAB_BOX                          : "builderTabBox",
  BUILDER_TAB_GROUP                        : "builderTabGroup",
  BUILDER_TAB_NAME                         : "builderTabName",
  CARD_HEADER                              : "cardHeader",
  ICON_SELECTOR_MAIN_CONTAINER             : "iconSelectorMainContainer",
  RENDER_RIGHT_DRAWER_CONTAINER            : "renderRightDrawerContainer",
  RENDER_RIGHT_DRAWER_ICON_CONTAINER       : "renderRightDrawerIconContainer",
  RENDER_TOOL_BOX_MANAGER_CONTAINER        : "renderToolBoxManagerContainer",
  RENDER_TOOL_BOX_MANAGER_CONTENT_CONTAINER: "renderToolBoxManagerContentContainer",
  RENDER_TOOL_BOX_MANAGER_ICON_CONTAINER   : "renderToolBoxManagerIconContainer",
  TEST_WRAPPID_STYLE_CLASS                 : "testWrappidStyleClass",
  TEXT_SELECTOR_MAIN_CONTAINER             : "textSelectorMainContainer"
};

export default ModuleClasses;
