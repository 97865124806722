import XXLargeappBuilderStyles from "../../modules/app-builder/styles/XXLargeModuleStyles";
import XXLargeappointmentStyles from "../../modules/appointment/styles/XXLargeModuleStyles";
import XXLargeauthStyles from "../../modules/auth/styles/XXLargeModuleStyles";
import XXLargeclinicStyles from "../../modules/clinic/styles/XXLargeModuleStyles";
import XXLargecommunicationStyles from "../../modules/communication/styles/XXLargeModuleStyles";
import XXLargedashboardStyles from "../../modules/dashboard/styles/XXLargeModuleStyles";
import XXLargedataManagementStyles from "../../modules/data-management/styles/XXLargeModuleStyles";
import XXLargepatientStyles from "../../modules/patient/styles/XXLargeModuleStyles";
import XXLargeprescriptionStyles from "../../modules/prescription/styles/XXLargeModuleStyles";
import XXLargerxprofileStyles from "../../modules/rxprofile/styles/XXLargeModuleStyles";
import XXLargerxsettingsStyles from "../../modules/rxsettings/styles/XXLargeModuleStyles";
import XXLargesupportStyles from "../../modules/support/styles/XXLargeModuleStyles";
import XXLargeuserManagementStyles from "../../modules/user-management/styles/XXLargeModuleStyles";

const XXLargeModulesStyles = {
...new XXLargeappBuilderStyles().style,
...new XXLargeappointmentStyles().style,
...new XXLargeauthStyles().style,
...new XXLargeclinicStyles().style,
...new XXLargecommunicationStyles().style,
...new XXLargedashboardStyles().style,
...new XXLargedataManagementStyles().style,
...new XXLargepatientStyles().style,
...new XXLargeprescriptionStyles().style,
...new XXLargerxprofileStyles().style,
...new XXLargerxsettingsStyles().style,
...new XXLargesupportStyles().style,
...new XXLargeuserManagementStyles().style,
};
 export default XXLargeModulesStyles