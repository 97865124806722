import { ValidationsRegistry as AppBuilderValidationsRegistry } from "./app-builder/validations.registry";
import { ValidationsRegistry as AppointmentValidationsRegistry } from "./appointment/validations.registry";
import { ValidationsRegistry as AuthValidationsRegistry } from "./auth/validations.registry";
import { ValidationsRegistry as ClinicValidationsRegistry } from "./clinic/validations.registry";
import { ValidationsRegistry as CommunicationValidationsRegistry } from "./communication/validations.registry";
import { ValidationsRegistry as DashboardValidationsRegistry } from "./dashboard/validations.registry";
import { ValidationsRegistry as DataManagementValidationsRegistry } from "./data-management/validations.registry";
import { ValidationsRegistry as PatientValidationsRegistry } from "./patient/validations.registry";
import { ValidationsRegistry as RxprofileValidationsRegistry } from "./rxprofile/validations.registry";
import { ValidationsRegistry as RxsettingsValidationsRegistry } from "./rxsettings/validations.registry";
import { ValidationsRegistry as SupportValidationsRegistry } from "./support/validations.registry";
import { ValidationsRegistry as UserManagementValidationsRegistry } from "./user-management/validations.registry";

export default {
...AppBuilderValidationsRegistry,
...AppointmentValidationsRegistry,
...AuthValidationsRegistry,
...ClinicValidationsRegistry,
...CommunicationValidationsRegistry,
...DashboardValidationsRegistry,
...DataManagementValidationsRegistry,
...PatientValidationsRegistry,
...RxprofileValidationsRegistry,
...RxsettingsValidationsRegistry,
...SupportValidationsRegistry,
...UserManagementValidationsRegistry,
}