import { Dispatch } from "redux";

import { ToolbarData } from "./Toolbar.data";
import { REQUEST_FOR_REVIEW } from "../../../constants/AppBuilder.constant";
import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import ToolbarFactory from "../../../factory/Toolbar.factory";
import { EntityData } from "../entity/Entity.data";

export class RequestForReviewToolbarData<TSchema> extends ToolbarData<TSchema>{
  private static actionEvent = [{ onClick: "requestForReviewOnClick" }];
  private static actionIcon = { iconName: "rate_review", iconType: "__IconTypes.MATERIAL_ICON" };
  constructor(
    toolbarId: string,
    entityData: EntityData<TSchema>,
    builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES],
    dispatch: Dispatch
  ){
    super(
      toolbarId,
      ToolbarFactory.ACTION.REQUEST_FOR_REVIEW, 
      REQUEST_FOR_REVIEW,
      RequestForReviewToolbarData.actionEvent,
      RequestForReviewToolbarData.actionIcon,
      entityData,
      builderType,
      dispatch
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onclickAction(entityData: EntityData<TSchema>, builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES], dispatch: Dispatch): void {
    // dispatch({ payload: { entityData }, type: "SAVE_ENTITY_DATA_SUCCESS" });
    // eslint-disable-next-line no-console
    console.log("RequestForReview Action performed with", entityData);
  }
}