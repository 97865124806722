
import React from "react";

import {
  CoreTypographyCaption,
  CoreClasses,
  CoreGrid,
  CoreBox,
  CoreAvatar,
  CoreIconText,
  CoreTypographySubtitle2,
  __IconTypes,
  CoreComponent,
  CoreTypographyBody2,
  CoreStack,
  CoreTypographyBody1,
  apiRequestAction,
  HTTP
} from "@wrappid/core";
import { useDispatch } from "react-redux";

import { getDate } from "../utils/default.util";

export default function AppointmentDetailsCard(props) {
  const {
    patientId,
    startTime,
    endTime,
    date,
    Patient = {},
    Clinic = {},
  } = props || {};
  const { initData = {} } = props || {};
  const dispatch = useDispatch();

  const data = initData;
  const [personMeta, setPersonMeta] = React.useState({});
  const getPersonMeta = ({ data }) => {
    
    setPersonMeta(data);
  };
  
  React.useEffect(() => {
    
    if(data && data.patientId){
      let endpoint = `/data/meta/PersonMetas/${data.patientId}`;

      dispatch(apiRequestAction(HTTP.GET, endpoint, true, {}, null, null, getPersonMeta));

      // eslint-disable-next-line etc/no-commented-out-code
      // dispatch(apiRequestAction(HTTP.GET, endpoint, {}, PATIENT_DATA_ENTRY));
    }
  }, [data]);

  const formattedDate = getDate(date);

  return (
    <>
      <CoreGrid styleClasses={[CoreClasses.PADDING.PL1]}>
        <CoreBox
          gridProps={{ gridSize: 1 }}
          styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
        >

          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
            src={Patient?.photoUrl}
          />
        </CoreBox>

        <CoreBox gridProps={{ gridSize: 11 }} styleClasses={[CoreClasses.PADDING.PL2]}>
          <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_SEMIBOLD]}>
            {personMeta?.firstName || personMeta?.middleName || personMeta?.lastName 
              ? [personMeta?.firstName, personMeta?.middleName, personMeta?.lastName].filter(Boolean).join(" ")
              : "Unknown Patient"}
          </CoreTypographyBody1>

          <CoreTypographyCaption styleClasses={[CoreClasses.MARGIN.MB1]}>
            {"MRN: "}
            
            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}
                  
            >
              {personMeta?.profileId || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>

          <CoreGrid >
            <CoreIconText
              type={__IconTypes.MATERIAL_OUTLINED_ICON}
              icon="event_available"
              text={formattedDate}
              gridProps={{ gridSize: { md: 6 } }}
            />

            <CoreIconText
              type={__IconTypes.MATERIAL_OUTLINED_ICON}
              icon="query_builder"
              text={`${startTime?.substring(0, 5)} - ${endTime?.substring(0, 5)}`}
              gridProps={{ gridSize: { md: 6 } }}
              
            />
          </CoreGrid>

          <CoreStack direction="row" spacing={1} styleClasses={[CoreClasses.MARGIN.MT1]}>
            {Clinic?.photoUrl && (
              <CoreAvatar
                styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]}
                src={Clinic?.photoUrl}
              />
            )}

            <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.MT1, CoreClasses.COLOR.TEXT_SECONDARY_DARK]} limitChars={12} hideSeeMore={true}>
              {Clinic?.name || "No Clinic"}
            </CoreTypographySubtitle2>
          </CoreStack>
        </CoreBox>
      </CoreGrid>

      <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.ML2, CoreClasses.MARGIN.MT3, CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>{"Past Appointments"}</CoreTypographyBody2>

      <CoreBox styleClasses={[CoreClasses.MARGIN.ML2, CoreClasses.MARGIN.MT3]}>
        <CoreComponent componentName={"PastAppointments"} patientID={patientId} />
      </CoreBox>
    </>

  );
}