import { PROPERTY_TOOLBOX } from "../../../../constants/AppBuilder.constant";
import ToolBoxFactory from "../../../../factory/ToolBox.factory";
import { ToolBoxData } from "../ToolBox.data";

/**
 * Represents the property toolbox data.
 * Extends the base `ToolBoxData` class to provide configurations for a property toolbox.
 */
export class PropertyToolBoxData extends ToolBoxData {
  constructor(
    toolboxId: string,
    toolboxTitle = "Properties",
    toolboxExpand = true,
    toolboxResize = "both",
    renderedComponent = PROPERTY_TOOLBOX 
  ) {
    super(
      toolboxId,
      ToolBoxFactory.VARIANTS.PROPERTY,
      toolboxTitle,
      toolboxExpand,
      toolboxResize
    );
    this.setToolboxRenderedComponent(renderedComponent);
  }
  
}
