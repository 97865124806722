import { DefaultCoreStyles } from "@wrappid/core";
import { DefaultUtilityStyles } from "@wrappid/styles";

export default class DefaultModuleStyles extends DefaultCoreStyles {
  defaultUtilityStyles = new DefaultUtilityStyles().style;
  constructor(){
    super();
    this.style = {
      associationCard: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.border,
        ...this.defaultUtilityStyles.borderRounded2,
        ...this.defaultUtilityStyles.shadow,
        ...this.defaultUtilityStyles.flexDirectionColumn,
        ...this.defaultUtilityStyles.borderColorGrey400,
        
      },
      
      attributeBox: {
        ...this.defaultUtilityStyles.cursorPointer,
        ...this.defaultUtilityStyles.borderBottom,
        ...this.defaultUtilityStyles.p0_5,
        ...this.defaultUtilityStyles.borderColorGrey300,
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.justifyContentCenter
      },
      
      attributeCard: {
        ...this.defaultUtilityStyles.border,
        ...this.defaultUtilityStyles.shadow,
        ...this.defaultUtilityStyles.textStart,
        ...this.defaultUtilityStyles.borderRounded2,
        ...this.defaultUtilityStyles.borderColorGrey400,
        ...this.defaultUtilityStyles.w25,
        ...this.defaultUtilityStyles.m1
      },
      
      attributeNameBox: {
        ...this.defaultUtilityStyles.textWeightBold,
        ...this.defaultUtilityStyles.textStart,
        ...this.defaultUtilityStyles.w50,
        ...this.defaultUtilityStyles.textTruncate

      },
      
      borderDivider: {
        ...this.defaultUtilityStyles.borderBottom, 
        ...this.defaultUtilityStyles.borderColorGrey400,
      },
      builderTab: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.justifyContentCenter,
        ...this.defaultUtilityStyles.alignItemsCenter,
        ...this.defaultUtilityStyles.flexWrapNoWrap,
        ...this.defaultUtilityStyles.px1,
        ...this.defaultUtilityStyles.cursorDefault,
        ...this.defaultUtilityStyles.borderRounded1
      },
      builderTabBox: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.justifyContentCenter,
        ...this.defaultUtilityStyles.alignItemsCenter,
        maxWidth: "80px"
      },
      builderTabGroup: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.flexWrapNoWrap,
        ...this.defaultUtilityStyles.overflowXScroll
      },
      
      builderTabName: {
        ...this.defaultUtilityStyles.textTruncate,
        ...this.defaultUtilityStyles.textSecondary,
        ...this.defaultUtilityStyles.pl1
      },

      cardHeader: {
        ...this.defaultUtilityStyles.borderBottom,
        ...this.defaultUtilityStyles.borderWidth2,
        ...this.defaultUtilityStyles.borderColorGrey300,
        ...this.defaultUtilityStyles.textCenter,
        ...this.defaultUtilityStyles.p1,
      },
      /**************************************************
       * Using defaultUtilityStyles example
       *************************************************/
      iconSelectorMainContainer: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.justifyContentCenter,
        ...this.defaultUtilityStyles.alignItemsCenter,
        ...this.defaultUtilityStyles.gap3
      },
      
      renderRightDrawerContainer: {
        ...this.defaultUtilityStyles.vh92,
        ...this.defaultUtilityStyles.overflowYScroll,
        ...this.defaultUtilityStyles.borderStart,
        ...this.defaultUtilityStyles.borderColorGrey400
      },
      renderRightDrawerIconContainer: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.justifyContentSpaceBetween,
        ...this.defaultUtilityStyles.alignItemsCenter,
        ...this.defaultUtilityStyles.p1
      },
      renderToolBoxManagerContainer       : { ...this.defaultUtilityStyles.p2 },
      renderToolBoxManagerContentContainer: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.alignItemsCenter,
        ...this.defaultUtilityStyles.justifyContentFlexStart,
        ...this.defaultUtilityStyles.my2
      },
      renderToolBoxManagerIconContainer: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.gap2
      },
      testWrappidStyleClass    : { ...this.style.devBorder },
      textSelectorMainContainer: {
        ...this.defaultUtilityStyles.displayFlex,
        ...this.defaultUtilityStyles.justifyContentCenter,
        ...this.defaultUtilityStyles.alignItemsCenter,
        ...this.defaultUtilityStyles.gap3
      }
    };
  }
}