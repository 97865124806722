import DefaultappBuilderStyles from "../../modules/app-builder/styles/DefaultModuleStyles";
import DefaultappointmentStyles from "../../modules/appointment/styles/DefaultModuleStyles";
import DefaultauthStyles from "../../modules/auth/styles/DefaultModuleStyles";
import DefaultclinicStyles from "../../modules/clinic/styles/DefaultModuleStyles";
import DefaultcommunicationStyles from "../../modules/communication/styles/DefaultModuleStyles";
import DefaultdashboardStyles from "../../modules/dashboard/styles/DefaultModuleStyles";
import DefaultdataManagementStyles from "../../modules/data-management/styles/DefaultModuleStyles";
import DefaultpatientStyles from "../../modules/patient/styles/DefaultModuleStyles";
import DefaultprescriptionStyles from "../../modules/prescription/styles/DefaultModuleStyles";
import DefaultrxprofileStyles from "../../modules/rxprofile/styles/DefaultModuleStyles";
import DefaultrxsettingsStyles from "../../modules/rxsettings/styles/DefaultModuleStyles";
import DefaultsupportStyles from "../../modules/support/styles/DefaultModuleStyles";
import DefaultuserManagementStyles from "../../modules/user-management/styles/DefaultModuleStyles";

const DefaultModulesStyles = {
...new DefaultappBuilderStyles().style,
...new DefaultappointmentStyles().style,
...new DefaultauthStyles().style,
...new DefaultclinicStyles().style,
...new DefaultcommunicationStyles().style,
...new DefaultdashboardStyles().style,
...new DefaultdataManagementStyles().style,
...new DefaultpatientStyles().style,
...new DefaultprescriptionStyles().style,
...new DefaultrxprofileStyles().style,
...new DefaultrxsettingsStyles().style,
...new DefaultsupportStyles().style,
...new DefaultuserManagementStyles().style,
};
 export default DefaultModulesStyles