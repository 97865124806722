import React from "react";

import { CoreBox } from "@wrappid/core";

export default function DeviceToolBox(props) {

  return (
    <CoreBox>
      {JSON.stringify(props, null, 2)}
    </CoreBox>
  );
}