/* eslint-disable etc/no-commented-out-code */
import React, { useEffect, useRef, useState } from "react";

import { 
  CoreLayoutItem, CoreTypographyBody1, CoreTypographyOverline, CoreIconButton, CoreTooltip, CoreIcon, CoreGrid, CoreBox, AppBuilderContainerLayout, CoreClasses, coreUseLocation
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { AppBuilderData } from "./data/app-builder/AppBuilder.data";
import EditorGroup from "./editor/EditorGroup";
import GlobalSnackbar, { notifyUsingSnackbar } from "./GlobalSnackbar";
import ToolbarGroup from "./toolbar/ToolbarGroup";
import ToolBoxGroup from "./toolbox/ToolBoxGroup";
import { addOrSelectBuilder, selectBuilderData, updateEditorContent, removeBuilder } from "../actions/builder.actions";
import { CLOSE, PRIMARY, SMALL } from "../constants/AppBuilder.constant";
import AppBuilderFactory from "../factory/AppBuilder.factory";
import ModuleClasses from "../styles/ModuleClasses";
import { BuilderEntitySchema } from "../utils/UtilityFunction";

interface AppBuilderState {
  data: any;
  builderType: string;
}

export default function AppBuilder() {
  const { state } = coreUseLocation();
  const dispatch = useDispatch();

  const { appBuilderData: appBuilderDataFromStore } = useSelector((state: any) => state.BuilderOptions);
  
  const [entityData, setEntityData] = useState(null);
  const [builderType, setBuilderType] = useState<string>(null);
  
  const [selectedBuilder, setSelectedBuilder] = useState(null); 
  const [selectedEditorData, setSelectedEditorData] = useState(null);
  const [selectedToolboxData, setSelectedToolboxData] = useState(null);
  const [selectedToolbarData, setSelectedToolbarData] = useState(null);
  const isSelectedBuilder = useRef(false);

  /**
   * Make the builder selected
   * 
   * @param builderId 
   */
  function handleTabSelect(builderId: string) {
    if (builderId) {
      dispatch(selectBuilderData(builderId));
    }
  }
  const handleDeleteBuilder = (builderId: string, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent tab selection when clicking delete
    
    // Don't allow deleting the last builder
    if (appBuilderDataFromStore.length <= 1) {
      notifyUsingSnackbar("Cannot delete the last builder(Still in testing stage)", "warning");
      return;
    }
    
    dispatch(removeBuilder(builderId));
  };
  
  useEffect(() => {
    /* In this useEffect we will set the builderType and entityData */

    if(!builderType || !entityData || entityData?.id <= 0 ){
      // when builderType, entityData or entityData not greater than 0

      if (state && (state as AppBuilderState).data && (state as AppBuilderState).builderType){
        /* when state and state has data and builderType */
        const typedState = state as AppBuilderState;
    
        setBuilderType(typedState.builderType);
        setEntityData(typedState.data);
      } else {
        /* when state is undefined or state has no data or builderType */
        const currentBuilderData: AppBuilderData<any> = appBuilderDataFromStore[0];

        /* Add null check before accessing methods */ 
        if (currentBuilderData?.getBuilderType && currentBuilderData?.getEntityData) {
          setBuilderType(currentBuilderData.getBuilderType());
          setEntityData(currentBuilderData.getEntityData());
        } else {
          setBuilderType(null); 
          setEntityData(null); 
        }
      }
    } else {
      // do nothing
    }
  }, [state, appBuilderDataFromStore, builderType, entityData]);
    
  useEffect(() => {
    /* In this useEffect we will set the selected builder data */
    if(!isSelectedBuilder.current && builderType && entityData && entityData?.id > 0){
      /* if builder is not initialized and builderType and entityData is valid */
      dispatch(addOrSelectBuilder(undefined, undefined, undefined, undefined, entityData.id, entityData, builderType, dispatch));
      isSelectedBuilder.current = true;
    } else {
      // do nothing
    }
  }, [isSelectedBuilder, builderType, entityData, dispatch]);

  useEffect(() => {
    const updatedBuilder = appBuilderDataFromStore?.find(builder => builder.selected);
    
    if (updatedBuilder) {
      const _selectedBuilder = AppBuilderFactory.getAppBuilderData<BuilderEntitySchema>(
        updatedBuilder.builderId,
        updatedBuilder.toolbars,
        updatedBuilder.toolboxes,
        updatedBuilder.editors,
        updatedBuilder.builderType,
        updatedBuilder.entityData,
        dispatch
      );
  
      // Ensure Redux editor content is updated
      dispatch(updateEditorContent(_selectedBuilder.getEditors()));
      setSelectedBuilder(updatedBuilder);
      setSelectedEditorData(_selectedBuilder.getEditors());
      setSelectedToolboxData(_selectedBuilder.getToolBoxes());
      setSelectedToolbarData(_selectedBuilder.getToolbars());

    }
  }, [appBuilderDataFromStore]);
  
  return (
    <> 

      {/* Header area of pagebuilder */} 
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.Header} styleClasses={[CoreClasses.BG.BG_GREY_200]}>
        <CoreGrid>
          <CoreBox gridProps={{ gridSize: 9 }} styleClasses={[ModuleClasses.BUILDER_TAB_GROUP]}>
            {appBuilderDataFromStore && appBuilderDataFromStore.length > 0 ? (
              
              appBuilderDataFromStore.map((eachBuilderData, index) => {
                const isLastItem = index === appBuilderDataFromStore.length - 1;

                return (
                  <CoreBox
                    key={`tab-${index}`}
                    styleClasses={[
                      ModuleClasses.BUILDER_TAB,
                      eachBuilderData?.builderId === selectedBuilder?.builderId
                        ? CoreClasses.BG.BG_GREY_200
                        : CoreClasses.BG.BG_GREY_400,
                      !isLastItem && CoreClasses.BORDER.BORDER_END,
                      !isLastItem && CoreClasses.BORDER.BORDER_COLOR_GREY_300,
                    ]}
                    onClick={() => handleTabSelect(eachBuilderData?.builderId)}
                  >
                    <CoreTooltip title={eachBuilderData?.entityData?.name} arrow>
                      <CoreBox styleClasses={[ModuleClasses.BUILDER_TAB_BOX]}>
                        <CoreIcon icon={eachBuilderData?.builderIcon} color={PRIMARY} fontSize={SMALL}/>
                        <CoreTypographyOverline paragraph={false} gutterBottom={false} styleClasses={[ModuleClasses.BUILDER_TAB_NAME]}>{eachBuilderData?.entityData?.name}</CoreTypographyOverline>
                      </CoreBox>
                    </CoreTooltip>
                    <CoreTooltip title="Close builder" arrow>
                      <CoreIconButton 
                        color={PRIMARY}
                        onClick={(event) => handleDeleteBuilder(eachBuilderData?.builderId, event)}
                      >
                        <CoreIcon icon={CLOSE} fontSize={SMALL}/>
                      </CoreIconButton>
                    </CoreTooltip>
                  </CoreBox>
                );
              })
            ) : (
              <CoreTypographyBody1>
                No Builder available to display.
              </CoreTypographyBody1>
            )}
          </CoreBox>

          <CoreBox gridProps={{ gridSize: 3 }} styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END, CoreClasses.PADDING.PB1]}> 
            <ToolbarGroup toolbarData={selectedToolbarData}/>
          </CoreBox>
        </CoreGrid>
        
      </CoreLayoutItem>

      {/* Content area of pagebuilder */}
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.Content}>
        {/* <EditorGroup viewerData={selectedEditorData} /> */}
        <EditorGroup key={selectedBuilder?.builderId} viewerData={selectedEditorData} />

        {/** Snackbar for displaying snack message */}
        <GlobalSnackbar />
      </CoreLayoutItem>

      {/* Right-Drawer area of pagebuilder */}
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.RightDrawer}>
        {/* <ToolBoxGroup toolboxData={selectedToolboxData}/> */}
        <ToolBoxGroup key={selectedBuilder?.builderId} toolboxData={selectedToolboxData} />

      </CoreLayoutItem>
    </>
  );
} 
