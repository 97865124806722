/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable etc/no-commented-out-code */
import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { ArrayManagerSingleStack } from "../../../test/ArrayManagerSingleStack";

// import { JSONTransformer } from "../../../test/test";

const ReduxStoreDataViewer = () => {
 
  // const abcd = new JSONTransformer();
  // const tmp = useSelector((state:any) => state[reducerName]);
  const reducerName  = "auth"; 
  const tmp = useSelector((state) => state[reducerName]);
  const { upperPointer, data:reducerData, currentPointer } = useSelector((state) => state[reducerName]);

  const [data, setData] = useState(tmp);
  const [jsonText, setJsonText] = useState(JSON.stringify(data, null, 2));
  const [parsedJson, setParsedJson] = useState(null);
  const [error, setError] = useState(null);

  const [arrayManager] = useState(() => new ArrayManagerSingleStack<number>());
  const [currentValue, setCurrentValue] = useState("");
  const [currentArray, setCurrentArray] = useState([]);

  const updateJSON = useCallback(() => {
    setCurrentArray(arrayManager.getCurrentArray());
  }, [arrayManager]);

  const handleAdd = (value) => {
    arrayManager.add(value);
    updateJSON();
  };

  const handleUndo = () => {
    const currentArray = arrayManager.getCurrentArray();
  
    if (arrayManager.undo()) {
      // const tmp = JSONTransformer.revertChanges(data, currentArray[(currentArray.length) - 1]);

      setData(tmp);
      updateJSON();
    }
  };

  const handleRedo = () => {
    if (arrayManager.redo()) {

      const tmpChanges = currentArray[currentArray.length - 1 ];
      // const tmp = JSONTransformer.applyChanges(data, tmpChanges );

      setData(tmp);
      updateJSON();
    }
  };

  const handleJsonChange = (() => {
    let debounceTimeout: NodeJS.Timeout;
  
    return (event: any) => {
      const input = event.target.value;

      setJsonText(input);
      try {
        const parsed = JSON.parse(input);
        
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          // const changes = JSONTransformer.compareJSON(data, parsed);

          setData(parsed);

          // handleAdd(changes);
        }, 2000); // 500ms debounce delay
  
        setParsedJson(parsed); // Update state with parsed JSON
        setError(null);
      } catch (err) {
        setError("Invalid JSON format");
        setParsedJson(null);
      }
    };
  })();

  useEffect(() => {
    setJsonText(JSON.stringify(data, null, 2));
  }, [data]);

  return (
    <div className="json-editor-container" style={{ padding: "20px" }}>
      <h2 className="text-xl font-bold mb-4">JSON Viewer and Editor</h2>
      <button onClick={handleUndo}>Undo</button>
      <button onClick={handleRedo}>Redo</button>
      {/* JSON Editor */}
      <textarea
        value={jsonText}
        onChange={handleJsonChange}
        rows={10}
        style={{
          backgroundColor: "#f0f0f0",
          border         : "1px solid #ccc",
          borderRadius   : "4px",
          color          : "#333",
          fontFamily     : "monospace",
          fontSize       : "14px",
          padding        : "10px",
          width          : "100%"
        }}
      />
      
      {error && (
        <div style={{ color: "red", marginTop: "10px" }}>
          {error}
        </div>
      )}
      <div>
        Current Array: {JSON.stringify(currentArray)}
      </div>
      {/* JSON Viewer */}
      {parsedJson && (
        <div
          style={{
            backgroundColor: "#272822",
            borderRadius   : "4px",
            color          : "#f8f8f2",
            fontFamily     : "monospace",
            marginTop      : "20px",
            overflow       : "auto",
            padding        : "10px",
            whiteSpace     : "pre-wrap"
          }}
        >
          {JSON.stringify(parsedJson, null, 2)}
        </div>
      )}
    </div>
  );
};

export default ReduxStoreDataViewer;