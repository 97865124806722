export const PROPERTY_TOOLBOX = "PropertyToolBox";
export const LAYOUT_TOOLBOX = "LayoutToolBox";
export const DEVICE_TOOLBOX = "DeviceToolBox";
export const COMPONENT_TOOLBOX = "ComponentToolBox";

/** Icon name constant */
export const MODEL_ICON = "storage";
export const PAGE_ICON = "layers";
export const ROUTE_ICON = "directions";
export const THEME_ICON = "contrast";
export const FORM_ICON  = "feed";
export const DEFAULT_ICON = "star";
export const BUSINESS_ICON = "schema";
export const STAR_ICON = "star";

export const PRIMARY = "primary";
export const SMALL = "small";
export const CLOSE = "close";
export const CLOSE_FUNCTIONALITY_COMMING_SOON = "Close functionality comming soon";

/** Editor component name */
export const MODEL_EDITOR = "ModelEditor";
export const JSON_EDITOR = "JsonEditor";
export const ROUTES_EDITOR = "RoutesEditor";
export const FORM_EDITOR = "FormEditor";
export const THEME_EDITOR = "ThemeEditor";
export const BUSINESS_EDITOR = "BusinessEditor";
export const PAGE_EDITOR = "PageEditor";

/** Builder Json tab name */
export const BUILDER_JSON = "Builder_JSON";

/** Toolbar action tooltip label */
export const SAVE = "Save";
export const REQUEST_FOR_REVIEW = "Request for review";
export const HISTORY = "History";