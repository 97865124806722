import { BusinessEditorData } from "../components/data/editor/business/BusinessEditor.data";
import { EditorData } from "../components/data/editor/Editor.data";
import { FormEditorData } from "../components/data/editor/form/FormEditor.data";
import { JsonEditorData } from "../components/data/editor/json/JsonEditor.data";
import { ModelEditorData } from "../components/data/editor/model/ModelEditor.data";
import { PageEditorData } from "../components/data/editor/page/PageEditor.data";
import { RouteEditorData } from "../components/data/editor/route/RouteEditor.data";
import { ThemeEditorData } from "../components/data/editor/theme/ThemeEditor.data";
import { EntityData } from "../components/data/entity/Entity.data";

/**
 * EditorFactory class provides methods to create different editor components based on the variant type.
 * The factory pattern is used to abstract the instantiation of specific variant data.
 */
export default class EditorFactory {
  /** 
   * A constant object holding various variant names for different variants available in the editor group.
   * These variants correspond to specific actions or types within the editor group.
   */
  static VARIANTS = {
    BUILDER_JSON   : "BUILDER_JSON",
    BUSINESS_EDITOR: "BUSINESS",
    FORM_EDITOR    : "FORM",
    JSON_EDITOR    : "JSON",
    MODEL_EDITOR   : "MODEL",
    PAGE_EDITOR    : "PAGE",
    ROUTES_EDITOR  : "ROUTE",
    THEME_EDITOR   : "THEME"
  } as const;

  /**
   * Factory method to create and return a editor data instance based on the provided editor type (variant) and entity data.
   * This method abstracts the creation of different editor data classes based on the variant type.
   * 
   * @param editorTab - The type of editor (e.g., "JSON", "Model").
   * @param entityData - The dynamic entity data that will be passed to the editor data classes.
   * @returns A new instance of the appropriate EditorData subclass based on the editorTab.
   * @throws Error if the provided editorTab is not recognized.
   */
  static getEditorData<TSchema, T extends EditorData<TSchema>>(
    editorId: string,
    storedEditorContent: any,
    editorTab: string,
    entityData: EntityData<TSchema>
  ) :T {
    switch (editorTab) {
      case EditorFactory.VARIANTS.JSON_EDITOR:
        return new JsonEditorData(editorId, storedEditorContent, entityData) as T; // Return a new instance of JsonEditorData when the editorTab is "JSON".

      case EditorFactory.VARIANTS.MODEL_EDITOR:
        return new ModelEditorData(editorId, storedEditorContent, entityData) as T; // Return a new instance of ModelEditorData when the editorTab is "Model".

      case EditorFactory.VARIANTS.ROUTES_EDITOR:
        return new RouteEditorData(editorId, storedEditorContent, entityData) as T;

      case EditorFactory.VARIANTS.FORM_EDITOR:
        return new FormEditorData(editorId, storedEditorContent, entityData) as T;
      
      case EditorFactory.VARIANTS.THEME_EDITOR:
        return new ThemeEditorData(editorId, storedEditorContent, entityData) as T;
      
      case EditorFactory.VARIANTS.PAGE_EDITOR:
        return new PageEditorData(editorId, storedEditorContent, entityData) as T;

      case EditorFactory.VARIANTS.BUSINESS_EDITOR:
        return new BusinessEditorData(editorId, storedEditorContent, entityData) as T;

      // If the editorTab doesn't match any of the defined variants, throw an error.
      default:
        throw new Error(`Unknown editor type: ${editorTab}`);
    }
  }
}