import React from "react";

import {
  CoreBox,
  CoreClasses,
  CoreTooltip,
  CoreIcon,
  CoreSpan,
  CoreTypographyCaption
} from "@wrappid/core";

import ModuleClasses from "../../../styles/ModuleClasses";

const AttributeBox = ({ attribute, isSelected, onClick }) => (
  <CoreBox
    key={attribute.id}
    onClick={onClick}
    styleClasses={[ModuleClasses.ATTRIBUTE_BOX, isSelected && CoreClasses.BG.BG_SECONDARY_LIGHT]}
  >
    <CoreSpan styleClasses={[CoreClasses.WIDTH.W_25]}>
      {attribute.selectedProps.primaryKey && (
        <CoreTooltip title="Primary Key" arrow placement="left">
          <CoreIcon color="primary" icon="vpn_key" />
        </CoreTooltip>
      )}
    </CoreSpan>

    <CoreTypographyCaption styleClasses={[ModuleClasses.ATTRIBUTE_NAME_BOX]}>
      {attribute.selectedProps.name}
    </CoreTypographyCaption>

    <CoreTypographyCaption styleClasses={[CoreClasses.TEXT.TEXT_END]}>
      {attribute.selectedProps.type}
    </CoreTypographyCaption>
  </CoreBox>
);

export default function ModelAttributeEditor({ attribute, selectedId, onSelect }) {
  // Determine if this attribute is selected
  const isSelected = selectedId === attribute.attributeId;

  const handleSelectedAttribute = () => {
    // Toggle selection: deselect if already selected, otherwise select
    onSelect(isSelected ? null : attribute.attributeId);
  };

  return (
    <AttributeBox
      attribute={attribute}
      isSelected={isSelected}
      onClick={handleSelectedAttribute}
    />
  );
}
