/* eslint-disable no-console */
/* eslint-disable etc/no-commented-out-code */
/* eslint-disable id-length */
/* eslint-disable no-unused-vars */

export const SanClinicAddUrlChange = (formData, apiMeta, state, others) => {
  // -- console.log("SANITING", apiMeta.endpoint, others);
  formData["addressTypeId"] = state?.common?.addressTypes?.find(
    (a) => a.type.toLowerCase() === "clinic"
  )?.id;
  return {
    endpoint: apiMeta.endpoint.replace(":id", state?.auth?.user?.personID),
    values  : formData,
  };
};
export const SanClinicDeleteUrlChange = (formData, apiMeta, state, others) => {
  // -- console.log("SANITING", apiMeta, others);
  return {
    endpoint: apiMeta.endpoint.replace(":id", others.deleting),
    values  : formData,
  };
};
  
export const SanClinicEditUrlChange = (formData, apiMeta, state, others) => {
  for (let key in formData) {
    if (formData[key] === null) {
      delete formData[key];
    }
  }
  return {
    endpoint: apiMeta.endpoint.replace(":id", others.editing),
    values  : formData,
  };
};

export const SanClinicRead = (formData, apiMeta, state, others) => {
  
  for (let key in formData) {
    if (formData[key] === null) {
      delete formData[key];
    }
  }

  return { ...formData };
};
  
export const SanClinicReadUrlChange = (formData, apiMeta, state, others) => {
  return {
    endpoint: apiMeta.endpoint.replace(":id", state?.auth?.user?.personID),
    values  : formData,
    ...formData
  };
};