export function convertMetaToJSON(data) {
  const transformedData = {};
      
  data.forEach((datum) => {
    transformedData[datum.key] = datum.value;
  });
  return transformedData;
}
  
export function processProfileCompletenessData(data) {
  let completenessData = {};

  if (data) {
    let metaData = convertMetaToJSON(data?.Person?.PersonMetas);
    
    completenessData = { ...data?.Person?.DoctorDetails, ...metaData };
    
    delete data.Person;
      
    completenessData = { ...completenessData, ...data };
  }
    
  return completenessData;
}