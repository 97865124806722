import { useContext, useEffect, useState } from "react";

import {
  CoreBox, CoreButton, CoreClasses, CoreInput,
  coreUseLocation,
  HTTP,
  apiRequestAction,
  CoreDialogContext
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import IconSelector from "./IconSelector";
import { GET_PAGE_DATA } from "../../../types/appBuilderTypes";

const buttonData = [{ color: "inherit", label: "Preview", onClick: () => alert("Preview clicked but not functional"), variant: "text" }, { color: "inherit", label: "History", onClick: () => alert("History clicked but not functional"), variant: "text" }, { color: "inherit", label: "Request for Review", onClick: () => alert("Request for Review clicked but not functional"), variant: "text" }, { label: "Save", onClick: () => alert("Save clicked but not functional") }];

export default function HeaderComp() {
  const location = coreUseLocation();
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");
  const [changeModuleName, setChangeModuleName] = useState(false);
  const { setDialog } = useContext(CoreDialogContext);

  useEffect(() => {
    if (changeModuleName) {
      setDialog({ 
        // eslint-disable-next-line no-console
        cancelButton   : () => console.log("Cancel clicked"),
        // eslint-disable-next-line no-console
        doneButton     : () => console.log("Done clicked"),
        doneButtonLabel: "Change",
        noCancelButton : false,
        noDoneButton   : false,
        // eslint-disable-next-line no-console
        onClose        : () => console.log("Dialog closed"),
        showDialog     : true,
        subtitle       : "Not functional, (List the module name options, with also modules that may have issues if this page is saved in them.)",
        title          : "List of module name",
        type           : "info"
      });

      const timer = setTimeout(() => {
        setChangeModuleName(false);
      }, 500); 

      return () => clearTimeout(timer); 
    }
  }, [changeModuleName, setDialog]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageId = searchParams.get("id");

    if (pageId) {
      const pageIdUri = encodeURIComponent(JSON.stringify({ id: pageId }));

      dispatch(
        apiRequestAction(
          HTTP.GET,
          `/business/individual/Pages?_defaultFilter=${pageIdUri}`,
          true,
          {},
          GET_PAGE_DATA
        )
      );
    }
  }, [location.search]);

  const pageData = useSelector((state) => state.appBuilderReducer?.pageData);

  const pageName = pageData?.data?.data?.name;

  useEffect(() => {
    if (pageName !== inputText) {
      setInputText(pageName || "");
    }
  }, [pageName]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  return (
    <CoreBox
      styleClasses={[
        CoreClasses.HEIGHT.VH_8,
        CoreClasses.DISPLAY.FLEX,
        CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER,
        CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN,
        CoreClasses.BG.BG_GREY_100,
        CoreClasses.PADDING.PX1,
      ]}
    >
      <CoreBox
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.GAP.GAP_2]}
      >
        <CoreButton
          variant="text"
          styleClasses={[CoreClasses.GAP.GAP_1, CoreClasses.COLOR.TEXT_BLACK_50]}
          onClick={()=>setChangeModuleName(true)}
        >
          Example Module
        </CoreButton>

        <CoreInput
          id="outlined-basic"
          placeholder="Page Name"
          value={inputText}
          onChange={handleInputChange}
          styleClasses={[CoreClasses.MARGIN.M0]}
        />
      </CoreBox>

      <IconSelector />

      <CoreBox
        styleClasses={[
          CoreClasses.DISPLAY.FLEX,
          CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER,
          CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER,
          CoreClasses.GAP.GAP_2,
          CoreClasses.COLOR.TEXT_BLACK_50
        ]}
      >
        {buttonData.map((button, index) => (
          <CoreButton
            key={index}
            label={button.label}
            variant={button.variant || "contained"} // Fallback variant if not defined
            color={button.color || "primary"} // Fallback color if not defined
            onClick={button.onClick}
          />
        ))}
      </CoreBox>

    </CoreBox>
  );
}