import { CoreAvatar, CoreBox, CoreClasses } from "@wrappid/core";

export default function CircularPicture(props) {
  const imageUrl = props?.data;

  return (
    <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER]}>
      <CoreAvatar
        styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]}
        src={imageUrl}
      />
    </CoreBox>
  );
}
