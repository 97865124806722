export const ApiRegistry = {
  EDIT_PRESCRIPTION_TEMPLATE:
    "/prescription/template/edit/:id",
  GET_PRESCRIPTION: "/prescription/:id",
  GET_PRESCRIPTION_TEMPLATE_DESIGNS_API:
    "/prescriptionTemplateDesigns",
  GET_SUPPORTED_LANGUAGES                 : "/business/all/SupportedLanguages",
  PRESCRIPTION_PDF_API                    : "/prescriptionPdf/:id",
  UPDATE_PRESCRIPTION_TEMPLATE_MASTER_DATA: "/person/:personId/master/:masterName"
};
