import { useEffect } from "react";

import {
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreIcon,
  CoreStack,
  CoreTextButton,
  CoreTypographyBody2,
  CoreTypographyCaption,
  HTTP,
  __IconTypes,
  apiRequestAction
} from "@wrappid/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import {
  GET_PATIENT_UPCOMING_APPOINTMENT_ERROR,
  GET_PATIENT_UPCOMING_APPOINTMENT_SUCCESS
} from "../types/patient.types";

export default function PatientUpcomingAppointment(props) {
  const { data } = props || {};
  const dispatch = useDispatch();
  const upcomingAppointment = useSelector(
    state => state?.patient?.upcomingAppointment
  );

  // -- const upcomingAppointmentDetails = {
  //   "daysLeft": "daysLeft",
  //   "time"    : "time",
  // };

  useEffect(() => {
    if (data && data?.relatedPersonId) {
      let reqURL = "/business/individual/" + "Appointments";
      let apiData = {};

      apiData = {
        _defaultFilter: encodeURIComponent(
          JSON.stringify({ _status: "new", patientId: data["relatedPersonId"] })
        ),
      };

      // --- eslint-disable-next-line no-prototype-builtins
      // if(Object.keys(upcomingAppointment).length === 0 || upcomingAppointment.hasOwnProperty(data["relatedPersonId"]) ) {}
      dispatch(
        apiRequestAction(
          HTTP.GET,
          reqURL,
          true,
          apiData,
          GET_PATIENT_UPCOMING_APPOINTMENT_SUCCESS,
          GET_PATIENT_UPCOMING_APPOINTMENT_ERROR,
          null,
          null,
          null,
          null,
          null,
          { patientId: data["relatedPersonId"] }
        )
      );
    }
  }, [data]);

  const isPastDate = () => {
    try {
      // eslint-disable-next-line no-console
      console.log("upcomingAppointment?.data?.date;", upcomingAppointment);
      if (!upcomingAppointment?.data) {
        return true;
      }
      let curDate = moment();
      let apptDate = upcomingAppointment?.data?.date;
      let diff = moment(apptDate).diff(curDate, "days");

      return diff < 0;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error", err);
      return false;
    }
  };

  /**
   * @todo
   * we need to show multiple appointments here
   */
  return !upcomingAppointment || isPastDate() ? (
    <CoreTypographyBody2
      styleClasses={[CoreClasses?.TEXT?.TEXT_CENTER, CoreClasses?.PADDING?.P1]}>
      No upcoming Appointments
    </CoreTypographyBody2>
  ) : (
    <CoreGrid>
      <CoreBox gridProps={{ gridSize: 2 }} />

      <CoreStack
        gridProps={{ gridSize: 10 }}
        spacing={1}
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
        <CoreIcon
          styleClasses={[CoreClasses?.MARGIN?.MT1, CoreClasses.COLOR.TEXT_PRIMARY]}
          type={__IconTypes.MATERIAL_OUTLINED_ICON}>
          {"schedule"}
        </CoreIcon>

        <CoreBox>
          <CoreTypographyCaption>
            {moment(upcomingAppointment?.data?.date).format("LL")}

            {",  "}

            {upcomingAppointment?.data?.startTime?.substring(0, 5) +
              "  to  " +
              upcomingAppointment?.data?.endTime?.substring(0, 5)}
          </CoreTypographyCaption>
        </CoreBox>

        <CoreBox>
          <CoreTypographyCaption>
            {moment(upcomingAppointment?.data?.date).startOf("days").fromNow()}
          </CoreTypographyCaption>
        </CoreBox>

        <CoreTextButton
          label="Remind Me"
          onClick={() => {
            alert("Coming soon");
          }}
        />
      </CoreStack>
    </CoreGrid>
  );
}
