export const SET_TOOLBOX_CONTENT = "SET_TOOLBOX_CONTENT";
export const SET_EDITOR_GROUP_DATA = "SET_EDITOR_GROUP_DATA";
export const UPDATE_BUTTON_NAME = "UPDATE_BUTTON_NAME";
export const ADD_ATTRIBUTE_FROM_MODEL_SCHEMA = "ADD_ATTRIBUTE_FROM_MODEL_SCHEMA";
export const CURRENT_POINTER_INC = "CURRENT_POINTER_INC";
export const CURRENT_POINTER_DEC = "CURRENT_POINTER_DEC";
export const BUILDER_HISTORY = "BUILDER_HISTORY";

export const ADD_APP_BUILDER_DATA_TO_REDUCER = "ADD_APP_BUILDER_DATA_TO_REDUCER";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const SELECT_ATTRIBUTE = "SELECT_ATTRIBUTE";
export const SET_TOOLBOX_DATA_TO_REDUCER = "SET_TOOLBOX_DATA_TO_REDUCER";

export const BUILDER_DATA_CASE_1 = "BUILDER_DATA_CASE_1";
export const SET_BUILDER_DATA = "SET_BUILDER_DATA";

export const ADD_BUILDER_DATA = "ADD_BUILDER_DATA";
export const UPDATE_ATTRIBUTE_SELECTED_PROPS = "UPDATE_ATTRIBUTE_SELECTED_PROPS";
export const SELECTED_BUILDER = "SELECTED_BUILDER";

/** Below types are currently working with, above types should be deleted after completing model builder */
export const ADD_OR_SELECT_BUILDER_DATA = "ADD_OR_SELECT_BUILDER_DATA";
export const UPDATE_EDITOR_CONTENT = "UPDATE_EDITOR_CONTENT";
export const UPDATE_ENTITY_DATA_SCHEMA = "UPDATE_ENTITY_DATA_SCHEMA";
export const UPDATE_ENTITY_DATA_EXTRA_INFO = "UPDATE_ENTITY_DATA_EXTRA_INFO";
export const UPDATE_ATTRIBUTE_PROPERTY = "UPDATE_ATTRIBUTE_PROPERTY";
export const SAVE_ENTITY_DATA_SUCCESS = "SAVE_ENTITY_DATA_SUCCESS";
export const SAVE_ENTITY_DATA_FAILURE = "SAVE_ENTITY_DATA_FAILURE";
export const RENAME_ATTRIBUTE = "RENAME_ATTRIBUTE";
export const REMOVE_BUILDER = "REMOVE_BUILDER";