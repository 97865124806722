import React from "react";

import {
  CoreBox,
  CoreList,
  CoreComponentsRegistry,
  CoreAccordion,
  CoreAccordionSummary,
  CoreIcon,
  CoreTypographyBody1,
  CoreAccordionDetail,
  CoreClasses
} from "@wrappid/core";

import { Component } from "../data/toolbox/component/Component.interface";

const COMPONENT_TYPES = [
  "layouts",
  "utils",
  "dataDisplay",
  "inputs",
  "surfaces",
  "navigation",
  "forms",
  "feedback",
  "dataTable"
] as const;

// Create a more dynamic and efficient categorization approach
function categorizeComponents() {
  const categorizedComponents = COMPONENT_TYPES.reduce((acc, type) => {
    acc[type] = Object.entries(CoreComponentsRegistry)
      // Type assertion for the entries to match the Component interface
      // eslint-disable-next-line id-length, @typescript-eslint/no-unused-vars
      .filter(([_, details]) => (details as Component).category === type)
      .map(([componentName]) => componentName);
    return acc;
  }, {} as Record<string, string[]>);

  return categorizedComponents;
}

export default function ComponentToolBox() {
  const categorizedComponents = categorizeComponents();

  return (
    <CoreBox>
      <CoreList>
        {COMPONENT_TYPES.map((type, index) => (
          <CoreAccordion key={type}>
            <CoreAccordionSummary
              expandIcon={<CoreIcon icon="arrow_drop_down" />}
              aria-controls={`panel-${index}-content`}
              id={`panel-${index}-header`}

            >
              <CoreTypographyBody1
                styleClasses={[CoreClasses.MARGIN.M0]}
              >{type}</CoreTypographyBody1>
            </CoreAccordionSummary >
            <CoreAccordionDetail >
              {categorizedComponents[type].length > 0 ? (
                categorizedComponents[type].map((item) => (
                  <CoreBox key={item}>
                    <CoreTypographyBody1>{item}: <CoreIcon icon="<>"/></CoreTypographyBody1>
                  </CoreBox>
                ))
              ) : (
                <CoreTypographyBody1>No components available for {type}.</CoreTypographyBody1>
              )}
            </CoreAccordionDetail>
          </CoreAccordion>
        ))}
      </CoreList>
    </CoreBox>
  );
}