import { Dispatch } from "redux";

import { ToolbarData } from "./Toolbar.data";
import { HISTORY } from "../../../constants/AppBuilder.constant";
import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import ToolbarFactory from "../../../factory/Toolbar.factory";
import { EntityData } from "../entity/Entity.data";

export class HistoryToolbarData<TSchema> extends ToolbarData<TSchema>{
  private static actionEvent = [{ onClick: "historyOnClick" }];
  private static actionIcon = { iconName: "history", iconType: "__IconTypes.MATERIAL_ICON" };
  constructor(
    toolbarId: string,
    entityData: EntityData<TSchema>, 
    builderType:typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES],
    dispatch: Dispatch
  ){
    super(
      toolbarId,
      ToolbarFactory.ACTION.HISTORY, 
      HISTORY,
      HistoryToolbarData.actionEvent,
      HistoryToolbarData.actionIcon,
      entityData,
      builderType,
      dispatch
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onclickAction(entityData: EntityData<TSchema>, builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES], dispatch: Dispatch): void {
    // dispatch({ payload: { entityData }, type: "SAVE_ACTION" });
    // eslint-disable-next-line no-console
    console.log("History Action performed with", entityData);

  }
}