import { ToolBoxData } from "./ToolBox.data";
import ToolBoxFactory from "../../../factory/ToolBox.factory";

/**
 * Represents the theme toolbox data.
 * Extends the base `ToolBoxData` class to provide default configurations for a theme toolbox.
 */
export class ThemeToolBoxData extends ToolBoxData{
  
  constructor(
    toolboxId: string,
    toolboxTitle = "Theme",
    toolboxExpand = true,
    toolboxResize = "both"
  ){
    super(
      toolboxId,
      ToolBoxFactory.VARIANTS.THEME,
      toolboxTitle,
      toolboxExpand,
      toolboxResize);
  }

}