import {
  CoreAccordion,
  CoreAccordionSummary,
  CoreAccordionDetail,
  CoreTypographyBody1,
  CoreLink,
  CoreBox,
  CoreIcon
} from "@wrappid/core";
 
// eslint-disable-next-line import/no-unresolved
import wrappidMeta from "../wrappid.meta.json";
 
export default function RenderAppModuleDetails() {
  // Iterate over modules and render each one
  return (
    <>
      {Object.keys(wrappidMeta.modules).map((moduleName) => {
        const module = wrappidMeta.modules[moduleName];
        const { version, repoOwner, repoURL, dependencies, devDependencies } = module;

        // Define an array of sections to render
        const sections = [{ label: "Name", value: moduleName }, { label: "Version", value: version ? version : "Version not available" }, { label: "Repository", link: repoURL, value: repoURL ? repoURL : "Repository not available" }, { label: "Author", value: repoOwner ? repoOwner : "Author not available" }];

        return (
          <CoreAccordion key={moduleName}>
            <CoreAccordionSummary expandIcon={<CoreIcon icon="keyboard_arrow_down" />}>
              <CoreTypographyBody1 paragraph={false}>
                {`${moduleName} - ${version ? version : "Version not available"}`}
              </CoreTypographyBody1>
            </CoreAccordionSummary>

            <CoreAccordionDetail>
              {sections.map((section, index) => (
                <CoreTypographyBody1 key={index} paragraph={false}>
                  {section.label}:

                  {section.link ? (
                    <CoreLink href={section.link}>{` ${section.value}`}</CoreLink>
                  ) : (
                    ` ${section.value}`
                  )}
                </CoreTypographyBody1>
              ))}

              {/* Render Dependencies */}
              <CoreBox>
                <CoreTypographyBody1 paragraph={false}>Dependencies:</CoreTypographyBody1>

                {dependencies && Object.keys(dependencies).length > 0 ? (
                  <CoreBox>
                    {Object.keys(dependencies).map(dep => (
                      <CoreTypographyBody1 key={dep} paragraph={false}>{`${dep}: ${dependencies[dep]}`}</CoreTypographyBody1>
                    ))}
                  </CoreBox>
                ) : (
                  <CoreTypographyBody1 paragraph={false}>No dependencies</CoreTypographyBody1>
                )}
              </CoreBox>

              {/* Render Dev Dependencies */}
              <CoreBox>
                <CoreTypographyBody1 paragraph={false}>Dev Dependencies:</CoreTypographyBody1>

                {devDependencies && Object.keys(devDependencies).length > 0 ? (
                  <CoreBox>
                    {Object.keys(devDependencies).map(devDep => (
                      <CoreTypographyBody1 key={devDep} paragraph={false}>{`${devDep}: ${devDependencies[devDep]}`}</CoreTypographyBody1>
                    ))}
                  </CoreBox>
                ) : (
                  <CoreTypographyBody1 paragraph={false}>No dev dependencies</CoreTypographyBody1>
                )}
              </CoreBox>
            </CoreAccordionDetail>
          </CoreAccordion>
        );
      })}
    </>
  );
}

