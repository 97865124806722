import { HTTP, apiRequestAction } from "@wrappid/core";
import { Dispatch } from "redux";

import { ToolbarData } from "./Toolbar.data";
import { SAVE } from "../../../constants/AppBuilder.constant";
import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import ToolbarFactory from "../../../factory/Toolbar.factory";
import { SAVE_ENTITY_DATA_FAILURE, SAVE_ENTITY_DATA_SUCCESS } from "../../../types/builder.types";
import { EntityData } from "../entity/Entity.data";
export class SaveToolbarData<TSchema> extends ToolbarData<TSchema>{
  private static actionEvent = [{ onClick: "saveOnClick" }];

  private static actionIcon = { iconName: "save", iconType: "__IconTypes.MATERIAL_ICON" };
  constructor(
    toolbarId: string,
    entityData: EntityData<TSchema>, 
    builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES], 
    dispatch: Dispatch
  ){
    super(
      toolbarId,
      ToolbarFactory.ACTION.SAVE, 
      SAVE, 
      SaveToolbarData.actionEvent,
      SaveToolbarData.actionIcon,
      entityData,
      builderType,
      dispatch
    );
  }

  // onclickAction(entityData: EntityData<TSchema>, builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES], dispatch: Dispatch): void {
  //   if(builderType === "Model"){
  //     const id = entityData.getId();

  //     dispatch(
  //       apiRequestAction(
  //         HTTP.PUT,
  //         `/data/ModelSchemas/${id}`,
  //         true,
  //         entityData,
  //         SAVE_ENTITY_DATA_SUCCESS,
  //         SAVE_ENTITY_DATA_FAILURE
  //       ) as any
  //     );
  //   }
  // }

  onclickAction(
    entityData: EntityData<TSchema>,
    builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES],
    dispatch: Dispatch
  ): void {
    const handleApiRequest = (endpoint: string) => {
      const id = entityData.getId();

      dispatch(
      apiRequestAction(
        HTTP.PUT,
        `${endpoint}/${id}`,
        true,
        entityData,
        SAVE_ENTITY_DATA_SUCCESS,
        SAVE_ENTITY_DATA_FAILURE
      ) as any
      );
    };

    switch (builderType) {
      case "Model": {
        handleApiRequest("/data/ModelSchemas");
        break;
      }

      case "Route": {
        handleApiRequest("/data/Routes");
        break;
      }

      case "Form": {
        handleApiRequest("/data/FormSchemas");
        break;
      }

      case "Page": {
        handleApiRequest("/data/Pages");
        break;
      }

      case "Theme": {
        handleApiRequest("/data/ThemeSchemas");
        break;
      }

      case "Business": {
        handleApiRequest("/data/BusinessEntitySchemas");
        break;
      }

      default: {
        // eslint-disable-next-line no-console
        console.warn("Unhandled builder type:", builderType);
        break;
      }
    }
  }

}