import { getWrappidUUID } from "@wrappid/core/utils/appUtils";

import EditorFactory from "../../../factory/Editor.factory";
import { EntityData } from "../entity/Entity.data";

export abstract class EditorData<TSchema> {
  readonly editorId:string;
  
  private editorContentExtraInfo: any;
  protected editorType: typeof EditorFactory.VARIANTS[keyof typeof EditorFactory.VARIANTS];
  
  protected editorContent: any;
  
  protected editorRenderedComponent: string;

  constructor(
    editorId: string,
    storedEditorContent: any,
    entityData: EntityData<TSchema>,
    editorType: typeof EditorFactory.VARIANTS[keyof typeof EditorFactory.VARIANTS]
  ) {
    this.editorId = editorId || getWrappidUUID();
    this.editorType = editorType;

    this.prepareEditorContent(storedEditorContent, entityData);
    this.prepareEditorContentExtrainfo(entityData);
  }

  abstract prepareEditorContent(editorContent: any, entityData: EntityData<TSchema>): void;

  prepareEditorContentExtrainfo(entityData: EntityData<TSchema>): void {
    this.editorContentExtraInfo = entityData.getExtraInfo();
  }
  getEditorContentExtraInfo(): any {
    return this.editorContentExtraInfo;
  }
  getEditorId(): string {
    return this.editorId;
  }
 
  getEditorType(): string {
    return this.editorType;
  }

  getEditorContent(): any {
    return this.editorContent;
  }

  setEditorType(editorType: typeof EditorFactory.VARIANTS[keyof typeof EditorFactory.VARIANTS]): void {
    this.editorType = editorType;
  }
 
  setEditorContent(content: any): void {
    this.editorContent = content;
  }
  getEditorRenderedComponent():string{
    return this.editorRenderedComponent;
  }
  setEditorRenderedComponent(editorRenderedComponent: string): void {
    this.editorRenderedComponent = editorRenderedComponent;
  }
}