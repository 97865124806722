import { RoutesRegistry as AppBuilderRoutesRegistry } from "./app-builder/routes.registry";
import { RoutesRegistry as AppointmentRoutesRegistry } from "./appointment/routes.registry";
import { RoutesRegistry as AuthRoutesRegistry } from "./auth/routes.registry";
import { RoutesRegistry as ClinicRoutesRegistry } from "./clinic/routes.registry";
import { RoutesRegistry as CommunicationRoutesRegistry } from "./communication/routes.registry";
import { RoutesRegistry as DashboardRoutesRegistry } from "./dashboard/routes.registry";
import { RoutesRegistry as DataManagementRoutesRegistry } from "./data-management/routes.registry";
import { RoutesRegistry as PatientRoutesRegistry } from "./patient/routes.registry";
import { RoutesRegistry as PrescriptionRoutesRegistry } from "./prescription/routes.registry";
import { RoutesRegistry as RxprofileRoutesRegistry } from "./rxprofile/routes.registry";
import { RoutesRegistry as RxsettingsRoutesRegistry } from "./rxsettings/routes.registry";
import { RoutesRegistry as SupportRoutesRegistry } from "./support/routes.registry";
import { RoutesRegistry as UserManagementRoutesRegistry } from "./user-management/routes.registry";

export default {
...AppBuilderRoutesRegistry,
...AppointmentRoutesRegistry,
...AuthRoutesRegistry,
...ClinicRoutesRegistry,
...CommunicationRoutesRegistry,
...DashboardRoutesRegistry,
...DataManagementRoutesRegistry,
...PatientRoutesRegistry,
...PrescriptionRoutesRegistry,
...RxprofileRoutesRegistry,
...RxsettingsRoutesRegistry,
...SupportRoutesRegistry,
...UserManagementRoutesRegistry,
}