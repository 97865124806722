import React from "react";

import {
  CoreAvatar, CoreBox, CoreIconText, CoreClasses, CoreStack, CoreTypographyCaption, StatusText, __IconTypes, CoreGrid, CoreTypographySubtitle2, CoreTypographyBody1,
  HTTP,
  apiRequestAction
} from "@wrappid/core";
import { useDispatch } from "react-redux";

import { getDate } from "../utils/default.util";

export default function AppointmentSummaryComponent(props) {
  const { rowData } = props;
  const [personMeta, setPersonMeta] = React.useState({});
  const dispatch =  useDispatch();
  const getPersonMeta = ({ data }) => {
    
    setPersonMeta(data);
  };
  
  React.useEffect(() => {
    
    if(rowData && rowData?.patientId){
      let endpoint = `/data/meta/PersonMetas/${rowData?.patientId}`;

      dispatch(apiRequestAction(HTTP.GET, endpoint, true, {}, null, null, getPersonMeta));

      // eslint-disable-next-line etc/no-commented-out-code
      // dispatch(apiRequestAction(HTTP.GET, endpoint, {}, PATIENT_DATA_ENTRY));
    }
  }, [rowData]);

  const formattedDate = getDate(rowData.date);
  
  return (
    <>
      <CoreGrid styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
        <CoreBox
          gridProps={{ gridSize: 2 }}
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
        >
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR]}
            src={personMeta?.photoUrl}
          />
        </CoreBox>

        <CoreBox gridProps={{ gridSize: 10 }}>
          <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_SEMIBOLD]}>
            {personMeta?.firstName || personMeta?.middleName || personMeta?.lastName 
              ? [personMeta?.firstName, personMeta?.middleName, personMeta?.lastName].filter(Boolean).join(" ")
              : "Unknown Patient"}
          </CoreTypographyBody1>

          <CoreTypographyCaption>
            MRN{": "}

            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}
            >
              {personMeta["profileId"] || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>
        </CoreBox>
      </CoreGrid>

      <CoreBox styleClasses={[
        CoreClasses.DISPLAY.FLEX,
        CoreClasses.FLEX.DIRECTION_ROW,
        CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN,
        CoreClasses.MARGIN.MT1,
        CoreClasses.MARGIN.ML1,
        CoreClasses.MARGIN.MR2
      ]}>
        <CoreIconText
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon="event_available"
          text={formattedDate}
        />

        <CoreIconText
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon="query_builder"
          text={`${rowData["startTime"]?.substring(0, 5)} - ${rowData["endTime"]?.substring(0, 5)}`}
        />
      </CoreBox>

      <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses?.FLEX?.DIRECTION_ROW, CoreClasses.MARGIN.ML1, CoreClasses.ALIGNMENT.ALIGN_CONTENT_CENTER]}>
        {rowData?.Clinic?.photoUrl && (
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]}
            src={rowData?.Clinic?.photoUrl}
          />
        )}

        <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.MT1, CoreClasses.COLOR.TEXT_SECONDARY_DARK]} limitChars={12} hideSeeMore={true}>
          {rowData?.Clinic?.name || "Name not found"}
        </CoreTypographySubtitle2>
      </CoreBox>

      <CoreStack
        direction="row"
        spacing={1}
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses?.FLEX?.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
      >
        <CoreTypographyCaption>{"ID: " + rowData["id"]}</CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>{"|"}</CoreTypographyCaption>

        <StatusText status={rowData["_status"]} />

      </CoreStack>
    </>
  );
}