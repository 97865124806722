import { Dispatch } from "redux";

import { EditorData } from "../components/data/editor/Editor.data";
import { EntityData } from "../components/data/entity/Entity.data";
import { Property } from "../components/data/toolbox/property/Property.interface";
import {
  ADD_ATTRIBUTE, ADD_OR_SELECT_BUILDER_DATA, REMOVE_BUILDER, RENAME_ATTRIBUTE, SELECTED_BUILDER, SET_TOOLBOX_CONTENT, UPDATE_ATTRIBUTE_PROPERTY, UPDATE_ATTRIBUTE_SELECTED_PROPS, UPDATE_EDITOR_CONTENT, UPDATE_ENTITY_DATA_EXTRA_INFO, UPDATE_ENTITY_DATA_SCHEMA
} from "../types/builder.types";
import { BuilderEntitySchema } from "../utils/UtilityFunction";

// export const setBuilderData_Case1 = (data: {[key: string]: string}) => (dispatch) => {
//   dispatch({
//     payload: data,
//     type   : BUILDER_DATA_CASE_1
//   });
// };

export const selectBuilderData = (builderId: string) => {
  return {
    payload: { builderId },
    type   : SELECTED_BUILDER
  };
};

/** 
 * Action for create new builder data or 
 * selected true for already saved builder data if it is opened 
 **/
export const addOrSelectBuilder = (
  builderId: string | undefined = undefined,
  toolbars: any = undefined,
  toolboxes: any = undefined,
  editors: any = undefined,
  entityID: number, 
  entityData: EntityData<BuilderEntitySchema>, 
  builderType: string,
  dispatch: Dispatch
) => {
  return {
    payload: {
      builderId, builderType, dispatch, editors, entityData, entityID, toolbars, toolboxes
    },
    type: ADD_OR_SELECT_BUILDER_DATA
  };
};

/**
 * Action for update entity schema in store
 */
export const updateEntitySchema = (entitySchema: BuilderEntitySchema) => {
  return {
    payload: { entitySchema },
    type   : UPDATE_ENTITY_DATA_SCHEMA
  };
};

export const updateEditorContent = (editorData: EditorData<BuilderEntitySchema>[]) => {
  return {
    payload: { editorData },
    type   : UPDATE_EDITOR_CONTENT
  };
};

/**
 * Action for update entity extra info in store
 */
export const updateEntityExtraInfo = (extraInfo: any) => {
  return {
    payload: { extraInfo },
    type   : UPDATE_ENTITY_DATA_EXTRA_INFO
  };
};

export const addAttribute = () => {
  
  return { type: ADD_ATTRIBUTE };
};
export const updateAttribute = (id: string) => ({
  payload: { id },
  type   : UPDATE_ATTRIBUTE_SELECTED_PROPS,
});

export const setToolboxContent = (toolboxContent: Property[]) => ({
  payload: { toolboxContent },
  type   : SET_TOOLBOX_CONTENT,
});

export const renameAttribute = (newAttributeName, oldAttributeName) => ({
  payload: { newAttributeName, oldAttributeName },
  type   : RENAME_ATTRIBUTE,
});

export const updateProperty = (attributeName, propertyName, value) => ({
  payload: { attributeName, propertyName, value }
  ,
  type: UPDATE_ATTRIBUTE_PROPERTY,
});
export const removeBuilder = (builderId: string) => ({
  payload: { builderId },
  type   : REMOVE_BUILDER
});