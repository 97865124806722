import React from "react";

import {
  CoreAsyncSelect,
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreLink
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { setClinic } from "../actions/prescription.action";
import { ApiRegistry } from "../apis.registry";
import { SET_CLINIC } from "../types/prescription.types";

export default function PrescriptionTopBar({
  // -- prescription,
  template,
  HandleTemplateChange,
  // -- prescriptionClinic,
  // -- allClinics,
  language,
  onLanguageChange,
  allTemplates
}) {
  const dispatch = useDispatch();
  const [templateEditLink, setTemplateEditLink] = React.useState(null);

  const {
    clinic = {},
    prescription,
    navData
  } = useSelector((state) => state.prescription);
  const personID = useSelector((state) => state?.auth?.user?.personID);

  React.useEffect(() => {
    let editLink = null;

    if (allTemplates && allTemplates?.find((tem) => tem.personId === personID)) {
      editLink = `/${ApiRegistry.EDIT_PRESCRIPTION_TEMPLATE.replace(
        ":id",
        allTemplates?.find((tem) => tem.personId === personID)?.id
      )}`;
    } else {
      editLink = `/${ApiRegistry.EDIT_PRESCRIPTION_TEMPLATE.replace(
        ":id",
        template?.id
      )}`;
    }

    setTemplateEditLink(editLink);
  }, [template]);

  return (
    <CoreGrid
      styleClasses={[CoreClasses.PADDING.PX1, CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
    >
      <CoreBox
        styleClasses={[CoreClasses.WIDTH.W_100, CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
        gridProps={{ gridSize: { sm: 4 } }}>
        <CoreAsyncSelect
          styleClasses={[CoreClasses.FLEX.FILL]}
          id="template"
          label="Choose Template"
          type="asyncSelect"
          endpoint={"/business/all/prescriptionTemplate"}
          query={{
            _defaultFilter: encodeURIComponent(
              JSON.stringify({
                or: {
                  name    : { like: "sys_gen_%" },
                  personId: personID
                }
              })
            )
          }}
          value={template}
          itemKey="prescriptionTemplates"
          asyncLoading={false}
          getOptionValue={(data) => {
            return data.map((tmp) => {
              return typeof tmp === "string"
                ? { label: tmp }
                : { id: tmp.id, label: tmp.labl };
            });
          }}
          getOptionLabel={(data) => {
            return data.label;
          }}
          isOptionEqualToValue={(option, value) => {
            if (
              typeof option === typeof value &&
                            typeof option === "string"
            ) {
              return option === value;
            } else {
              return option.id === value.id;
            }
          }}
          handleChange={HandleTemplateChange}
          readOnly={prescription?.id}
        />

        {template && (
          <CoreLink href={templateEditLink}>Edit</CoreLink>
        )}
      </CoreBox>

      <CoreAsyncSelect
        gridProps={{ gridSize: { sm: 4 } }}
        id="prescription-clinic"
        label={"Choose clinic"}
        type="asyncSelect"
        endpoint={"/business/all/ProfileClinic"}
        query={{
          _defaultFilter: encodeURIComponent(
            JSON.stringify({ personId: personID })
          )
        }}
        itemKey={"clinics"}
        freeSolo={false}
        onChangeDispatch={{ type: SET_CLINIC }}
        getOptionValue={(data) => {
          return data;
        }}
        getOptionLabel={(data) => {
          return data.fullName;
        }}
        isOptionsEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        value={clinic}
        asyncLoading={false}
        handleChange={(data) => {
          dispatch(setClinic(data));
        }}
        readOnly={prescription?.id || navData?.appointment?.id}
      />

      <CoreAsyncSelect
        gridProps={{ gridSize: { sm: 4 } }}
        id="language"
        label="Change Language"
        type="asyncSelect"
        endpoint={ApiRegistry.GET_SUPPORTED_LANGUAGES}
        query={{}}
        value={language}
        itemKey="language"
        asyncLoading={false}
        handleChange={onLanguageChange}
      />
    </CoreGrid>
  );
}
