import SmallappBuilderStyles from "../../modules/app-builder/styles/SmallModuleStyles";
import SmallappointmentStyles from "../../modules/appointment/styles/SmallModuleStyles";
import SmallauthStyles from "../../modules/auth/styles/SmallModuleStyles";
import SmallclinicStyles from "../../modules/clinic/styles/SmallModuleStyles";
import SmallcommunicationStyles from "../../modules/communication/styles/SmallModuleStyles";
import SmalldashboardStyles from "../../modules/dashboard/styles/SmallModuleStyles";
import SmalldataManagementStyles from "../../modules/data-management/styles/SmallModuleStyles";
import SmallpatientStyles from "../../modules/patient/styles/SmallModuleStyles";
import SmallprescriptionStyles from "../../modules/prescription/styles/SmallModuleStyles";
import SmallrxprofileStyles from "../../modules/rxprofile/styles/SmallModuleStyles";
import SmallrxsettingsStyles from "../../modules/rxsettings/styles/SmallModuleStyles";
import SmallsupportStyles from "../../modules/support/styles/SmallModuleStyles";
import SmalluserManagementStyles from "../../modules/user-management/styles/SmallModuleStyles";

const SmallModulesStyles = {
...new SmallappBuilderStyles().style,
...new SmallappointmentStyles().style,
...new SmallauthStyles().style,
...new SmallclinicStyles().style,
...new SmallcommunicationStyles().style,
...new SmalldashboardStyles().style,
...new SmalldataManagementStyles().style,
...new SmallpatientStyles().style,
...new SmallprescriptionStyles().style,
...new SmallrxprofileStyles().style,
...new SmallrxsettingsStyles().style,
...new SmallsupportStyles().style,
...new SmalluserManagementStyles().style,
};
 export default SmallModulesStyles