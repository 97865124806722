import { ReducersRegistry as AppBuilderReducersRegistry } from "./app-builder/reducers.registry";
import { ReducersRegistry as AppointmentReducersRegistry } from "./appointment/reducers.registry";
import { ReducersRegistry as AuthReducersRegistry } from "./auth/reducers.registry";
import { ReducersRegistry as ClinicReducersRegistry } from "./clinic/reducers.registry";
import { ReducersRegistry as CommunicationReducersRegistry } from "./communication/reducers.registry";
import { ReducersRegistry as DashboardReducersRegistry } from "./dashboard/reducers.registry";
import { ReducersRegistry as DataManagementReducersRegistry } from "./data-management/reducers.registry";
import { ReducersRegistry as PatientReducersRegistry } from "./patient/reducers.registry";
import { ReducersRegistry as PrescriptionReducersRegistry } from "./prescription/reducers.registry";
import { ReducersRegistry as RxprofileReducersRegistry } from "./rxprofile/reducers.registry";
import { ReducersRegistry as RxsettingsReducersRegistry } from "./rxsettings/reducers.registry";
import { ReducersRegistry as SupportReducersRegistry } from "./support/reducers.registry";
import { ReducersRegistry as UserManagementReducersRegistry } from "./user-management/reducers.registry";

export default {
...AppBuilderReducersRegistry,
...AppointmentReducersRegistry,
...AuthReducersRegistry,
...ClinicReducersRegistry,
...CommunicationReducersRegistry,
...DashboardReducersRegistry,
...DataManagementReducersRegistry,
...PatientReducersRegistry,
...PrescriptionReducersRegistry,
...RxprofileReducersRegistry,
...RxsettingsReducersRegistry,
...SupportReducersRegistry,
...UserManagementReducersRegistry,
}