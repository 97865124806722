/* eslint-disable etc/no-commented-out-code */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";

import { CoreBox, CoreComponent } from "@wrappid/core";
import { useDispatch } from "react-redux";

import { updateEntityExtraInfo, updateEntitySchema } from "../../actions/builder.actions";
import { EditorData } from "../data/editor/Editor.data";

type EditorType<TSchema> = { viewerData: EditorData<TSchema> };

export default function Editor<TSchema>(props: EditorType<TSchema>) {
  const { viewerData } = props;
  const dispatch = useDispatch();

  const componentName = viewerData.getEditorRenderedComponent();
  const initialEditorContent = viewerData.getEditorContent(); // Capture the value
  const [entitySchema, setEntitySchema] = useState(initialEditorContent);

  const [contentExtraInfo, setContentExtraInfo] = useState(viewerData.getEditorContentExtraInfo());

  const prevEntitySchemaRef = useRef(entitySchema);
  const prevContentExtraInfoRef = useRef(contentExtraInfo);
  
  useEffect(()=> {
    // if new value is set to entity schema then update it in store 
    if (entitySchema !== initialEditorContent) {
      prevEntitySchemaRef.current = entitySchema;
      dispatch(updateEntitySchema(entitySchema));
    }

    // if new value is set to entity extra info then update it in store
    if (contentExtraInfo !== prevContentExtraInfoRef.current) {
      prevContentExtraInfoRef.current = contentExtraInfo;
      dispatch(updateEntityExtraInfo(contentExtraInfo));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitySchema, contentExtraInfo]);

  return (
    <CoreBox>
      <CoreComponent componentName={componentName} content={initialEditorContent} setContent={setEntitySchema} contentExtraInfo={contentExtraInfo} setContentExtraInfo={setContentExtraInfo}/>
    </CoreBox>
  );
}

