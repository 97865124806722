/* eslint-disable etc/no-commented-out-code */
import React from "react";

import { CoreLayoutItem, AppBuilderContainerLayout } from "@wrappid/core";

import ReduxStoreDataViewer from "./editor/model/ReduxStoreDataViewer";
// import ArrayEditor from "../test/ArrayEditor";
export default function HistoryChecker() {
  return (
    <>
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.Header}>
        <ReduxStoreDataViewer/>
        {/* <ArrayEditor/> */}
      </CoreLayoutItem></>
  );
}