import {
  CoreBox,
  CoreClasses,
  CoreDivider,
  CoreGrid,
  CoreLabel,
  CoreTextButton,
  CoreTypographyBody2,
  getAge,
  getFullName
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { SET_PATIENT } from "../../types/prescription.types";

export default function PrescriptionPatientProfileCard() {
  const dispatch = useDispatch();
  // eslint-disable-next-line etc/no-commented-out-code
  // const location = coreUseLocation();
  // const routesRegistry = React.useContext(CoreRoutesContext);
  // const { id: prescriptionID } = coreUseParams();
  
  const prescription = useSelector(
    (state) => state?.prescription?.prescription
  );
  const { Person = {} } = useSelector(
    (state) => state.prescription.navData || {}
  );

  const {
    gender = null,
    dob = null,
    profileId = null
  } = Person;

  return (
    <>
      <CoreGrid styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_START, CoreClasses.TEXT.TEXT_START]}>
        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN]} gridProps={{ gridSize: { sm: prescription?.isCompleted ? 3 : 4 } }}>
          <CoreLabel>{"Name"}</CoreLabel>

          <CoreTypographyBody2 component="span">
            {getFullName(Person)}
          </CoreTypographyBody2>
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN]} gridProps={{ gridSize: { sm: prescription?.isCompleted ? 3 : 2 } }}>
          <CoreLabel>{"MRN"}</CoreLabel>

          <CoreTypographyBody2 component="span">
            {profileId}
          </CoreTypographyBody2>
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN]} gridProps={{ gridSize: { sm: prescription?.isCompleted ? 3 : 1 } }}>
          <CoreLabel>{"Sex"}</CoreLabel>

          <CoreTypographyBody2 component="span">
            {gender}
          </CoreTypographyBody2>
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN]} gridProps={{ gridSize: { sm: prescription?.isCompleted ? 3 : 3 } }}>
          <CoreLabel>{"Age"}</CoreLabel>

          <CoreTypographyBody2 component="span">
            {getAge(dob)}
          </CoreTypographyBody2>
        </CoreBox>

        {!prescription?.isCompleted && (
          <CoreBox
            gridProps={{ gridSize: { sm: 2 } }}
            styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
          >
            <CoreTextButton
              styleClasses={[CoreClasses.PADDING.P0]}
              label="Change Patient"
              onClick={() => {
              // eslint-disable-next-line etc/no-commented-out-code
              // const prescriptionURL = routesRegistry["prescription"]?.url;
              // const prescriptionSpecificURL = routesRegistry["prescriptionSpecific"]?.url?.replace(":id", prescriptionID);
              
                if (
                /* (location.pathname === prescriptionURL
                  || (prescriptionID && location.pathname === prescriptionSpecificURL)
                ) && */
                  prescription?.isCompleted
                ) {
                  alert(
                    "Sorry!",
                    "Can not change patient...prescription already generated",
                    "error"
                  );
                } else {
                  dispatch({ payload: {}, type: SET_PATIENT });
                }
              }}
            />
          </CoreBox>
        )}
      </CoreGrid>

      <CoreDivider />
    </>
  );
}
