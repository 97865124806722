import React from "react";

import {
  CoreAvatar,
  CoreBox,
  CoreClasses,
  CoreIcon,
  CoreStack,
  CoreTypographyBody1,
  CoreTypographyCaption,
  StatusText,
  __IconTypes,
  getAge
} from "@wrappid/core";
import { useSelector } from "react-redux";

import { getFromMasterData } from "../functions/helper.functions";
import { patientRawDataTransform } from "../utils/json.utils";

export default function PatientSummaryComponent(props) {
  const { rowData } = props || {};
  const [data, setData] = React.useState({});
  const options = useSelector(state => state?.selectOptions?.options);
  
  // eslint-disable-next-line etc/no-commented-out-code
  // const dispatch = useDispatch();
  // const [data, setdata] = React.useState({});
  // const getdata = ({ data }) => {
  //   setdata(data);
  // };
  // React.useEffect(() => {
  //   if(rowData && rowData["RelatedPerson.id"]){
  //     let endpoint = `/data/meta/datas/${rowData["RelatedPerson.id"]}`;
  //     dispatch(apiRequestAction(HTTP.GET, endpoint, true, {}, null, null, getdata));
  //     // eslint-disable-next-line etc/no-commented-out-code
  //     // dispatch(apiRequestAction(HTTP.GET, endpoint, {}, PATIENT_DATA_ENTRY));
  //   }
  // }, [rowData]);
  
  React.useEffect(() => {
    setData(patientRawDataTransform(rowData));
  }, [rowData]);
      
  return (
    <>
      <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.ALIGN_ITEMS_START]}>
        <CoreBox
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR]}
            src={data?.photo}
          />
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses.PADDING.PL1]}>
          <CoreTypographyBody1 paragraph={false} gutterBottom={false} styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_SEMIBOLD]}>
            {[data.firstName, data.middleName, data.lastName].join(" ")}
          </CoreTypographyBody1>

          <CoreTypographyCaption>
            MRN{": "}

            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}>
              {data.profileId || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>

          <CoreStack direction="row" spacing={.5} styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}>

            <CoreIcon 
              type={__IconTypes.MATERIAL_OUTLINED_ICON}
              icon="cake"
              color="primary"
            />
            
            <CoreTypographyCaption>
              {getAge(data.dob)}
            </CoreTypographyCaption>

            <CoreTypographyCaption>
              {"|"}
            </CoreTypographyCaption>

            <CoreIcon 
              type={__IconTypes.MATERIAL_OUTLINED_ICON}
              icon={getFromMasterData(
                data.gender,
                "gender",
                options
              )?.toLowerCase()}
              color="primary"
            />
            
            <CoreTypographyCaption>
              {getFromMasterData(
                data.gender,
                "gender",
                options
              )}
            </CoreTypographyCaption>

          </CoreStack>
        </CoreBox>
      </CoreBox>

      <CoreStack
        direction="row"
        spacing={1}
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}>
        <CoreTypographyCaption>{"ID: " + rowData["id"]}</CoreTypographyCaption>

        <CoreTypographyCaption>
          {"|"}
        </CoreTypographyCaption>

        <StatusText status={rowData["_status"]} />

        <CoreTypographyCaption
          styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD, CoreClasses.TEXT.TEXT_UPPERCASE]}>
        </CoreTypographyCaption>
      </CoreStack>
    </>
  );
}
