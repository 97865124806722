/* eslint-disable etc/no-commented-out-code */
import { CoreLayoutItem, AppBuilderContainerLayout } from "@wrappid/core";

import ContentComp from "./content-components/ContentComp.js";
// import HeaderComp from "./header-components/HeaderComp.js";
import RightDrawerComp from "./right-drawer-components/RightDrawerComp.js";
// import ToolBoxFactory from "../../../utils/ToolBoxFactory.ts";
// import ToolBox from "../../toolbox/ToolBox.ts";

export default function PageBuilder() {
  
  return (
    <> 
      {/* Header area of pagebuilder */} 
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.Header}>
        {/* <HeaderComp /> */}
      </CoreLayoutItem>
      
      {/* Content area of pagebuilder */}
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.Content}>
        <ContentComp />

        {/* <ToolBox variant={ToolBoxFactory.VARIANTS.PROPERTY} />
        
        <ToolBox variant={ToolBoxFactory.VARIANTS.DEVICE} /> */}
        
      </CoreLayoutItem>

      {/* Right-Drawer area of pagebuilder */}
      <CoreLayoutItem id={AppBuilderContainerLayout.PLACEHOLDER.RightDrawer}>
        <RightDrawerComp />
      </CoreLayoutItem>
    </>
  );
}