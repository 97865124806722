import {
  AppContainerLayout,
  CoreBox,
  CoreCard,
  CoreCardContent,
  CoreForm,
  CoreGrid,
  CoreLayoutItem,
  FORM_VIEW_MODE
} from "@wrappid/core";
import { useSelector } from "react-redux";

import { FORM_IDS } from "../constants/formIds.registry";

function ConsultationTimings() {
  const { user: { personID } } = useSelector((state) => state.auth);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreGrid>
          <CoreBox gridProps={{ gridSize: { sm: 2, xs: 0 } }}></CoreBox>

          <CoreCard gridProps={{ gridSize: { sm: 8 } }}>
            <CoreCardContent>
              <CoreForm
                formId={FORM_IDS.__CONSULTATION_TIMINGS}
                mode={FORM_VIEW_MODE}
                initProps={{
                  clinic: {
                    query: {
                      _defaultFilter: encodeURIComponent(
                        JSON.stringify({ personId: personID })
                      ),
                    },
                  },
                }}
              />
            </CoreCardContent>
          </CoreCard>
        </CoreGrid>
      </CoreLayoutItem>
    </>
  );
}

export default ConsultationTimings;
