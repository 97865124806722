import React from "react";

import {
  CoreBox,
  CoreClasses,
  CoreTooltip,
  CoreIcon,
  CoreTypographyCaption
} from "@wrappid/core";

export default function ModelAssociationViewer(props) {

  const { relation } = props;  

  return (
    <CoreBox styleClasses={[CoreClasses.PADDING.P1]}>
      <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]}>
        <CoreTooltip title="Foreign Key" arrow placement="right" >

          <CoreIcon color="action" icon="key" styleClasses={[CoreClasses.MARGIN.M0]} />
        </CoreTooltip>
        {typeof relation.foreignKey === "string" ? (
          <CoreTypographyCaption
            styleClasses={[CoreClasses.MARGIN.M0]}
          >
            {relation.foreignKey}
          </CoreTypographyCaption>
        ) : (
          <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX]} >
            {/* eslint-disable-next-line @typescript-eslint/no-unused-vars*/}
            {Object.entries(relation.foreignKey).map(([key, value], index, array) => (
              <CoreBox key={key}>
                <CoreTypographyCaption styleClasses={[CoreClasses.MARGIN.M0, CoreClasses.TEXT.Text]}>
                  {key}{index < array.length - 1 ? "|" : ""}
                </CoreTypographyCaption>
              </CoreBox>
            ))}
          </CoreBox>
        )}
      </CoreBox>
      <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]}>
        <CoreTooltip title="Primary Key" arrow placement="right" >
          <CoreIcon color="primary" icon="vpn_key" styleClasses={[CoreClasses.MARGIN.M0]} />
        </CoreTooltip>
        <CoreTypographyCaption 
          styleClasses={[CoreClasses.MARGIN.M0]}  
        >
          {relation.sourceKey}
        </CoreTypographyCaption>
      </CoreBox>
                    
      {relation.as && (
        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]} >
          <CoreTypographyCaption styleClasses={[CoreClasses.MARGIN.M0, CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}>
                    Alias{" "}
          </CoreTypographyCaption>
          <CoreTypographyCaption
            styleClasses={[CoreClasses.MARGIN.M0]}
          >
            {relation.as}
          </CoreTypographyCaption>
        </CoreBox>
      )}
                   
      <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]} >
        <CoreTypographyCaption styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD, CoreClasses.MARGIN.M0]}>
                  Type{" "}
                        
        </CoreTypographyCaption>
        <CoreTypographyCaption
          styleClasses={[CoreClasses.MARGIN.M0]}
        >
          {relation.type}
        </CoreTypographyCaption>
      </CoreBox>
    </CoreBox>
  );
}