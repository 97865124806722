import { BUSINESS_EDITOR } from "../../../../constants/AppBuilder.constant";
import EditorFactory from "../../../../factory/Editor.factory";
import { EntityData } from "../../entity/Entity.data";
import { EditorData } from "../Editor.data";

export class BusinessEditorData<TSchema> extends EditorData<TSchema>{
  constructor(
    editorId: string,
    storedEditorContent: any,
    entityData: EntityData<TSchema>
  ){
    super(
      editorId,
      storedEditorContent,
      entityData,
      EditorFactory.VARIANTS.BUSINESS_EDITOR
    );
    this.setEditorRenderedComponent(BUSINESS_EDITOR);
  }

  prepareEditorContent(storedEditorContent: any, entityData: EntityData<TSchema>): void {
    Object.setPrototypeOf(entityData, EntityData.prototype); /** @todo We have to remove the setPrototypeOf as the type casting sould not be here */
    this.editorContent = {
      extraInfo: entityData.getExtraInfo(),
      schema   : entityData.getSchema(),
    };
  }
}