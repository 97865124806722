// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import React from "react";

import {
  CoreAppBar,
  CoreBox,
  CoreClasses,
  CoreCssBaseline,
  CoreLayoutPlaceholder,
  CoreResourceContext
} from "@wrappid/core";

import AppFooter from "./AppFooter";
export default function RxefyAppLayout() {
  // eslint-disable-next-line no-unused-vars
  const resources = React.useContext(CoreResourceContext);

  return (
    <>
      <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX]}>

        <CoreCssBaseline />

        <CoreAppBar logo={resources?.appLogo} leftMenuEnabled={true} />

        <CoreBox
          component="main"
          styleClasses={[CoreClasses.LAYOUT.CONTENT_CONTAINER, CoreClasses.LAYOUT.P0, CoreClasses.LAYOUT.APPBAR_HEIGHT]}
        >

          <CoreLayoutPlaceholder id={RxefyAppLayout.PLACEHOLDER.CONTENT} />

          <AppFooter/>
        </CoreBox>
      </CoreBox>

    </>
  );
}

RxefyAppLayout.PLACEHOLDER = { CONTENT: "content" };
