import React, { useState, useEffect, useRef } from "react";

import {
  CoreBox,
  CoreTable,
  CoreTableBody,
  CoreTableRow,
  CoreTableCell,
  CoreClasses,
  CoreTooltip,
  CoreSelect,
  CoreMenuItem,
  CoreTextField,
  CoreTypographyBody2
} from "@wrappid/core";
import { useDispatch } from "react-redux";

import { renameAttribute, updateProperty } from "../../actions/builder.actions";

export default function PropertyToolBox(props) {
  const { toolboxContent = [] } = props;
  const dispatch = useDispatch();

  const nameProperty = Array.isArray(toolboxContent) 
    ? toolboxContent.find(prop => prop.name === "name") 
    : undefined;
  const nameValue = nameProperty?.types?.[0]?.value;
  // Track the current attribute name in state instead of derived value
  const [currentAttributeName, setCurrentAttributeName] = useState( nameValue || "");
  const [values, setValues] = useState({});
  const [lastDispatchedValues, setLastDispatchedValues] = useState({});
  const isInitialMount = useRef(true);

  // Initialize values from toolboxContent
  useEffect(() => {

    if (Array.isArray(toolboxContent)) {
      const initialValues = {};

      toolboxContent.forEach((prop) => {
        initialValues[prop.name] = prop.types?.[0]?.value || "";
      });
      setValues(initialValues);
      setLastDispatchedValues(initialValues);

      // Update currentAttributeName from toolboxContent only on initial mount
      // or when toolboxContent changes from empty to non-empty
      if (isInitialMount.current || !nameProperty?.types?.[0]?.value) {
        const newAttributeName = nameProperty?.types?.[0]?.value || "";

        setCurrentAttributeName(newAttributeName);
        isInitialMount.current = false;
      }
    }
  }, [toolboxContent]);
  // Update currentAttributeName when nameProperty changes
  useEffect(() => {
    const newAttributeName = nameProperty?.types?.[0]?.value || "";

    setCurrentAttributeName(newAttributeName);
  }, [nameProperty]); // Depend on nameProperty

  const handleChange = (propName) => (event) => {
    const newValue = event.target.value;

    setValues((prevValues) => ({
      ...prevValues,
      [propName]: newValue,
    }));
  };
  
  // Improved useEffect to handle updates and renames properly
  useEffect(() => {
    if (JSON.stringify(values) === JSON.stringify(lastDispatchedValues)) {
      return;
    }

    const timeoutId = setTimeout(() => {
      // Find changed values
      const changedEntries = Object.entries(values).filter(
        ([key, value]) => value !== lastDispatchedValues[key]
      );

      // Process changes
      changedEntries.forEach(([propName, newValue]) => {
        if (propName === "name") {
          // Update the current attribute name after rename
          if (newValue !== currentAttributeName) {
            dispatch(renameAttribute(newValue, currentAttributeName));
            setCurrentAttributeName(newValue); // Update tracking of current name
          }
        } else {
          dispatch(updateProperty(currentAttributeName, propName, newValue));
        }
      });

      // Update last dispatched values
      setLastDispatchedValues(values);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [values, currentAttributeName, dispatch, lastDispatchedValues]);

  const renderInput = (prop) => {
    const type = prop.types[0];
    const currentValue = values[prop.name] || type?.value || "";

    if (type?.type === "boolean") {
      return (
        <CoreSelect
          fullWidth
          value={String(currentValue)}
          variant="standard"
          size="small"
          styleClasses={[CoreClasses.MARGIN.M0]}
          onChange={handleChange(prop.name)}
        >
          <CoreMenuItem value="true">True</CoreMenuItem>
          <CoreMenuItem value="false">False</CoreMenuItem>
        </CoreSelect>
      );
    }

    if (Array.isArray(type?.validValues) && type.validValues.length > 0) {
      return (
        <CoreSelect
          fullWidth
          value={currentValue}
          variant="standard"
          size="small"
          styleClasses={[CoreClasses.MARGIN.M0]}
          onChange={handleChange(prop.name)}
        >
          {type.validValues.map((value) => (
            <CoreMenuItem key={value} value={value}>
              {value}
            </CoreMenuItem>
          ))}
        </CoreSelect>
      );
    }

    return (
      <CoreTextField
        fullWidth
        size="small"
        value={currentValue}
        placeholder={`Enter ${prop.name}`}
        onChange={handleChange(prop.name)}
      />
    );
  };

  if (!Array.isArray(toolboxContent) || toolboxContent.length === 0) {
    return <CoreTypographyBody2>No property available</CoreTypographyBody2>;
  }

  return (
    <CoreBox>
      <CoreTable>
        <CoreTableBody>
          {toolboxContent.map((prop, index) => (
            <CoreTableRow key={index}>
              <CoreTableCell
                styleClasses={[CoreClasses.PADDING.P0, CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
              >
                <CoreTooltip title={prop.description} arrow placement="left">
                  {prop.name} :
                </CoreTooltip>
              </CoreTableCell>
              <CoreTableCell styleClasses={[CoreClasses.PADDING.P0]}>
                {renderInput(prop)}
              </CoreTableCell>
            </CoreTableRow>
          ))}
        </CoreTableBody>
      </CoreTable>
    </CoreBox>
  );
}