import { Dispatch } from "redux";

import AppBuilderFactory from "./AppBuilder.factory";
import { EntityData } from "../components/data/entity/Entity.data";
import { HistoryToolbarData } from "../components/data/toolbar/HistoryToolbar.data";
import { RequestForReviewToolbarData } from "../components/data/toolbar/RequestForReviewToolbar.data";
import { SaveToolbarData } from "../components/data/toolbar/SaveToolbar.data";

export type ToolType = typeof SaveToolbarData.prototype | typeof HistoryToolbarData.prototype | typeof RequestForReviewToolbarData.prototype;

/**
 * ToolbarFactory class provides methods to create different toolbar components based on the icon type.
 * The factory pattern is used to abstract the instantiation of specific toolbar data.
 */
export default class ToolbarFactory {
  /** 
   * A constant object holding various icon names for different tools available in the toolbar.
   * These action correspond to specific tool actions within the toolbar.
   */
  static ACTION = {
    HISTORY           : "Histort",
    REQUEST_FOR_REVIEW: "Review",
    SAVE              : "Save",
  } as const;

  /**
   * Factory method to get the appropriate viewer data based on the provided tool icon.
   * This method creates a new instance of a specific toolbar tool data class based on the icon name.
   * 
   * @param toolsIcon - The icon name of the toolbar tool. It determines which tool data to return.
   * @returns A new instance of the corresponding ToolbarData subclass.
   * @throws Error if the provided icon is not recognized.
   */
  static getEditorData<TSchema>(
    toolbarId: string,
    toolsIcon: string, 
    entityData: EntityData<TSchema>,
    builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES],
    dispatch: Dispatch
  ) :ToolType {
    switch (toolsIcon) {
      case ToolbarFactory.ACTION.HISTORY: {
        return new HistoryToolbarData(toolbarId, entityData, builderType, dispatch); // Return a new instance of HistoryToolbarData for the "history" icon.
      }

      case ToolbarFactory.ACTION.REQUEST_FOR_REVIEW: {
        return new RequestForReviewToolbarData(toolbarId, entityData, builderType, dispatch); // Return a new instance of RequestForReviewToolbarData for the "rate_review" icon.
      }

      case ToolbarFactory.ACTION.SAVE: {
        return new SaveToolbarData(toolbarId, entityData, builderType, dispatch); // Return a new instance of SaveToolbarData for the "save" icon.
      } 

      // If the provided icon is not recognized, throw an error.
      default: {
        throw new Error(`Unknown entity type: ${toolsIcon}`);
      }
    }
  }

  static getToolPrototype(toolbarType: string): ToolType{
    switch (toolbarType) {
      case ToolbarFactory.ACTION.SAVE:
        return SaveToolbarData.prototype;
        break;

      case ToolbarFactory.ACTION.HISTORY:
        return HistoryToolbarData.prototype;
        break;

      case ToolbarFactory.ACTION.REQUEST_FOR_REVIEW:
        return RequestForReviewToolbarData.prototype;
        break;
    
      default:
        break;
    }
  }
}

