
import {
    __EntityStatus,
    __IconTypes,
    CoreDataTable
} from "@wrappid/core";
import { useSelector } from "react-redux";

import AppointmentDetails from "./AppointmentDetails";
import AppointmentSummaryComponent from "./AppointmentSummaryComponent";

export default function OnHoldAppointment(props) {
  const personID = useSelector((state) => state?.auth?.user?.personID);

  const tableRowActions = [
    {
      action: (data) => {
        props.makeCurrent(
          data,
          data?.Prescription && data?.Prescription[0] && data?.Prescription[0].id,
          true
        );
      },
      crearRowData: true,
      hide        : (data) => {
        if (data?.Prescription && data?.Prescription[0] && data?.Prescription[0].id) {
          return false;
        } else {
          return true;
        }
      },

      icon: { icon: "fa-file-prescription", type: __IconTypes.FONTAWESOME_V5_SOLID_ICON },

      label: "Continue Prescription",

      type: "action",
    },
    {
      action: (data) => {
        props.makeCurrent(data, null, true);
      },
      hide: (data) => {
        if (data?.Prescription && data?.Prescription[0] && data?.Prescription[0].id) {
          return true;
        } else {
          return false;
        }
      },

      icon: { icon: "fa-file-prescription", type: __IconTypes.FONTAWESOME_V5_SOLID_ICON },

      label: "+ Prescription",

      type: "action",
    },
    {
      action: (data) => {
        props.makeCurrent(data);
      },
      clearRowData: true,
      icon        : { icon: "fa-angle-double-up", type: __IconTypes.FONTAWESOME_V5_SOLID_ICON },
      label       : "Make Current",
      type        : "action",
    },
  ];

  return (
    <>
      <CoreDataTable
        entity="OnHoldAppointments"
        enableCreateEntity={false}
        hideForm={true}
        rowActions={tableRowActions}
        filterQuery={{
          filter: {
            _status : __EntityStatus.ONHOLD,
            doctorId: personID,
          },
        }}
        noHeaderInApp={true}
        summaryRendererComponent={AppointmentSummaryComponent}
        preRenderDetailsPaneComponent={AppointmentDetails}
      />
    </>
  );
}