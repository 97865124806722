/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import {
  AppContainerLayout,
  CoreBox,
  CoreClasses,
  CoreLayoutItem,
  HTTP,
  apiRequestAction
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import AssistantDashboard from "./AssistantDashboard";
import BackofficeDashboard from "./BackofficeDashboard";
import DoctorDashboard from "./DoctorDashboard";
import PatientDashboard from "./PatientDashboard";
import SalesForceDashboard from "./SalesForceDashboard";
import SystemAdminDashboard from "./SystemAdminDashboard";
import { ApiRegistry } from "../apis.registry";
import { userSettingsConstants } from "../constants/constants";
import {
  GET_PROFILE_BASIC_ERROR,
  GET_PROFILE_BASIC_SUCCESS,
  GET_PROFILE_CONTACT_ERROR,
  GET_PROFILE_CONTACT_SUCCESS
} from "../types/authTypes";
import {
  COMMON_MASTER_DATA_ERROR,
  COMMON_MASTER_DATA_SUCCESS,
  GET_ADRESS_TYPE_ERROR,
  GET_ADRESS_TYPE_SUCCESS
} from "../types/commonTypes";
import {
  GET_DEPT_ERROR,
  GET_DEPT_SUCCESS,
  MAIN_MASTER_DATA_ERROR,
  MAIN_MASTER_DATA_SUCCESS
} from "../types/mdmTypes";
import {
  GET_PRESCRIPTION_TEMPLATES_ERROR,
  GET_PRESCRIPTION_TEMPLATES_SUCCESS,
  GET_TEMPLATE_DESIGNS_ERROR,
  GET_TEMPLATE_DESIGNS_SUCCESS
} from "../types/prescriptionTypes";

function Dashboard() {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateFlagAfterProfile, setUpdateFlagAfterProfile] = useState(false);
  const [updateUserSettings, setUpdateUserSettings] = useState(false);

  const { accessToken, uid, role: { role: userRole }, user: { id: userID, personId: personID } } = useSelector((state) => state.auth); // --- auth, navData, permissions, role, uid, accessToken
  const settings = useSelector((state) => state.settings); // --- userSettings, getUserSettingsSuccess
  const dispatch = useDispatch();

  // componentDidUpdate
  useEffect(() => {
    if (accessToken && accessToken.length > 0 && !updateFlag) {
      // eslint-disable-next-line no-console
      console.log("---FIRST CALLS", accessToken);
      setUpdateFlag(true);
      GetCommonMaster({ level: 2, name: "gender" });
      GetPresriptionMasterData({ level: 10, name: "prescription" });

      GetAddressType();
      GetProfileContact();
      GetProfileBasic({ _defaultFilter: encodeURIComponent(JSON.stringify({ userId: uid })) });

      GetTemplateDesigns();
      GetDept({});
    }

    if (userID && !updateFlagAfterProfile) {
      setUpdateFlagAfterProfile(true);
    }

    /**
     * after user settings fetched all reducer update done here
     */

    if (settings.getUserSettingsSuccess && !updateUserSettings) {
      setUpdateUserSettings(true);
      let userSetting =
        settings.userSettings[userSettingsConstants.LEFT_DRAWER_STATE];
    }
  }, []);

  useEffect(()=>{
    if (personID && !updateFlagAfterProfile) {
      setUpdateFlagAfterProfile(true);
      GetPrescriptionTemplates(personID, personID?.departmentId);
    }
  }, [personID]);

  const GetAddressType = (query) => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_ADDRESS_TYPES_API,
        true,
        query,
        GET_ADRESS_TYPE_SUCCESS,
        GET_ADRESS_TYPE_ERROR
      )
    );
  };

  const GetCommonMaster = (query) => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_MASTER_DATA_API,
        true,
        query,
        COMMON_MASTER_DATA_SUCCESS,
        COMMON_MASTER_DATA_ERROR
      )
    );
  };

  const GetDept = () => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_DEPARTMENTS_API,
        true,
        {},
        GET_DEPT_SUCCESS,
        GET_DEPT_ERROR
      )
    );
  };

  const GetPrescriptionTemplates = (personId) => {
    const query = {
      or: {
        name    : { like: "sys_gen_%" },
        personId: personId,
      },
    };

    dispatch(
      apiRequestAction(
        HTTP.GET,
        // "/person/" + personId + "/department/" + deptId + "/prescriptionTemplates",
        "/business/all/prescriptionTemplate",
        true,
        { _defaultFilter: encodeURIComponent(JSON.stringify(query)) },
        GET_PRESCRIPTION_TEMPLATES_SUCCESS,
        GET_PRESCRIPTION_TEMPLATES_ERROR
      )
    );
  };

  const GetPresriptionMasterData = (query) => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_MASTER_DATA_API,
        true,
        query,
        MAIN_MASTER_DATA_SUCCESS,
        MAIN_MASTER_DATA_ERROR
      )
    );
  };

  const GetProfileContact = () => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_PROFILE_CONTACT_API,
        true,
        {},
        GET_PROFILE_CONTACT_SUCCESS,
        GET_PROFILE_CONTACT_ERROR
      )
    );
  };

  const GetProfileBasic = (query) => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_PROFILE_BASIC_API,
        true,
        query,
        GET_PROFILE_BASIC_SUCCESS,
        GET_PROFILE_BASIC_ERROR
      )
    );
  };

  const GetTemplateDesigns = () => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_PRESCRIPTION_TEMPLATE_DESIGNS_API,
        true,
        {},
        GET_TEMPLATE_DESIGNS_SUCCESS,
        GET_TEMPLATE_DESIGNS_ERROR
      )
    );
  };

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreBox styleClasses={[CoreClasses.PADDING.PX1, CoreClasses.PADDING.PB1]}>
          {userRole === "doctor" ? (
            <DoctorDashboard />
          ) : userRole === "patient" ? (
            <PatientDashboard />
          ) : userRole === "assistant" ? (
            <AssistantDashboard />
          ) : userRole === "onboarding_salesforce" ? (
            <SalesForceDashboard />
          ) : userRole === "onboarding_backoffice" ? (
            <BackofficeDashboard />
          ) : userRole === "system_admin" ? (
            <SystemAdminDashboard />
          ) : null}
        </CoreBox>
      </CoreLayoutItem>
    </>
  );
}

export default Dashboard;
