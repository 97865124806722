import React, { useEffect, useState } from "react";

import { CoreToolBox, CoreIconButton, CoreIcon, CoreComponent } from "@wrappid/core";

import { ToolBoxData } from "../data/toolbox/ToolBox.data";

type ToolBoxType<T extends ToolBoxData> = {toolboxData: T};

export default function ToolBox<T extends ToolBoxData>(props: ToolBoxType<T>) {
  const { toolboxData } = props;
  
  Object.setPrototypeOf(toolboxData, ToolBoxData.prototype);
  const [toolboxContent, setToolboxContent] = useState(toolboxData.getToolboxContent());

  const content = toolboxData.getToolboxContent();

  useEffect(() => {
    
    // Update the state only if the content has changed or is different from the current content
    if (content && content.length > 0 && content !== toolboxContent) {
      setToolboxContent(content);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolboxData, content]);

  return (
    <CoreToolBox 
      toolTitle={toolboxData.getToolboxTitle()} 
      expandProp={toolboxData.getToolboxExpand()} 
      resize={toolboxData.getToolboxResize()} 
      toolboxActionButton={
        <>
          <CoreIconButton>
            <CoreIcon icon={toolboxData.getUpArrowIcon()} />
          </CoreIconButton>
          <CoreIconButton>
            <CoreIcon icon={toolboxData.getDownArrowIcon()} />
          </CoreIconButton>
          <CoreIconButton>
            <CoreIcon icon={toolboxData.getRemoveIcon()} />
          </CoreIconButton>
        </>
      }>
      <CoreComponent componentName={toolboxData.getToolboxRenderedComponent()} toolboxContent={toolboxContent}/>

    </CoreToolBox>
  );
}