/* eslint-disable etc/no-commented-out-code */
import { Dispatch } from "redux";

import { AppBuilderData } from "./AppBuilder.data";
import { FORM_ICON } from "../../../constants/AppBuilder.constant";
import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import EditorFactory from "../../../factory/Editor.factory";
import ToolbarFactory from "../../../factory/Toolbar.factory";
import ToolBoxFactory from "../../../factory/ToolBox.factory";
import { EntityData } from "../entity/Entity.data";

/**
 * A concrete implementation of the `AppBuilderData` abstract class.
 * This class specializes in managing and preparing toolbox, viewer, toolbar data for a form builder.
 */
export class FormBuilderData<TSchema> extends AppBuilderData<TSchema> {
  /** Static property to store meta information related to the toolbox.  
   * This updated or accessed across instances of this class. */
  private static toolboxMeta = { [ToolBoxFactory.VARIANTS.PROPERTY]: {} };

  /** Static property to store name of viewers. */
  private static viewerMeta = [EditorFactory.VARIANTS.FORM_EDITOR, EditorFactory.VARIANTS.JSON_EDITOR];

  /** Static property to store icons related to the toolbar. */
  private static toolbarMeta = [ToolbarFactory.ACTION.SAVE];

  /** Constructor that initializes an instance of `FormBuilderData`
   *  with the provided entity data and pre-defined constants. 
   * 
   * @param entityData The dynamic entity data that is passed to the base class constructor. 
   * */
  constructor(
    builderId: string,
    storedToolbarData: any,
    storedToolboxData: any,
    storedEditorData: any,
    entityData: EntityData<TSchema>,
    dispatch: Dispatch) {
    super(
      builderId,
      storedToolbarData,
      storedToolboxData,
      storedEditorData,
      entityData,
      AppBuilderFactory.BUILDER_TYPES.FORM, 
      FORM_ICON, FormBuilderData.toolboxMeta, 
      FormBuilderData.viewerMeta, 
      FormBuilderData.toolbarMeta,
      dispatch
    );
  }

}