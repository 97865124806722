import React, { useState } from "react";

import {
  AppContainerLayout,
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreLayoutItem,
  CoreTypographyBody1,
  HTTP,
  apiRequestAction,
  coreUseParams
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { ApiRegistry } from "../../apis.registry";
import {
  GET_PRESCRIPTION_TEMPLATES_ERROR,
  GET_PRESCRIPTION_TEMPLATES_SUCCESS,
  GET_TEMPLATE_DESIGNS_ERROR,
  GET_TEMPLATE_DESIGNS_SUCCESS,
  RESET_PRESCRIPTION_REDUCER,
  UPDATE_PRESCRIPTION_TEMPLATES_ERROR,
  UPDATE_PRESCRIPTION_TEMPLATES_SUCCESS
} from "../../types/prescription.types";
import {
  DesignFormation,
  VitalsFormation,
  checkStatus
} from "../../utils/helper";
import TemplateSection from "./TemplateSection";

/**
 * @todo
 * Template editor - restrict forms and fields with master data
 *
 * @param {*} props
 * @returns
 */
export default function TemplateEditor(props) {
  const { id } = coreUseParams();
  const tempID = id || props?.data?.id;

  const dispatch = useDispatch();

  const [template, setTemplate] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [comprehensiveTemplate, setComprehensiveTemplate] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [templateDesign, setTemplateDesign] = useState(null);

  const [formedDesign, setFormedDesign] = useState(null);

  const [vitals, setVitals] = useState([]);
  const personID = useSelector((state) => state?.auth?.user?.personID);

  const prescriptionTemplates = useSelector(
    (state) => state?.prescription?.prescriptionTemplates
  );

  const updateLoading = useSelector(
    (state) => state?.prescription?.updatePrescriptionTemplateLoading
  );

  const updateSuccess = useSelector(
    (state) => state?.prescription?.updatePrescriptionTemplateSuccess
  );

  const templatesLoading = useSelector(
    (state) => state?.prescription?.prescriptionTemplatesLoading
  );

  const templateDesigns = useSelector(
    (state) => state?.prescription?.templateDesigns
  );

  const mainMasterData = useSelector((state) => state?.mdm?.mainMasterData);

  React.useEffect(() => {
    setDataPoints();
  }, [tempID, templatesLoading, prescriptionTemplates]);

  React.useEffect(() => {
    if (updateSuccess) {
      // -- console.log("CALLING%%%%%%%%", updateSuccess, refreshed);
      // -- setRefresh(true);
      dispatch({
        payload: {
          updatePrescriptionTemplateError  : false,
          updatePrescriptionTemplateLoading: false,
          updatePrescriptionTemplateMsg    : false,
          updatePrescriptionTemplateSuccess: false
        },
        type: RESET_PRESCRIPTION_REDUCER
      });
      dispatch(
        apiRequestAction(
          HTTP.GET,
          "/business/all/prescriptionTemplate",
          true,
          {
            _defaultFilter: encodeURIComponent(
              JSON.stringify({
                or: {
                  name    : { like: "sys_gen_%" },
                  personId: personID
                }
              })
            )
          },
          GET_PRESCRIPTION_TEMPLATES_SUCCESS,
          GET_PRESCRIPTION_TEMPLATES_ERROR
        )
      );
    }
  }, [tempID, updateSuccess]);

  const setDataPoints = () => {
    let template = prescriptionTemplates?.find(
      (tmp) => tmp.id === Number(tempID)
    );

    let comprehensiveTemplate = prescriptionTemplates?.find(
      (tmp) => tmp.name === "sys_gen_comprehensive"
    );

    let templateDesign = templateDesigns?.find(
      (tmp) => tmp.templateId === comprehensiveTemplate?.id
    );

    // eslint-disable-next-line no-console
    console.log(
      "TEMPLATE",
      template,
      templateDesign,
      comprehensiveTemplate
    );

    let designArr = DesignFormation(
      templateDesign?.extraInfo?.body,
      mainMasterData,
      comprehensiveTemplate,
      templateDesign?.extraInfo?.group
    );

    let vitalForm = VitalsFormation(
      comprehensiveTemplate,
      mainMasterData,
      prescriptionTemplates
    );

    // eslint-disable-next-line no-console
    console.log("DESIGN", designArr);
    setTemplate(template);
    setComprehensiveTemplate(comprehensiveTemplate);
    setTemplateDesign(templateDesign);
    setFormedDesign(designArr);
    setVitals(vitalForm);
  };
  const onOperate = (name, operation) => {
    let url = ApiRegistry.UPDATE_PRESCRIPTION_TEMPLATE_MASTER_DATA.replace(
      ":personId",
      personID
    );

    url = url.replace(":masterName", name);
    dispatch(
      apiRequestAction(
        HTTP.POST,
        url,
        true,
        {
          operation,
          templateId: tempID
        },
        UPDATE_PRESCRIPTION_TEMPLATES_SUCCESS,
        UPDATE_PRESCRIPTION_TEMPLATES_ERROR
      )
    );
    dispatch(
      apiRequestAction(
        HTTP.GET,
        ApiRegistry.GET_PRESCRIPTION_TEMPLATE_DESIGNS_API,
        true,
        {},
        GET_TEMPLATE_DESIGNS_SUCCESS,
        GET_TEMPLATE_DESIGNS_ERROR
      )
    );
    // -- setRefresh(false);
  };

  // eslint-disable-next-line no-console
  console.log("Location", mainMasterData, formedDesign);
  // eslint-disable-next-line no-console
  // -- console.log("ALL CLINIC", allClinics);
  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreBox styleClasses={[CoreClasses.PADDING.P1]}>
          <CoreTypographyBody1>
                Template: {template?.label}
          </CoreTypographyBody1>

          <CoreGrid container={true}>
            {formedDesign?.map((formElement, index) =>
              formElement.groupFlag ? (
                <CoreGrid
                  key={`formedDesign-${index}`}
                  gridProps={formElement.gridProps}
                >
                  {formElement?.data?.map(
                    (groupedFormElement, index) => (
                      <CoreGrid
                        key={`formElementData-${index}`}
                        gridProps={
                          groupedFormElement.design || {}
                        }
                      >
                        <TemplateSection
                          el={groupedFormElement}
                          label={
                            mainMasterData
                              ? mainMasterData[0]?.Children?.find(
                                (tmp) => tmp.name === groupedFormElement.name
                              )?.label
                              : null
                          }
                          disabled={updateLoading}
                          onOperate={onOperate}
                          currentTemplate={template}
                          mainMasterData={mainMasterData}
                          allTemplates={prescriptionTemplates}
                          present={checkStatus(
                            groupedFormElement.name,
                            template,
                            mainMasterData?.find(
                              (tmp) => tmp.name === "prescription"
                            )?.Children,
                            prescriptionTemplates
                          )}
                          vitals={vitals}
                        />
                      </CoreGrid>
                    )
                  )}
                </CoreGrid>
              ) : (
                <CoreGrid
                  key={`formedDesign-${index}`}
                  gridProps={formElement.design || {}}
                >
                  <TemplateSection
                    el={formElement}
                    label={
                      mainMasterData
                        ? mainMasterData[0]?.Children?.find(
                          (tmp) =>
                            tmp.name === formElement.name
                        )?.label
                        : null
                    }
                    disabled={updateLoading}
                    onOperate={onOperate}
                    currentTemplate={template}
                    mainMasterData={mainMasterData}
                    allTemplates={prescriptionTemplates}
                    present={checkStatus(
                      formElement.name,
                      template,
                      mainMasterData?.find(
                        (tmp) => tmp.name === "prescription"
                      )?.Children,
                      prescriptionTemplates
                    )}
                    vitals={vitals}
                  />
                </CoreGrid>
              )
            )}
          </CoreGrid>
        </CoreBox>
      </CoreLayoutItem>
    </>
  );
}
