import {
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreLabel,
  CoreTypographyBody1,
  CoreTypographySubtitle2
} from "@wrappid/core";

import { convertToLocale } from "../utils/time.utils";

export default function ConsultationTimingCard(props) {
  const { clinic, dontShowClinicName, hideEmpty = false } = props;
  const days = [
    { label: "Monday", value: 2 },
    { label: "Tuesday", value: 3 },
    { label: "Wednesday", value: 4 },
    { label: "Thursday", value: 5 },
    { label: "Friday", value: 6 },
    { label: "Saturday", value: 7 },
    { label: "Sunday", value: 1 },
  ];

  return (
    <>
      <CoreBox styleClasses={[CoreClasses.MARGIN.MY1, CoreClasses.PADDING.P1]}>
        {dontShowClinicName ? null : <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_TRUNCATE]}>
          Timings - {clinic?.fullName || "Personal"}
        </CoreTypographyBody1>}

        <CoreGrid>
          {days.map((day, index) => {
            return (
              hideEmpty && !props[day.value] ? null :
                (<CoreBox
                  key={`consult-days-${index}`}
                  gridProps={{ gridSize: { sm: 6 } }}
                >
                  <CoreLabel>{day.label}</CoreLabel>

                  {props[day.value] ? (
                    props[day.value].map((times, index) => (
                      <CoreTypographySubtitle2
                        styleClasses={[CoreClasses.MARGIN.MB0]}
                        key={`times-${index}`}>
                        {convertToLocale(times.startTime) +
                        " - " +
                        convertToLocale(times.endTime)}
                      </CoreTypographySubtitle2>
                    ))
                  ) : (
                    <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.MB0]}>
                      {"Not available"}
                    </CoreTypographySubtitle2>
                  )}
                </CoreBox>)
            );
          })}
        </CoreGrid>
      </CoreBox>
    </>
  );
}
