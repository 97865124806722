import React from "react";

import {
  CoreBox, CoreClasses, CoreIcon, CoreIconButton, CoreStack, CoreTooltip, CoreTypographyBody1
} from "@wrappid/core";

import ToolBox from "./ToolBox";
import { ToolBoxData } from "../data/toolbox/ToolBox.data";

type ToolBoxGroupType<T extends ToolBoxData> = {toolboxData: T[]};

export default function ToolBoxGroup<T extends ToolBoxData>(props: ToolBoxGroupType<T>) {
  const { toolboxData } = props;

  return (
    <CoreStack
      styleClasses={[CoreClasses.OVERFLOW.OVERFLOW_Y_SCROLL, CoreClasses.BORDER.BORDER_START, CoreClasses.BORDER.BORDER_COLOR_GREY_400]}
    >
      <CoreBox
        styleClasses={[
          CoreClasses.DISPLAY.FLEX,
          CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN,
          CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER,
          CoreClasses.BORDER.BORDER_BOTTOM,
          CoreClasses.WIDTH.W_100
        ]}
      >
        <CoreBox
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
        > 
          {/** This icon should be changed dynnamically based on collapse and expand */}
          <CoreTooltip title="Collapse All" arrow>
            <CoreIconButton>
              <CoreIcon 
                icon="unfold_less" 
              // onClick={() => dispatch(toolboxCollapseExpand(false))}
              />
            </CoreIconButton>
          </CoreTooltip>
        </CoreBox>

        <CoreTooltip title="Select Selector Box" arrow>
          <CoreIconButton
            // onClick={() => setToolBoxController(true)}
          >
            <CoreIcon icon="more_vert" />
          </CoreIconButton>
        </CoreTooltip>
      </CoreBox>

      {/* Check if toolboxData is an array with items to map over */}
      {toolboxData && toolboxData.length > 0 ? (
        toolboxData.map((eachToolBoxData, index) => (
          <ToolBox
            key={"" + index} // Using index as the key (preferably use a unique value if available)
            toolboxData={eachToolBoxData}
          />
        ))
      ) : (
        <CoreTypographyBody1 styleClasses={[CoreClasses.PADDING.PY5]}>
          No toolboxes available to display.
        </CoreTypographyBody1>
      )}
    </CoreStack>
  );
}
