import { FunctionsRegistry as AppBuilderFunctionsRegistry } from "./app-builder/functions.registry";
import { FunctionsRegistry as AppointmentFunctionsRegistry } from "./appointment/functions.registry";
import { FunctionsRegistry as AuthFunctionsRegistry } from "./auth/functions.registry";
import { FunctionsRegistry as ClinicFunctionsRegistry } from "./clinic/functions.registry";
import { FunctionsRegistry as CommunicationFunctionsRegistry } from "./communication/functions.registry";
import { FunctionsRegistry as DashboardFunctionsRegistry } from "./dashboard/functions.registry";
import { FunctionsRegistry as DataManagementFunctionsRegistry } from "./data-management/functions.registry";
import { FunctionsRegistry as PatientFunctionsRegistry } from "./patient/functions.registry";
import { FunctionsRegistry as PrescriptionFunctionsRegistry } from "./prescription/functions.registry";
import { FunctionsRegistry as RxprofileFunctionsRegistry } from "./rxprofile/functions.registry";
import { FunctionsRegistry as RxsettingsFunctionsRegistry } from "./rxsettings/functions.registry";
import { FunctionsRegistry as SupportFunctionsRegistry } from "./support/functions.registry";
import { FunctionsRegistry as UserManagementFunctionsRegistry } from "./user-management/functions.registry";

export default {
...AppBuilderFunctionsRegistry,
...AppointmentFunctionsRegistry,
...AuthFunctionsRegistry,
...ClinicFunctionsRegistry,
...CommunicationFunctionsRegistry,
...DashboardFunctionsRegistry,
...DataManagementFunctionsRegistry,
...PatientFunctionsRegistry,
...PrescriptionFunctionsRegistry,
...RxprofileFunctionsRegistry,
...RxsettingsFunctionsRegistry,
...SupportFunctionsRegistry,
...UserManagementFunctionsRegistry,
}