import {
  CoreAvatar, CoreBox, CoreClasses, CoreGrid, CoreTypographyBody1, CoreTypographyCaption 
} from "@wrappid/core";

export default function PatientBasicInfo(props) {
  const { rowData } = props || {};

  return (
    <CoreGrid>
      <CoreBox
        gridProps={{ gridSize: { md: 2, sm: 2, xs: 3 } }}
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
      >

        <CoreAvatar
          styleClasses={[CoreClasses.DATA_DISPLAYAVATAR_SMALL]}
          src={rowData["RelatedPerson.photoUrl"]}
        />
      </CoreBox>

      <CoreBox gridProps={{ gridSize: { md: 10, sm: 10, xs: 9 } }}>
        <CoreTypographyBody1
          styleClasses={[CoreClasses.TEXT.TEXT_BOLD]}
        >
          {[rowData["RelatedPerson.firstName"], rowData["RelatedPerson.middleName"], rowData["RelatedPerson.lastName"]].join(" ")}
        </CoreTypographyBody1>

        <CoreTypographyCaption>
          MRN{": "}

          <CoreTypographyCaption
            component="span"
            styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}
          
          >
            {rowData["RelatedPerson.profileId"] || "N/A"}
          </CoreTypographyCaption>
        </CoreTypographyCaption>
      </CoreBox>
    </CoreGrid>
  );
}