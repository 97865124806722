import About from "../components/About";
import Help from "../components/Help";
import RxefyAppLayout from "../components/layouts/RxefyAppLayout";
import Privacy from "../components/Privacy";
import Term from "../components/Term";
import modulesRoutesRegistry from "../modules/modules.routes.registry";

export const RoutesRegistry = {
  About: {
    Page        : { appComponent: About.name, layout: RxefyAppLayout.name },
    authRequired: false,
    entityRef   : "about",
    url         : "about"
  },
  Help: {
    Page        : { appComponent: Help.name, layout: RxefyAppLayout.name },
    authRequired: false,
    entityRef   : "help",
    url         : "help"
  },
  Privacy: {
    Page        : { appComponent: Privacy.name, layout: RxefyAppLayout.name },
    authRequired: false,
    entityRef   : "privacy",
    url         : "privacy"
  },
  Term: {
    Page        : { appComponent: Term.name, layout: RxefyAppLayout.name },
    authRequired: false,
    entityRef   : "term",
    url         : "terms"
  },
  ...modulesRoutesRegistry
};