import { getWrappidUUID } from "@wrappid/core/utils/appUtils";
import { Dispatch } from "redux";

import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import ToolbarFactory from "../../../factory/Toolbar.factory";
import { EntityData } from "../entity/Entity.data";

/** Type declaration for toolbar actions */
export type ToolbarActionsType = { [eventType: string]: string };

/** Type declaration for action icons */
export type ActionIconType = {
  iconName: string;
  iconType: string;
};

/**
 * Abstract class representing toolbar data.
 * This class serves as a blueprint for creating toolbar objects, defining the structure of 
 * toolbar attributes like ID, type, icon title, and actions.
 */
export abstract class ToolbarData<TSchema> {
  private dispatch: Dispatch;

  private builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES];

  /** Unique identifier for the toolbar */
  readonly toolbarId: string;
  
  /** Type of the toolbar, which corresponds to one of the predefined action types from ToolbarFactory */
  readonly toolbarType: typeof ToolbarFactory.ACTION[keyof typeof ToolbarFactory.ACTION];

  /** Title for the toolbar icon */
  protected toolbarIconTitle: string;

  /** Array of toolbar actions (each action is defined as an object with eventType and action string) */
  protected toolbarActions: ToolbarActionsType[] = [];

  /** Icon information for the toolbar's actions (name and type of the icon) */
  protected actionIcon: ActionIconType;
  
  protected entityData: EntityData<TSchema>;
  /**
   * Constructor for initializing toolbar data.
   * 
   * @param toolbarType - Defines the action type for the toolbar from ToolbarFactory
   * @param toolbarIconTitle - Optional title for the toolbar icon (default is an empty string)
   * @param toolbarActions - Array of actions associated with the toolbar (can be empty)
   */
  constructor(
    toolbarId: string,
    toolbarType: typeof ToolbarFactory.ACTION[keyof typeof ToolbarFactory.ACTION],
    toolbarIconTitle = "",
    toolbarActions: ToolbarActionsType[],
    actionIcon: ActionIconType,
    entityData: EntityData<TSchema>,
    builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES],
    dispatch: Dispatch
  ) {
    this.toolbarId = toolbarId || getWrappidUUID();
    this.toolbarType = toolbarType;
    this.toolbarIconTitle = toolbarIconTitle;
    this.toolbarActions = toolbarActions;
    this.actionIcon = actionIcon;
    this.entityData = entityData;
    this.builderType = builderType;
    this.dispatch = dispatch;
  }

  abstract onclickAction(entityData: EntityData<TSchema>, buildertype: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES], dispatch: Dispatch): void;

  performAction() {
    // Iterate over the actions of the toolbar and execute their corresponding onClick method.
    this.toolbarActions.forEach(action => {
      if (action.onClick) {
        // eslint-disable-next-line no-console
        console.log("action.onClick", action.onClick);
        
        this.onclickAction(this.entityData, this.builderType, this.dispatch);
      }
    });
  }
  
  /**
   * Getter method to retrieve the toolbar's action type.
   * @returns The type of the toolbar (e.g., "add", "edit").
   */
  getToolbarType(): string {
    return this.toolbarType;
  }

  /**
   * Getter method to retrieve the toolbar's icon title.
   * @returns The icon title of the toolbar.
   */
  getToolbarIconTitle(): string {
    return this.toolbarIconTitle;
  }

  /**
   * Getter method to retrieve the toolbar's actions.
   * @returns An array of actions associated with the toolbar.
   */
  getToolbarActions(): ToolbarActionsType[] {
    return this.toolbarActions;
  }

  /**
   * Getter method to retrieve the toolbar's action icon information.
   * @returns An object containing the icon name and type.
   */
  getActionIcon(): ActionIconType {
    return this.actionIcon;
  }
}