import { LOGOUT_SUCCESS } from "@wrappid/core";

import {
  CREATE_PATIENT_ERROR,
  CREATE_PATIENT_SUCCESS,
  EDIT_PATIENT_SUCCESS,
  EDIT_PATIENT_ERROR,
  GET_PATIENT_PAST_APPOINTMENT_ERROR,
  GET_PATIENT_PAST_APPOINTMENT_SUCCESS,
  GET_PATIENT_UPCOMING_APPOINTMENT_ERROR,
  GET_PATIENT_UPCOMING_APPOINTMENT_SUCCESS,
  GET_RELATIVE_USER_INFO_ERROR,
  GET_RELATIVE_USER_INFO_SUCCESS,
  HIDE_RELATIVE_FORM,
  RESET_PATIENT,
  SET_PATIENT_FOR_RELATIVE,
  SHOW_RELATIVE_FORM,
  GET_RELATIVE_SUCCESS,
  PATIENT_DATA_ENTRY
} from "../types/patient.types";

const initState = {
  createPatientError  : false,
  createPatientSuccess: false,
  currentPerson       : null,
  currentUser         : null,
  currentUserInfo     : null,
  data                : {},
  editPatientError    : false,
  editPatientSuccess  : false,
  pastAppointment     : {},
  relativeFormVisible : false,
  relativeInfo        : {},
  upcomingAppointment : {}
};

const patientReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        createPatientError  : false,
        createPatientSuccess: true
      };

    case CREATE_PATIENT_ERROR:
      return {
        ...state,
        createPatientError  : true,
        createPatientSuccess: false,
      };

    case EDIT_PATIENT_SUCCESS:
      return {
        ...state,
        editPatientError  : false,
        editPatientSuccess: true,
      };
    
    case EDIT_PATIENT_ERROR:
      return {
        ...state,
        editPatientError  : true,
        editPatientSuccess: false,
      };

    case RESET_PATIENT:
      return { initState };

    case GET_RELATIVE_SUCCESS: 
      return {
        ...state,
        relativeInfo: action.payload
      } ;
    
    case SHOW_RELATIVE_FORM:
      return {
        ...state,
        relativeFormVisible: true,
      };

    case HIDE_RELATIVE_FORM:
      return {
        ...state,
        relativeFormVisible: false,
      };

    case GET_PATIENT_UPCOMING_APPOINTMENT_SUCCESS:
      return {
        ...state,
        upcomingAppointment: action?.payload?.data,
      };

    case GET_PATIENT_UPCOMING_APPOINTMENT_ERROR:
      return {
        ...state,
        upcomingAppointment: null, 
      };

    case GET_PATIENT_PAST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        pastAppointment: action?.payload?.data,
      };

    case GET_PATIENT_PAST_APPOINTMENT_ERROR:
      return {
        ...state,
        pastAppointment: null,
      };

    case SET_PATIENT_FOR_RELATIVE:
      return {
        ...state,
        currentPerson: action.payload.id,
        currentUser  : action.payload.userId,
      };
      
    case GET_RELATIVE_USER_INFO_SUCCESS:
      return {
        ...state,
        currentUserInfo: action.payload?.data?.data,
      };

    case GET_RELATIVE_USER_INFO_ERROR:
      return {
        ...state,
        currentUserInfo: null,
      };

    case PATIENT_DATA_ENTRY:
      return {
        ...state,
        data: { ...state.data, [action.payload.parentID]: action.payload },
      };
      
    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};

export default patientReducer;
