import moment from "moment";

/**
   * Convert UTC time from backend to local time for display
   * 
   * @param val - string format 2024-11-19T14:00:00.000Z
   * @return local moment
   */
export function convertToLocale(val) {
  try {
    
    // expecting format 2024-11-19T14:00:00.000Z
    if (!val || !moment(val, moment.ISO_8601, true).isValid()) {
      return null;
    }
  
    return moment.utc(val).local().format("LT");

    /*  else if (typeof val === "string" && val.includes(":")) {
      // Handle time strings like "14:00:00" or "14:00"
      const timeParts = val.split(":");
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);
    
      // Create moment object in UTC with current date
      const utcTime = moment.utc().hours(hours).minutes(minutes);
    
      // Convert to local time
      return utcTime.local();
    } */
  } catch (error) {
    return null;
  }
}