import React, { useContext } from "react";

import {
  __IconTypes,
  CoreAvatar,
  CoreBox,
  CoreChip,
  CoreClasses,
  CoreGrid,
  CoreIconText,
  CoreLink,
  CoreRoutesContext,
  CoreStack,
  CoreTypographyCaption,
  CoreTypographySubtitle1,
  StatusText
} from "@wrappid/core";

export default function PrescriptionSummaryComponent(props) {
  const routesRegistry = useContext(CoreRoutesContext);
  let { rowData, data } = props;
    
  rowData = rowData || data || props || {};
  const {
    Patient = { firstName: "", lastName: "", middleName: "" },
    Clinic = { name: "" },
    Appointment
  } = rowData;

  const [editPrescriptionLink, setEditPrescriptionLink] = React.useState("#");
    
  React.useEffect(() => {
    if (Object.hasOwnProperty.call(routesRegistry, "prescription") && rowData?.id > 0) {
      setEditPrescriptionLink(routesRegistry.prescription?.url?.replace(":id", rowData?.id));
    }
  }, [routesRegistry, rowData?.id]);

  return (
    <>
      <CoreGrid>
        <CoreBox
          gridProps={{ gridSize: 2 }}
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.PADDING.PT1, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
        >
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR]}
            src={Patient?.photoUrl}
          />
        </CoreBox>
  
        <CoreBox gridProps={{ gridSize: 10 }}>
          <CoreTypographySubtitle1 styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>
            {[Patient["firstName"], Patient["middleName"], Patient["lastName"]].join(" ")}
          </CoreTypographySubtitle1>
  
          <CoreTypographyCaption>
              MRN{": "}
  
            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}
            >
              {Patient["profileId"] || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>
        </CoreBox>
  
        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.MARGIN.MT1, CoreClasses.MARGIN.MR2]}>
          {Appointment ?
            <>
              <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_CONTENT_CENTER]}>
                <CoreIconText
                  type={__IconTypes.MATERIAL_OUTLINED_ICON}
                  icon="event_available"
                  text={Appointment?.date}
                />
              </CoreBox>
        
              <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_CONTENT_CENTER]}>
                <CoreIconText
                  type={__IconTypes.MATERIAL_OUTLINED_ICON}
                  icon="query_builder"
                  text={`${Appointment?.startTime?.substring(0, 5)} - ${Appointment?.endTime?.substring(0, 5)}`}
                />
              </CoreBox>
            </>
            :
            <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>
                        No Appointment Available
            </CoreTypographyCaption>
          }
        </CoreBox>

        <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
          {Clinic ?
            <CoreChip
              avatar={
                <CoreAvatar
                  styleClasses={[CoreClasses.BORDER.BORDER_0]}
                  src={Clinic?.photoUrl}
                />
              }
              label={Clinic?.name}
            />
            :
            <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>
                            No Clinic Available 
            </CoreTypographyCaption>

          }
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]} gridProps={{ gridSize: { sm: 6 } }}>
          {rowData?.isCompleted && rowData?.docUrl ?
            <CoreLink href={rowData?.docUrl} >Download</CoreLink>
            :
            <CoreLink href={"/" + editPrescriptionLink} >Edit</CoreLink>
          }
        </CoreBox>
      </CoreGrid>
  
      <CoreStack
        direction="row"
        spacing={1}
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
      >
        <CoreTypographyCaption>{"ID: " + rowData?.id}</CoreTypographyCaption>
  
        <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>{"|"}</CoreTypographyCaption>
  
        <StatusText status={rowData?._status} />
  
      </CoreStack>
    </>
  );
}
