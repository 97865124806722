import { useContext, useEffect, useState } from "react";

import {
  AppContainerLayout,
  CORE_DIALOG_TYPES,
  CoreDialogContext,
  CoreLayoutItem,
  coreUseLocation,
  coreUseParams
} from "@wrappid/core";
import { WrappidDataContext } from "@wrappid/styles";
import { useDispatch, useSelector } from "react-redux";

import PrescriptionMain from "./PrescriptionMain";
import { clearPrescription, setPrescriptionReducer } from "../actions/prescription.action";
import { RESET_PRESCRIPTION_FLAGS } from "../types/prescription.types";

// eslint-disable-next-line etc/no-commented-out-code
// function getErrorText(missingData) {
//   let mData = {
//     email    : "email",
//     firstName: "first name",
//     gender   : "gender",
//     phone    : "phone number",
//     photoUrl : "profile picture",
//     regDate  : "registration date",
//     regNo    : "registration no."
//   };
//   let errorText = "Your profile is not complete, add the following details: ";
//   let mDataKeys = Object.keys(missingData);
//   for (let i = 0; i < mDataKeys.length; i++) {
//     errorText += mData[mDataKeys[i]] + ", ";
//   }
//   return errorText;
// }

export default function Prescription() {
  const dispatch = useDispatch();

  const location = coreUseLocation();
  // eslint-disable-next-line etc/no-commented-out-code
  // const navigate = coreUseNavigate();
  const params = coreUseParams();

  // eslint-disable-next-line etc/no-commented-out-code
  // const routeRegistry = useContext(CoreRoutesContext);
  const dialogContext = useContext(CoreDialogContext);
  const { config: appConfig } = useContext(WrappidDataContext);
  
  const {
    prescription,
    downloadPrescriptionError,
    downloadPrescriptionSuccess,
    downloadPrescriptionMsg
  } = useSelector(
    (state) => state.prescription
  );
  
  // eslint-disable-next-line etc/no-commented-out-code
  // const { quotient, missingData } = useSelector(state=>state.profile?.completeness?.report);
  
  const [idInUrl, setIdInUrl] = useState(null);

  /**
   * @todo review required
   * 
   * This will help us to block create prescription
   */
  const openProfileNotCompleteDialog = () => {
    // - if (quotient && quotient < 100) {
    //   dialogContext?.setDialog({
    //     doneButton: () => {
    //       navigate(routeRegistry?.profile);
    //     },
    //     doneButtonLabel: "Okay",
    //     noCancelButton : true,
    //     showDialog     : true,
    //     subtitle       : getErrorText(missingData),
    //     title          : "You profile is not complete!",
    //     type           : "info"
    //   });
    // }
  };

  useEffect(() => {

    /**
     * This will help us to block create prescription
     */
    openProfileNotCompleteDialog();

    /**
     * This dialog will open if a prescription in edit mode
     */
    if (prescription?.id && dialogContext?.setDialog) {
      dialogContext.setDialog({
        cancelButton: () => {
          // eslint-disable-next-line no-console
          console.log("This is continue button");
          dispatch({ type: RESET_PRESCRIPTION_FLAGS });
        },
        cancelButtonLabel: "Continue",
        doneButton       : () => {
          // eslint-disable-next-line no-console
          console.log("This is clear button");
          dispatch(clearPrescription(prescription?.id));
        },
        doneButtonLabel: "Clear",
        showDialog     : true,
        subtitle       : "Would you like you continue or start fresh",
        title          : "You already have a prescription !",
        type           : "info"
      });
    }

    /**
     * This will help us to get the prescription id from url
     */
    if(params?.id){
      setIdInUrl(params?.id);
    }

    /**
     * This will help us to clear prescription reducer
     */
    if (
      !location?.state?.fromAppointment
      && !location?.state?.fromPatient
      && !prescription?.id
      && !idInUrl
    ) {
      dispatch(setPrescriptionReducer({ clinic: null, navData: null }));
    }
  }, []);

  useEffect(()=>{
    if(downloadPrescriptionSuccess){
      dialogContext?.setDialog({
        showDialog: true,
        subtitle  : downloadPrescriptionMsg || "Prescription downloaded successfully!",
        title     : "Success",
        type      : CORE_DIALOG_TYPES?.SUCCESS
      });
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
    }
    if(downloadPrescriptionError){
      dialogContext?.setDialog({
        showDialog: true,
        subtitle  : downloadPrescriptionMsg || "Prescription downloaded error!",
        title     : "Error",
        type      : CORE_DIALOG_TYPES?.FAILURE
      });
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
    }
  }, [downloadPrescriptionError, downloadPrescriptionSuccess]);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <PrescriptionMain idInUrl={idInUrl} appConfig={appConfig} />
      </CoreLayoutItem>
    </>
  );
}
