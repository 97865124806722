/* eslint-disable etc/no-commented-out-code */
import { PropertyBuilder } from "./Property.builder";
import { PropertyTypeBuilder } from "./PropertyType.builder";
import { Property } from "../toolbox/property/Property.interface";

export class PropertyManager {
  private properties: Property[];

  constructor() {
    this.properties = this.initializeDefaultProperties();
  }

  private initializeDefaultProperties(): Property[] {
    const typeBuilder = new PropertyTypeBuilder();
    const propertyBuilder = new PropertyBuilder();

    const properties: Property[] = [];

    // Name property
    properties.push(
      propertyBuilder
        .setName("name")
        .setDescription("")
        .setTypes([
          typeBuilder
            .setType("string")
            .setDefault("")
            .setValidValues([])
            .build()
        ])
        .build()
    );

    // Type property
    properties.push(
      propertyBuilder
        .setName("type")
        .setDescription("")
        .setTypes([
          typeBuilder
            .setType("string")
            .setDefault("STRING")
            .setValidValues([
              "STRING",
              "INTEGER",
              "FLOAT",
              "BOOLEAN",
              "DATE",
              "DATEONLY",
              "TIME",
              "BLOB",
              "TEXT",
              "BIGINT",
              "UUID",
              "ENUM",
              "JSON",
              "JSONB",
              "ARRAY",
              "DOUBLE",
              "DECIMAL",
              "CHAR",
              "CITEXT",
              "RANGE",
              "GEOMETRY",
              "GEOGRAPHY",
              "VIRTUAL",
              "HSTORE",
              "SMALLINT",
              "MEDIUMINT",
              "TINYINT"
            ])
            .build()
        ])
        .build()
    );

    // allowNull property
    properties.push(
      propertyBuilder
        .setName("allowNull")
        .setDescription("Defines whether the column can accept NULL values.")
        .setTypes([
          typeBuilder
            .setType("boolean")
            .setDefault("true")
            .setValidValues(["true", "false"])
            .build()
        ])
        .build()
    );

    // "defaultValue" property
    properties.push(
      propertyBuilder
        .setName("defaultValue")
        .setDescription("Sets a default value for the column if none is provided.")
        .setTypes([
          typeBuilder
            .setType("any")
            .setDefault("null")
            .setValidValues(["any value"])
            .build()
        ])
        .build()
    );

    // "primaryKey" property
    properties.push(
      propertyBuilder
        .setName("primaryKey")
        .setDescription("Marks the column as the primary key.")
        .setTypes([
          typeBuilder
            .setType("boolean")
            .setDefault("false")
            .setValidValues(["true", "false"])
            .build()
        ])
        .build()
    );

    // "autoIncrement" property
    properties.push(
      propertyBuilder
        .setName("autoIncrement")
        .setDescription("Indicates that the column should auto-increment.")
        .setTypes([
          typeBuilder
            .setType("boolean")
            .setDefault("false")
            .setValidValues(["true", "false"])
            .build()
        ])
        .build()
    );

    // "unique" property
    properties.push(
      propertyBuilder
        .setName("unique")
        .setDescription("Ensures the column has unique values.")
        .setTypes([
          typeBuilder
            .setType("boolean")
            .setDefault("false")
            .setValidValues(["true", "false"])
            .build()
        ])
        .build()
    );

    // "validate" property
    properties.push(
      propertyBuilder
        .setName("validate")
        .setDescription("Defines validation rules for the column.")
        .setTypes([
          typeBuilder
            .setType("object")
            .setDefault(null)
            .setValidValues(null)
            .build()
        ])
        .build()
    );

    // "references" property
    properties.push(
      propertyBuilder
        .setName("references")
        .setDescription("Defines the foreign key relationship.")
        .setTypes([
          typeBuilder
            .setType("object")
            .setDefault(null)
            .setValidValues(null)
            .build()
        ])
        .build()
    );

    // "onDelete" property
    properties.push(
      propertyBuilder
        .setName("onDelete")
        .setDescription("Defines behavior when the referenced record is deleted.")
        .setTypes([
          typeBuilder
            .setType("string")
            .setDefault("NO ACTION")
            .setValidValues(["CASCADE"])
            .build()
        ])
        .build()
    );

    // "onUpdate" property
    properties.push(
      propertyBuilder
        .setName("onUpdate")
        .setDescription("Defines behavior when the referenced record is updated.")
        .setTypes([
          typeBuilder
            .setType("string")
            .setDefault("NO ACTION")
            .setValidValues(["CASCADE"])
            .build()
        ])
        .build()
    );

    // "comment" property
    properties.push(
      propertyBuilder
        .setName("comment")
        .setDescription("Adds a comment to the column.")
        .setTypes([
          typeBuilder
            .setType("string")
            .setDefault(null)
            .setValidValues(null)
            .build()
        ])
        .build()
    );

    // Add remaining properties following the same pattern...
    // (Truncated for brevity but would include all properties from original)

    return properties;
  }

  getValidProperties(): Property[] {
    return this.properties;
  }

  addProperty(property: Property): void {
    this.properties.push(property);
  }

  removeProperty(propertyName: string): void {
    this.properties = this.properties.filter(prop => prop.name !== propertyName);
  }

  updateProperty(propertyName: string, updatedProperty: Property): void {
    const index = this.properties.findIndex(prop => prop.name === propertyName);

    if (index !== -1) {
      this.properties[index] = updatedProperty;
    }
  }
}