import { useContext } from "react";

import {
  CoreBox, CoreClasses, CoreDialogContext, CoreGrid, CoreIcon, CoreIconButton, CoreSelect, CoreTypographyBody1 
} from "@wrappid/core";
import { useSelector, useDispatch } from "react-redux";

import ComponentSelector from "./ComponentSelector";
import EventsSelector from "./EventsSelector";
import LayoutSelector from "./LayoutSelector";
import NavigatorView from "./NavigatorView";
import PropsSelector from "./PropsSelector";
import { reorderToolbox, toggleToolboxOpen, toolboxCollapseExpand } from "../../../../actions/app.action";
import { AppBuilderMaker } from "../AppBuilderMaker";

const appBuilderMaker = new AppBuilderMaker();

export default function RightDrawerComp() {
  const dispatch = useDispatch();
  const { setDialog } = useContext(CoreDialogContext);
  
  const toolboxExpand = useSelector((state) => state.appBuilderReducer?.isExpandToolBox);
  const toolboxesState = useSelector((state) => 
    state.appBuilderReducer?.toolboxes || {
      1: { isOpenToolBox: true, order: 0 },
      2: { isOpenToolBox: true, order: 1 },
      3: { isOpenToolBox: true, order: 2 },
      4: { isOpenToolBox: true, order: 3 },
      5: { isOpenToolBox: true, order: 4 },
      6: { isOpenToolBox: true, order: 5 },
      7: { isOpenToolBox: true, order: 6 }
    }
  );

  // Initialize the utility with required dependencies
  appBuilderMaker.initialize(dispatch, setDialog, toolboxExpand, toolboxesState);
  appBuilderMaker.setActions({
    reorderToolbox,
    toggleToolboxOpen,
    toolboxCollapseExpand
  });

  // Define your toolboxes configuration
  const config = {
    toolboxes: [
      {
        content  : <LayoutSelector />,
        id       : 1,
        resize   : "horizontal",
        toolTitle: "Select Layout",
      },
      {
        content  : <ComponentSelector />,
        id       : 2,
        resize   : "both",
        toolTitle: "Select any Component",
      },
      {
        content  : <PropsSelector />,
        id       : 3,
        resize   : "both",
        toolTitle: "Select Props for Component",
      },
      {
        content  : <CoreTypographyBody1>Theme</CoreTypographyBody1>,
        id       : 4,
        resize   : "both",
        toolTitle: "Select Theme",
      },
      {
        content  : <EventsSelector />,
        id       : 5,
        resize   : "both",
        toolTitle: "Select Event",
      },
      {
        content: (
          <CoreGrid>
            <CoreSelect
              gridProps={{ gridSize: { md: 12 } }}
              label="Device"
              selectID="currentDevice"
              options={[{ id: "", label: "Laptop" }, { id: "10", label: "Tab" }, { id: "20", label: "Android" }, { id: "30", label: "iPhone" }]}
            />

            <CoreSelect
              gridProps={{ gridSize: { md: 8, xs: 4 } }}
              label="Zoom"
              selectID="currentZoom"
              options={[{ id: "", label: "25%" }, { id: "10", label: "50%" }, { id: "20", label: "75%" }, { id: "30", label: "100%" }]}
            />

            <CoreBox
              gridProps={{
                gridSize    : { md: 4, xs: 4 },
                styleClasses: [CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]
              }}
            >
              <CoreIconButton>
                <CoreIcon>screen_rotation</CoreIcon>
              </CoreIconButton>

              <CoreIconButton title="Device posture">
                <CoreIcon>devices_fold</CoreIcon>
              </CoreIconButton>
            </CoreBox>
          </CoreGrid>
        ),
        id       : 6,
        resize   : "both",
        toolTitle: "Select Device",
      },
      {
        content  : <NavigatorView />,
        id       : 7,
        resize   : "both",
        toolTitle: "Navigator",
      }
    ]
  };

  // Render the drawer using the utility
  return appBuilderMaker.renderRightDrawer(config);
}