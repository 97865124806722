/* eslint-disable etc/no-commented-out-code */
import React, { useEffect, useState } from "react";

import { CoreBox, CoreClasses, CoreTabs, CoreTab, CoreTypographyBody1 } from "@wrappid/core";
import { useSelector } from "react-redux";

import Editor from "./Editor";
import EditorFactory from "../../factory/Editor.factory";
import { EditorData } from "../data/editor/Editor.data";
import { JsonEditorData } from "../data/editor/json/JsonEditor.data";

function CoreTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <CoreBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <CoreBox>{children}</CoreBox>}
    </CoreBox>
  );
}

function a11yProps(index) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
    id             : `simple-tab-${index}`,
  };
}

type EditorGroupType<TSchema> = { viewerData: EditorData<TSchema>[] };

export default function EditorGroup<TSchema>(props: EditorGroupType<TSchema>) {
  const { viewerData } = props;

  // const builderJson = GetDataFromReducer(getBuilderData()?.getId());
  const { appBuilderData } = useSelector((state: any) => state.BuilderOptions);
  const selectedBuilder = appBuilderData?.find((builderData) => {
    return builderData.selected === true;
  });
  const [enhancedEditorData, setEnhancedEditorData] = useState<EditorData<TSchema>[]>([]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let editorId;

    if (selectedBuilder){
      selectedBuilder.editors.map((eachEditor) => {
        Object.setPrototypeOf(eachEditor, EditorData.prototype);
        if(eachEditor.getEditorType() === EditorFactory.VARIANTS.JSON_EDITOR){
          editorId = eachEditor.getEditorId();
        }
      });
      const newEditorData: EditorData<TSchema> = new JsonEditorData(editorId, undefined, selectedBuilder?.entityData);

      newEditorData.setEditorContent(selectedBuilder);
      newEditorData.setEditorType(EditorFactory.VARIANTS.BUILDER_JSON);
      const updatedEditorData: EditorData<TSchema>[] = Array.isArray(viewerData)
        ? [...viewerData, newEditorData]
        : [];

      setEnhancedEditorData(updatedEditorData);
    }
    
  }, [viewerData, selectedBuilder]);

  return (
    <CoreBox>
      <CoreBox styleClasses={[CoreClasses.WIDTH.W_100, CoreClasses.TEXT.LINEHEIGHT_NORMAL]}>
        <CoreBox styleClasses={[CoreClasses.POSITION.POSITION_STICKY, CoreClasses.Z_INDEX.Z_3, CoreClasses.BG.BG_WHITE]}>
          <CoreTabs styleClasses={[CoreClasses.DISPLAY.FLEX]} value={value} onChange={handleChange} aria-label="basic tabs example">
            {enhancedEditorData && enhancedEditorData.length > 0 ? (
              enhancedEditorData.map((eachEditorData, index) => {
                Object.setPrototypeOf(eachEditorData, EditorData.prototype);
                return (
                  <CoreTab
                    key={`tab-${index}`}
                    label={eachEditorData.getEditorType()}
                    {...a11yProps(index)}
                    styleClasses={[CoreClasses.PADDING.P0, CoreClasses.DISPLAY.INLINE_BLOCK, CoreClasses.HEIGHT.MAX_H_25, CoreClasses.TEXT.LINEHEIGHT_NORMAL]}
                  />
                );
              })
            ) : (
              <CoreTypographyBody1>
                No viewer available to display.
              </CoreTypographyBody1>
            )}
          </CoreTabs>

        </CoreBox>

        {enhancedEditorData && enhancedEditorData.length > 0 ? (
          enhancedEditorData.map((eachEditorData, index) => {
            return (
              <CoreTabPanel key={`panel-${index}`} value={value} index={index}>
                <Editor viewerData={eachEditorData} />
              </CoreTabPanel>
            );
          })
        ) : (
          <CoreTypographyBody1>
            No viewer content available.
          </CoreTypographyBody1>
        )}
      </CoreBox>
    </CoreBox>
  );
}

