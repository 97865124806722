import {
  CoreAccordion,
  CoreAccordionSummary,
  CoreAccordionDetail,
  CoreTypographyBody1,
  CoreLink,
  CoreClasses,
  CoreBox
} from "@wrappid/core";
  
// eslint-disable-next-line import/no-unresolved
import wrappidMeta from "../wrappid.meta.json";
  
export default function RenderAppModuleDetails() {
  // Iterate over modules and render each one
  return (
    <>
      {Object.keys(wrappidMeta.modules).map((moduleName) => {
        const module = wrappidMeta.modules[moduleName];
        const { version, repoOwner, repoURL, dependencies, devDependencies } = module;
  
        return (
          <CoreAccordion key={moduleName} styleClasses={[CoreClasses.PADDING.P1]}>
            <CoreAccordionSummary styleClasses={[CoreClasses.PADDING.P1]}>
              <CoreTypographyBody1>{`${moduleName} - ${version ? version : "Version not available"}`}</CoreTypographyBody1>
            </CoreAccordionSummary>

            <CoreAccordionSummary styleClasses={[CoreClasses.PADDING.P1]}>
              <CoreTypographyBody1> {`Author: ${repoOwner}`}</CoreTypographyBody1>
            </CoreAccordionSummary>
  
            <CoreAccordionDetail styleClasses={[CoreClasses.PADDING.P1]}>
              <CoreTypographyBody1>{`Name: ${moduleName}`}</CoreTypographyBody1>
  
              <CoreTypographyBody1>{`Version: ${version ? version : "Version not available"}`}</CoreTypographyBody1>
  
              <CoreTypographyBody1>Repository:
                <CoreLink href={repoURL ? repoURL : "#"}>{repoURL ? repoURL : "Repository not available"}</CoreLink>
              </CoreTypographyBody1>
  
              <CoreBox>
                <CoreTypographyBody1>Dependencies:</CoreTypographyBody1>

                {dependencies && Object.keys(dependencies).length > 0 ? (
                  <CoreBox styleClasses={[CoreClasses.PADDING.P2]}>
                    {Object.keys(dependencies).map(dep => (
                      <CoreTypographyBody1 key={dep}>{`${dep}: ${dependencies[dep]}`}</CoreTypographyBody1>
                    ))}
                  </CoreBox>
                ) : (
                  <CoreTypographyBody1>No dependencies</CoreTypographyBody1>
                )}
              </CoreBox>
  
              <CoreBox>
                <CoreTypographyBody1>Dev Dependencies:</CoreTypographyBody1>

                {devDependencies && Object.keys(devDependencies).length > 0 ? (
                  <CoreBox styleClasses={[CoreClasses.PADDING.P2]}>
                    {Object.keys(devDependencies).map(devDep => (
                      <CoreTypographyBody1 key={devDep}>{`${devDep}: ${devDependencies[devDep]}`}</CoreTypographyBody1>
                    ))}
                  </CoreBox>
                ) : (
                  <CoreTypographyBody1>No dev dependencies</CoreTypographyBody1>
                )}
              </CoreBox>
            </CoreAccordionDetail>
          </CoreAccordion>
        );
      })}
    </>
  );
}
  