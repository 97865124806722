import { AppService, FORM_IDS, HTTP, getFullName } from "@wrappid/core";
import moment from "moment";

import { config } from "../config/config";

const numbersInWords = [
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
];

function getPrescriptionFieldArray(data, field) {
  if (data && data.length > 0) {
    if (data[0][field]?.includes("|")) {
      return data[0][field].split("|").map(tmpLabel => {
        return { label: tmpLabel };
      });
    } else return [{ label: data[0][field] }];
  } else {
    return [];
  }
}

export function getPrescriptionInitData(data, formId) {
  switch (formId) {
    case FORM_IDS.__SEARCH_CLINIC:
      // eslint-disable-next-line no-console
      console.log("PRESCRIPTION INIT DATA ", data?.prescriptionClinic);
      return data?.prescriptionClinic;

    case FORM_IDS.__PRESCRIPTION_DOCTOR_PROFILE:
      return {
        email: data?.profile?.contact?.email
          ? data?.profile?.contact?.email
          : "",
        name : data?.profile?.name || "Unnamed",
        phone: data?.profile?.contact?.phone
          ? data?.profile?.contact?.phone
          : "",
        regNo: data?.profile?.registration
          ? data?.profile?.registration?.regNo
          : "",
      };

    case FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE:
      return {
        dob: data?.prescriptionNavData?.Person?.dob
          ? moment().diff(moment(data.prescriptionNavData.Person.dob), "years")
          : "",
        gender   : data?.prescriptionNavData?.Person?.gender,
        name     : getFullName(data?.prescriptionNavData?.Person),
        profileId: data?.prescriptionNavData?.Person?.profileId,
      };

    case FORM_IDS.__PRESCRIPTION_CLINIC:
      return {
        addLine1  : data?.prescriptionClinic?.addLine1,
        addLine2  : data?.prescriptionClinic?.addLine2,
        city      : data?.prescriptionClinic?.city,
        clinicLogo: data?.prescriptionClinic?.Clinic?.photoUrl,
        country   : data?.prescriptionClinic?.country,
        district  : data?.prescriptionClinic?.district,
        fullName  : data?.prescriptionClinic?.fullName,
        phone     : data?.prescriptionClinic?.phone,
        pin       : data?.prescriptionClinic?.pin,
        state     : data?.prescriptionClinic?.state,
      };

    case FORM_IDS.__PRESCRIPTION_COMPLAINTS:
      return {
        complaint: getPrescriptionFieldArray(
          data?.prescription?.Complaints,
          "complaint"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_HISTORIES:
      return {
        history: getPrescriptionFieldArray(
          data?.prescription?.Histories,
          "history"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_REFFERS:
      return {
        reffer: getPrescriptionFieldArray(
          data?.prescription?.Refferes,
          "reffer"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_GUIDELINES:
      return {
        guideline: getPrescriptionFieldArray(
          data?.prescription?.Guidelines,
          "guideline"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_FOLLOWUPS:
      return {
        followup: getPrescriptionFieldArray(
          data?.prescription?.Followups,
          "followup"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_PROCEDURES:
      return {
        procedure: getPrescriptionFieldArray(
          data?.prescription?.Procedures,
          "procedure"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_DIAGNOSIS:
      return {
        diagnosis: getPrescriptionFieldArray(
          data?.prescription?.Diagnoses,
          "diagnosis"
        ),
      };

    case FORM_IDS.__PRESCRIPTION_VITALS:
      // eslint-disable-next-line no-case-declarations
      let vitals = data?.prescription?.PrescriptionVitals;

      // eslint-disable-next-line no-case-declarations
      let obj = {};

      // eslint-disable-next-line no-console
      console.log("-------------------prescriptionVitals", vitals);
      if (vitals && vitals.length > 0)
        for (let i = 0; i < vitals.length; i++) {
          obj[vitals[i].masterDataId] = vitals[i].value;
        }
      return obj;

    case FORM_IDS.__PRESCRIPTION_MEDICINE:
      // eslint-disable-next-line no-case-declarations
      let finalData = [];

      for (let i = 0; i < data?.prescription?.AdvicedMedicines?.length; i++) {
        let tempMed = data?.prescription?.AdvicedMedicines[i];

        let advMed = {};

        advMed["formulation"] = {
          label: tempMed?.formulation,
          value: tempMed?.formulation,
        };
        advMed["name"] = {
          label: tempMed?.Medicine?.name,
          value: tempMed?.Medicine.id,
        };
        advMed["quantity"] = {
          label: tempMed?.quantity,
          value: tempMed?.quantity,
        };
        advMed["frequency"] = {
          label: tempMed?.frequency,
          value: tempMed?.frequency,
        };
        advMed["meal"] = {
          label: tempMed?.meal,
          value: tempMed?.meal,
        };
        advMed["durationCount"] = {
          label: tempMed?.durationCount,
          value: tempMed?.durationCount,
        };
        advMed["durationType"] = {
          label: tempMed?.durationType,
          value: tempMed?.durationType,
        };

        finalData.push(advMed);
      }

      // eslint-disable-next-line no-console
      console.log("----------prescriptionMedicine", finalData);
      return finalData;

    case "prescriptionPreview":
      return { isCompleted: true };

    default:
      return {};
  }
}

export function VitalsFormation(
  template,
  mainMasterData,
  prescriptionTemplates
) {
  if (template) {
    let prescriptionMaster = mainMasterData.find(
      tmp => tmp.name === "prescription"
    );

    let vitalDesign = prescriptionTemplates
      ? prescriptionMaster?.Children?.find(
        tmp => tmp.name === "prescriptionVitals"
      ).Children?.filter(tmp =>
        template.PrescriptionTemplatesMasters?.find(
          master => master?.masterId === tmp?.id
        )
      )
      : mainMasterData[0].Children?.find(
        tmp => tmp.name === "prescriptionVitals"
      ).Children;

    let vitals = createDesign(vitalDesign, "tree", {}, null, []);

    return vitals;
  } else {
    return {};
  }
}

export function DesignFormation(
  templateDesign,
  mainMasterData,
  template,
  groups
) {
  if (templateDesign) {
    let finalData = [];

    for (let i = 0; i < templateDesign.length; i++) {
      let temp = templateDesign[i];

      if (!checkInPrescriptionTemplate(mainMasterData, template, temp.name)) {
        continue;
      }
      if (groups[temp.name]) {
        let index = finalData.findIndex(
          tmp => tmp.group === groups[temp.name]?.id
        );

        if (index >= 0) {
          finalData[index].data.push(temp);
        } else {
          finalData.push({
            data     : [temp],
            gridProps: groups[temp.name].gridProps,
            group    : groups[temp.name].id,
            groupFlag: true,
          });
        }
      } else {
        finalData.push(temp);
      }
    }
    return finalData;
  } else {
    return templateDesign;
  }
}

export function checkStatus(id, template, mainMaster, allTemplates) {
  let obj = {
    disabled: false,
    enabled : false,
  };

  // eslint-disable-next-line no-console
  // console.log("%%%here", id, template, mainMaster, allTemplates);

  let custom = allTemplates?.find(tmp => tmp?.name?.toLowerCase() === "custom");

  if (!custom) {
    obj.enabled = true;
    obj.disabled = true;
  } else {
    let specificMaster = mainMaster?.find(master => master?.name === id);

    let ptm = template?.PrescriptionTemplatesMasters?.find(
      ptm => ptm.masterId === specificMaster?.id
    );

    if (ptm) {
      obj.enabled = false;
      obj.disabled = true;
    } else {
      obj.enabled = true;
      obj.disabled = false;
    }
  }
  return obj;
}

export function searchMasterTree(elem, fullList, level) {
  for (let i = 0; i < fullList.length; i++) {
    let eachItem = fullList[i];

    if (eachItem.id === elem.id) {
      return fullList;
    } else if (eachItem.Children && eachItem.Children.length > 0) {
      let levelSearch = searchMasterTree(elem, eachItem.Children, level + 1);

      if (levelSearch) {
        return levelSearch;
      } else {
        continue;
      }
    }
  }

  return null;
}

export async function medStringOptions(inputVal, prescriptionString) {
  if (!inputVal) {
    inputVal = "";
  }
  let numbers = [];
  let allNums = [];
  let nVal = [];
  let words = [];

  for (let i = 0; i < 20; i++) {
    numbers?.push({ label: i + 1, value: i + 1 });
  }
  switch (prescriptionString.length) {
    case 0:
      // eslint-disable-next-line no-case-declarations
      let formulation = config.prescriptionData.formulation;

      return formulation?.filter(tmp =>
        tmp.label?.toLocaleLowerCase()?.includes(inputVal?.toLocaleLowerCase())
      );

    case 1:
      try {
        let medRes = await AppService.apiRequest(HTTP.GET, "/medicines", true, { input: inputVal || "a" });

        if (medRes.status === 200) {
          let medData = medRes.data;

          return medData.data.map(med => {
            return {
              data : med,
              label: med.name,
              value: med.id,
            };
          });
        } else {
          return [];
        }
      } catch (err) {
        return [];
      }

    case 2:
      allNums = numbers?.map(num => {
        return { label: String(num.label), value: String(num.value) };
      });
      words = numbersInWords?.map(num => {
        return { label: String(num), value: String(num) };
      });
      nVal = [...allNums, ...words]?.filter(tmp =>
        tmp.label?.toLocaleLowerCase()?.includes(inputVal?.toLocaleLowerCase())
      );
      if (nVal?.length > 0) {
        return nVal;
      } else {
        return [{ label: inputVal, value: inputVal }];
      }

    case 3:
      // eslint-disable-next-line no-case-declarations
      let frequency = config.prescriptionData.frequency;

      return frequency?.filter(tmp =>
        tmp.label?.toLocaleLowerCase()?.includes(inputVal?.toLocaleLowerCase())
      );

    case 4:
      // eslint-disable-next-line no-case-declarations
      let meal = config.prescriptionData.meal;

      return meal?.filter(tmp =>
        tmp.label?.toLocaleLowerCase()?.includes(inputVal?.toLocaleLowerCase())
      );

    case 5:
      allNums = numbers?.map(num => {
        return { label: String(num.label), value: num.value + "." };
      });
      words = numbersInWords?.map(num => {
        return { label: String(num), value: num + "." };
      });
      nVal = [...allNums, ...words]?.filter(tmp =>
        tmp.label?.toLocaleLowerCase()?.includes(inputVal?.toLocaleLowerCase())
      );
      if (nVal?.length > 0) {
        return nVal;
      } else {
        return [{ label: inputVal, value: inputVal + "." }];
      }

    case 6:
      // eslint-disable-next-line no-case-declarations
      let durationType = config.prescriptionData.durationType;

      return durationType?.filter(tmp =>
        tmp.label?.toLocaleLowerCase()?.includes(inputVal?.toLocaleLowerCase())
      );

    default:
      return null;
  }
}

//forms: It is the array of formids submitted,
//formSubmitSuccess: formSubmitSuccess object for all forms individual formsubmit
//is accessed by formSubmitSuccess[formId]?.success
export function CheckAllFormSubmitSucces(forms, formSubmitSuccess) {
  if (!forms) {
    return false;
  }
  for (let i = 0; i < forms.length; i++) {
    let formId = forms[i];

    if (!formSubmitSuccess[formId]?.success) {
      return false;
    }
  }

  return true;
}

/**
 * Vital design related functions
 */

function createTreeView(data, values, changeHandler, finalData, nlFlag) {
  if (!data || !data || data.length === 0) {
    return [];
  }
  let currentLevelData = data;

  let flag = false;

  for (let i = 0; i < currentLevelData.length; i++) {
    //current data
    let currentData = currentLevelData[i];

    let elem = {};

    let newLineFlag = false;

    if (currentData.Children && currentData.Children.length > 0) {
      newLineFlag = true;
    }

    elem = {
      dependencies: currentData?.extraInfo?.dependencies,
      extraInfo   : currentData.extraInfo,
      id          : currentData.id,
      input       : currentData.isInput,
      isDependent : currentData?.extraInfo?.isDependent,
      label       : currentData.label,
      name        : currentData.name,
      newLine     : newLineFlag,
      type        : currentData.inputType,
    };

    if (!elem.input) {
      elem["gridSize"] = 12;
    }

    let nextLevelData = currentLevelData[i].Children;

    finalData.push(elem);
    if (elem.newLine) {
      flag = true;
    }
    createTreeView(nextLevelData, values, changeHandler, finalData, flag);
  }

  if (nlFlag) {
    finalData.push({
      gridSize: 12,
      type    : "spacer",
    });
  }

  return finalData;
}

export function createDesign(data, view, values, changeHandler, finalData) {
  switch (view) {
    case "tree":
      return createTreeView(data, values, changeHandler, finalData);

    default:
      return "";
  }
}

export function checkInPrescriptionTemplate(allMaster, template, elementName) {
  let elems = template?.PrescriptionTemplatesMasters;

  let mId = allMaster
    ?.find(tmp => tmp.name === "prescription")
    ?.Children?.find(tmp => tmp.name === elementName)?.id;

  if (elems?.find(tmp => tmp.masterId === mId)) return true;
  else return false;
}

export function getClearValues(values) {
  let newValues = { ...values };
  let keys = [];
  let key = [];

  if (values && Object.keys(values)?.length > 0) {
    keys = Object.keys(values);
    for (let i = 0; i < keys?.length; i++) {
      key = keys[i];
      if (values[key] && Array.isArray(values[key])) {
        newValues[key] = [];
      } else {
        newValues[key] = "";
      }
    }
  }

  return newValues;
}

export function getMedicineFormFromText(
  data,
  OnAddMed,
  advMedicines,
  editMed,
  isGeneric
) {
  return {
    formJson: {
      actions: [
        {
          label  : "Save",
          onClick: {
            functionName: values => {
              let medOb = {
                durationCount: {
                  label: values?.durationCount,
                  value: values?.durationCount,
                },
                durationType: {
                  label: values?.durationType,
                  value: values?.durationType,
                },
                formulation: {
                  label: values?.formulation,
                  value: values?.formulation,
                },
                frequency: {
                  label: values?.frequency,
                  value: values?.frequency,
                },
                id: editMed
                  ? editMed
                  : "temp#" + Number(advMedicines.length + 1),
                isGeneric: isGeneric,
                meal     : {
                  label: values?.meal,
                  value: values?.meal,
                },
                name: {
                  label: values?.name?.name || values?.name,
                  value: values?.name?.id || values?.name,
                },
                quantity: {
                  label: values?.quantity || "",
                  value: values?.quantity || "",
                },
              };

              let meds = [...(advMedicines || [])];

              meds.push(medOb);

              OnAddMed(meds);
            },
          },
          type: "coreOutlinedButton",
        },
      ],
      fields: [
        {
          asyncLoading   : false,
          creatable      : true,
          endpoint       : "/masterData",
          id             : "formulation",
          itemKey        : "formulation",
          label          : "Formulation",
          mountValueMatch: true,
          query          : { level: 2, name: "formulation" },
          speechToText   : { active: true },
          type           : "asyncSelect",
        },
        {
          asyncLoading  : false,
          creatable     : true,
          endpoint      : "/medicines?input=" + data?.medicine,
          getOptionLabel: medicineAsyncSelectFunctions?.getOptionLabel,
          getOptionValue: medicineAsyncSelectFunctions?.getOptionValue,
          id            : "name",
          isOptionsEqualToValue:
            medicineAsyncSelectFunctions?.isOptionsEqualToValue,
          itemKey        : "medicines",
          label          : "Medicine",
          mountValueMatch: true,
          speechToText   : { active: true },
          type           : "asyncSelect",
        },
        {
          id          : "quantity",
          label       : "Quantity",
          speechToText: { active: true },
          type        : "text",
        },
        {
          asyncLoading   : false,
          creatable      : true,
          endpoint       : "/masterData",
          id             : "frequency",
          itemKey        : "frequency",
          label          : "Frequency",
          mountValueMatch: true,
          query          : { level: 2, name: "frequency" },
          speechToText   : { active: true },
          type           : "asyncSelect",
        },
        {
          asyncLoading   : false,
          creatable      : true,
          endpoint       : "/masterData",
          id             : "meal",
          itemKey        : "meal",
          label          : "Meal",
          mountValueMatch: true,
          query          : { level: 2, name: "meal" },
          speechToText   : { active: true },
          type           : "asyncSelect",
        },
        {
          id          : "durationCount",
          label       : "Duration",
          speechToText: { active: true },
          type        : "text",
        },
        {
          asyncLoading   : false,
          creatable      : true,
          endpoint       : "/masterData",
          id             : "durationType",
          itemKey        : "durationType",
          label          : "Duration Type",
          mountValueMatch: true,
          query          : { level: 2, name: "durationType" },
          speechToText   : { active: true },
          type           : "asyncSelect",
        },
      ],
    },
    initData: {
      durationCount: data?.duration,
      durationType : data?.durationType,
      formulation  : data?.formulation,
      frequency    : data?.no_of_time,
      meal         : data?.meal,
      name         : data?.medicine,
      quantity     : data?.quantity,
    },
  };
}

const medicineAsyncSelectFunctions = {
  getOptionLabel: data => {
    return data.name;
  },
  getOptionValue: data => {
    return data;
  },
  isOptionsEqualToValue: (option, value) => {
    return option.id === value;
  },
};
