import {
  CoreBox,
  CoreClasses,
  CoreTypographyBody1,
  CoreTypographyBody2,
  CoreTypographySubtitle1
} from "@wrappid/core";

export default function PrescriptionDoctorProfileCard(props) {
  const { name, regNo, phone, email } = props;

  return (
    <CoreBox>
      <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.MB0]}>
        {name}
      </CoreTypographyBody2>

      <CoreTypographySubtitle1 styleClasses={[CoreClasses.MARGIN.MB0]}>
        {`Registration No. - ${regNo ? regNo : "XXXXXXXXXXX"}`}
      </CoreTypographySubtitle1>

      <CoreTypographyBody1 styleClasses={[CoreClasses.MARGIN.MB0]}>
        {`Email: ${email ? email : "Not Given"}`}
      </CoreTypographyBody1>

      <CoreTypographyBody1 styleClasses={[CoreClasses.MARGIN.MB0]}>
        {`Phone: ${phone ? phone : "Not Given"}`}
      </CoreTypographyBody1>

    </CoreBox>
  );
}
