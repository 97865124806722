import { Property } from "../toolbox/property/Property.interface";
import { PropertyType } from "../toolbox/property/PropertyType.interface";

export class PropertyBuilder {
  private name: string;
  private description: string;
  private types: PropertyType[];

  constructor() {
    this.name = "";
    this.description = "";
    this.types = [];
  }

  setName(name: string): PropertyBuilder {
    this.name = name;
    return this;
  }

  setDescription(description: string): PropertyBuilder {
    this.description = description;
    return this;
  }

  setTypes(types: PropertyType[]): PropertyBuilder {
    this.types = types;
    return this;
  }

  build(): Property {
    return {
      description: this.description,
      name       : this.name,
      types      : this.types
    };
  }
}
