/* eslint-disable etc/no-commented-out-code */
/* eslint-disable no-console */
import { AppBuilderData } from "../components/data/app-builder/AppBuilder.data";
import { EditorData } from "../components/data/editor/Editor.data";
import { EntityData } from "../components/data/entity/Entity.data";
import { ToolBoxData } from "../components/data/toolbox/ToolBox.data";
import { notifyUsingSnackbar } from "../components/GlobalSnackbar";
import AppBuilderFactory from "../factory/AppBuilder.factory";
import {
  ADD_ATTRIBUTE,
  ADD_OR_SELECT_BUILDER_DATA, REMOVE_BUILDER, RENAME_ATTRIBUTE, SAVE_ENTITY_DATA_FAILURE, SAVE_ENTITY_DATA_SUCCESS, SELECTED_BUILDER, UPDATE_ATTRIBUTE_PROPERTY, UPDATE_ATTRIBUTE_SELECTED_PROPS, UPDATE_EDITOR_CONTENT, UPDATE_ENTITY_DATA_EXTRA_INFO, UPDATE_ENTITY_DATA_SCHEMA
} from "../types/builder.types";
import { BuilderEntitySchema } from "../utils/UtilityFunction";

export interface BuilderState {
  /** @todo Entity schema needs to be created and placed in place of any in the following line */
  appBuilderData: AppBuilderData<any>[];
}

/** Initial state of the builder options reducer  */
const initialState: BuilderState = { appBuilderData: [] };

/** 
 * Helper function to update builder data in the state
 * It takes the current state, the payload, and an updater function to apply changes
 */
const updateBuilderData = (state, payload, updater) => ({
  ...state,
  appBuilderData: state.appBuilderData.map((builderData) => {

    // Set the prototype of builderData to ensure it has the correct methods
    Object.setPrototypeOf(builderData, AppBuilderData.prototype);
    
    // If the builder is selected, update its entity data
    if (builderData.isSelected()) {
      const entityData = builderData.getEntityData();

      // Use the updater function to modify the entity data based on the payload
      updater(entityData, payload);
    }

    return builderData;
  }),
});

const selectedBuilder = (state) => {
  return {
    appBuilderData: state.appBuilderData
      .map((builderData) => {
        // Set the prototype of builderData to ensure it has the correct methods
        Object.setPrototypeOf(builderData, AppBuilderData.prototype);

        // Return the builderData if it is selected
        if (builderData.isSelected()) {
          return builderData;
        }

        // Return null or undefined for unselected builders
        return null;
      })
      // Remove null/undefined entries from the array
      .filter((builderData) => builderData !== null),
  };
};

/**  Main reducer function to handle different actions related to the builder */
const builderOptions = (state = initialState, action: {type: string, payload: any}): BuilderState => {
  switch (action.type) {

    case UPDATE_ATTRIBUTE_SELECTED_PROPS: {
      // Retrieve the selected builder
      const selectedBuilderData = selectedBuilder(state).appBuilderData;

      // Ensure a builder is selected
      if (!selectedBuilderData.length) return state;

      // Update the attributeSelected property in the selected builder
      const updatedAppBuilderData = state.appBuilderData.map((builderData) => {
        Object.setPrototypeOf(builderData, AppBuilderData.prototype);

        const editorData = builderData.getEditors();

        let selectedAttribute = null; // Track the selected attribute

        editorData.forEach((editor) => {
          Object.setPrototypeOf(editor, EditorData.prototype);

          // Update MODEL-type editors
          if (editor.getEditorType() === "MODEL") {
            const editorContent = editor.getEditorContent();

            if (editorContent && Array.isArray(editorContent.attribute)) {
              editorContent.attribute.forEach((attr) => {
                // Update the attributeSelected property
                const isSelected = attr.attributeId === action.payload.id;

                attr.attributeSelected = isSelected;

                if (isSelected) {
                  selectedAttribute = attr; // Store the selected attribute
                }
              });
            }
          }
        });

        // Update toolboxes only if a selected attribute is found
        const toolboxes = builderData.getToolBoxes();

        const toolboxesArray = Object.values(toolboxes);

        toolboxesArray.forEach((toolbox) => {
          Object.setPrototypeOf(toolbox, ToolBoxData.prototype);
          const toolboxType = toolbox.toolboxType; // Access property directly

          if (toolboxType === "property") {
            const toolboxContent = toolbox.getToolboxContent(); // Assume it returns an array

            // Safely clear and update the toolbox content
            if (Array.isArray(toolboxContent)) {
              toolboxContent.splice(0, toolboxContent.length); // Clear existing content
            }

            if (selectedAttribute?.validProps) {
              const updatedContent = selectedAttribute.validProps.map((prop) => {
                // Check if selectedProps has a matching key for this validProp name
                const matchingValue =
                  // eslint-disable-next-line no-prototype-builtins
                  selectedAttribute.selectedProps?.hasOwnProperty(prop.name) // Check if selectedProps contains the key
                    ? selectedAttribute.selectedProps[prop.name] // Use the value from selectedProps if it exists
                    // : prop.types?.[0]?.default ?? // Otherwise, use the default value
                    : null; // Fallback to null if no value is found

                return {
                  ...prop, // Keep the original structure of the prop
                  types: prop.types.map((type) => ({
                    ...type, // Keep the original structure of the type
                    value: matchingValue, // Add the resolved value
                  })),
                };
              });

              toolbox.setToolboxContent(updatedContent); // Update the toolbox content with the transformed validProps
            }

          }
        });

        return builderData; // Return the original builderData with modifications
      });

      return {
        ...state,
        appBuilderData: updatedAppBuilderData,
      };
    }

    case UPDATE_EDITOR_CONTENT: {
      const { editorData } = action.payload;

      // Get the selected builder data directly within the case
      const selectedBuilderData = state.appBuilderData
        .map((builderData) => {
          Object.setPrototypeOf(builderData, AppBuilderData.prototype);
          if (builderData.isSelected()) {
            return builderData;
          }
          return null;
        })
        .filter((builderData) => builderData !== null);

      // Check if there is any selected builder
      if (selectedBuilderData.length === 0) {
        return state; // No selected builder, return current state
      }

      // Since there's only one selected builder (based on the logic in selectedBuilder)
      const selectedBuilder = selectedBuilderData[0];

      // Get the editors of the selected builder
      const editors = selectedBuilder.getEditors();

      // Iterate over the editorData passed in the action and set the editorContent
      editorData.forEach((editor) => {
        // Find the editor that matches the one in the payload by matching editorId
        const matchedEditor = editors.find(
          (existingEditor) => {
            Object.setPrototypeOf(existingEditor, EditorData.prototype);
            return existingEditor.getEditorId() === editor.getEditorId();
          }
        );

        // If a matching editor is found, update its editorContent
        if (matchedEditor) {
          Object.setPrototypeOf(matchedEditor, EditorData.prototype); // Ensure correct prototype
          matchedEditor.setEditorContent(editor.getEditorContent()); // Update the editorContent
        }
      });

      return { ...state };
    }

    /** Add a new builder data to the state and select it */
    case ADD_OR_SELECT_BUILDER_DATA:
      // eslint-disable-next-line no-case-declarations
      let found = false;
      // eslint-disable-next-line no-case-declarations
      const updatedBuilderData = state.appBuilderData;

      updatedBuilderData.forEach((builder) => {
        Object.setPrototypeOf(builder, AppBuilderData.prototype);
        const entityData = builder.getEntityData();

        Object.setPrototypeOf(entityData, EntityData.prototype);

        const payloadEnityData = action.payload.entityData;

        // If the current builder's entity data matches the payload entity, select it
        if(entityData.getId() === payloadEnityData?.id) {
          builder.setSelected(true);
          found = true;
        } else {
          builder.setSelected(false);
        }
      });

      // If no builder data matched the payload, create a new one
      if(!found){
        const {
          builderId, toolbars, toolboxes, editors, builderType, entityData, dispatch
        } = action.payload;

        // Use the factory to create a new builder instance
        const newBuilder: AppBuilderData<BuilderEntitySchema> = AppBuilderFactory.getAppBuilderData<BuilderEntitySchema>(
          builderId,
          toolbars,
          toolboxes,
          editors,
          builderType,
          entityData,
          dispatch
        );

        Object.setPrototypeOf(newBuilder, AppBuilderData.prototype);
        newBuilder.setSelected(true);
        
        // Add the new builder to the array
        updatedBuilderData.push(newBuilder);
      }

      return {
        ...state,
        appBuilderData: JSON.parse(JSON.stringify(updatedBuilderData)), /** @todo we need to remove JSON.parse and make sure the code is working without it. */
      };

    /** Action to update the entity schema for the selected builder */
    case UPDATE_ENTITY_DATA_SCHEMA:
      return updateBuilderData(state, action.payload, (entityData, payload) => {
        entityData.setSchema(payload.entitySchema);
      });

    /** Action to update the extra info for the selected builder */
    case UPDATE_ENTITY_DATA_EXTRA_INFO:
      return updateBuilderData(state, action.payload, (entityData, payload) => {
        entityData.setExtraInfo(payload.extraInfo);
      });
    
    /** Action to handle successful entity data save */
    case SAVE_ENTITY_DATA_SUCCESS:
      notifyUsingSnackbar("Data saved successfully", "success");
      return state;

    /** Action to handle failure in saving entity data */
    case SAVE_ENTITY_DATA_FAILURE:
      notifyUsingSnackbar("Failed to save data", "error");
      return state;
    
    case SELECTED_BUILDER: {
      const { builderId } = action.payload;
      const updatedAppBuilderData = state.appBuilderData.map(builder => ({
        ...builder,
        selected: builder.builderId === builderId
      }));
        
      return {
        ...state,
        appBuilderData: JSON.parse(JSON.stringify(updatedAppBuilderData)),
      };
    }

    case ADD_ATTRIBUTE: {
      // Get the selected builder using the helper function
      const selectedBuilderData = selectedBuilder(state).appBuilderData;
    
      // Ensure a builder is selected
      if (!selectedBuilderData.length) {
        return state; // No selected builder, return current state
      }
    
      // Get the selected builder (assuming only one is selected)
      const builderData = { ...selectedBuilderData[0] };
    
      // Set prototype for builder
      Object.setPrototypeOf(builderData, AppBuilderData.prototype);
    
      // Get entity data and set prototype
      const entityData = builderData.getEntityData();

      Object.setPrototypeOf(entityData, EntityData.prototype);
    
      const schema = entityData.getSchema();
    
      // Validate schema and attributes object
      if (!schema || typeof schema.attributes !== "object") {
        return state;
      }
    
      // Generate a unique attribute name
      let newAttributeIndex = 1;
      let newAttributeName = `addedAttribute${newAttributeIndex}`;
    
      while (Object.prototype.hasOwnProperty.call(schema.attributes, newAttributeName)) {
        newAttributeIndex++;
        newAttributeName = `addedAttribute${newAttributeIndex}`;
      }
    
      // Define new attribute with default properties
      const newAttribute = { name: newAttributeName, type: "INTEGER" };
    
      // Create a new schema object with the updated attributes
      const newSchema = {
        ...schema,
        attributes: {
          ...schema.attributes,
          [newAttributeName]: newAttribute,
        }
      };
    
      // Update entity data with the new schema
      const newEntityData = { ...entityData, schema: newSchema };
    
      // Update the builder with the modified entity data
      builderData.entityData = newEntityData;
    
      return {
        ...state,
        appBuilderData: state.appBuilderData.map(bData =>
          bData.builderId === builderData.builderId ? builderData : bData
        ),
      };
    }
    
    case RENAME_ATTRIBUTE: {
      const { oldAttributeName, newAttributeName } = action.payload;
    
      return {
        ...state,
        appBuilderData: state.appBuilderData.map((builderData) => {
          // Set prototype for builder
          Object.setPrototypeOf(builderData, AppBuilderData.prototype);
    
          // Skip builders that are not selected
          if (!builderData.isSelected()) {
            return builderData;
          }
    
          // Retrieve entity data and set prototype
          const entityData = builderData.getEntityData();

          Object.setPrototypeOf(entityData, EntityData.prototype);
      
          // Retrieve current schema
          const schema = entityData.getSchema();
      
          // Validate schema and attributes
          if (!schema || !schema.attributes) {
            return builderData;
          }
      
          const attributes = schema.attributes;
          const oldAttribute = attributes[oldAttributeName];
      
          // If the old attribute does not exist, return unchanged
          if (!oldAttribute) {
            return builderData;
          }
      
          // Preserve attribute properties while renaming
          const updatedAttribute = {
            ...oldAttribute, // Copy all properties
            name: newAttributeName, // Update the name property
          };
      
          // Create updated attributes object
          const updatedAttributes = {
            ...attributes,
            [newAttributeName]: updatedAttribute, // Add renamed attribute
          };
          
          // Delete old attribute key
          delete updatedAttributes[oldAttributeName];
      
          // Create updated schema with the renamed attribute
          const newSchema = {
            ...schema,
            attributes: updatedAttributes,
          };
      
          // Update schema in entity data
          entityData.setSchema(newSchema);
      
          return builderData;
        })
      };
    }
    
    case UPDATE_ATTRIBUTE_PROPERTY: {
      const { attributeName, propertyName, value } = action.payload;
    
      // Retrieve the selected builder data
      const selectedBuilderData = selectedBuilder(state).appBuilderData;
    
      // If no builder is selected, return current state
      if (!selectedBuilderData.length) {
        return state;
      }
    
      // Extract the selected builder
      const builderData = selectedBuilderData[0];
    
      // Ensure prototype consistency
      Object.setPrototypeOf(builderData, AppBuilderData.prototype);
      const entityData = builderData.getEntityData();

      Object.setPrototypeOf(entityData, EntityData.prototype);
    
      // Retrieve schema and validate
      const schema = entityData.getSchema();

      if (!schema || !schema.attributes) {
        return state;
      }
    
      const currentAttribute = schema.attributes[attributeName];
    
      // If the attribute does not exist, return current state
      if (!currentAttribute) {
        return state;
      }
    
      // Create updated schema with modified attribute property
      const newSchema = {
        ...schema,
        attributes: {
          ...schema.attributes,
          [attributeName]: {
            ...currentAttribute,
            [propertyName]: value
          }
        }
      };
    
      // Update entity schema
      entityData.setSchema(newSchema);
    
      return {
        ...state,
        appBuilderData: state.appBuilderData.map(bData => {
          if (bData.builderId === builderData.builderId) {
            return builderData; // Replace modified builder
          }
          return bData; // Keep others unchanged
        }),
      };
    }
    
    case REMOVE_BUILDER: {
      const { builderId } = action.payload;
      
      // Remove the builder with the specified ID
      const updatedBuilderData = state.appBuilderData.filter(builder => builder.builderId !== builderId);
      
      // If there are remaining builders, select the last one
      if (updatedBuilderData.length > 0) {
        const lastBuilder = updatedBuilderData[updatedBuilderData.length - 1];

        Object.setPrototypeOf(lastBuilder, AppBuilderData.prototype);
        lastBuilder.setSelected(true);
      }
    
      return {
        ...state,
        appBuilderData: JSON.parse(JSON.stringify(updatedBuilderData)), // Deep copy to ensure immutability
      };
    }

    // Return the current state for unhandled action types
    default:
      return state;
  }
};

export default builderOptions;