/* eslint-disable etc/no-commented-out-code */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import {
  CoreTypographyBody1,
  apiRequestAction,
  CoreBox,
  CoreStack,
  CoreCard,
  CoreCardContent,
  CoreCardHeader,
  CoreClasses,
  HTTP,
  CoreH6,
  CoreCircularProgress,
  CoreIcon,
  CoreIconButton,
  CoreH5
} from "@wrappid/core";
import { useSelector, useDispatch } from "react-redux";

import {
  COUNTER_WIDGET_GET_SUCCESS,
  COUNTER_WIDGET_GET_ERROR,
  COUNTER_WIDGET_INIT
} from "../types/widgetTypes";

export default function CounterWidget(props) {
  const [mounted, setMounted] = useState(false);
  const dispatch = useDispatch();
  const { widgetId, heading, icon, options, loading } = props;
  const { maxCounter = 2, counters = [] } = options;
  const {
    counterInitState = false,
    primaryCounter = {},
    otherCounters = [],
  } = useSelector((state) => state.widget?.counter[widgetId] || {});

  /**
   * init counter widget in widget reducer
   */
  React.useEffect(() => {
    if (
      !loading &&
      !counterInitState &&
      Object.keys(primaryCounter)?.length === 0 &&
      otherCounters?.length === 0
    ) {
      let primaryCounter =
        counters?.filter((counter) => {
          return counter?.primary && counter?.primary === true;
        })[0] || counters[0];

      let otherCounters = counters
        ?.filter((counter) => {
          return counter.id !== primaryCounter.id;
        })
        .slice(0, maxCounter);

      dispatch({
        payload: {
          data: {
            otherCounters,
            primaryCounter,
          },
          widgetId,
        },
        type: COUNTER_WIDGET_INIT,
      });
    }
  }, [loading, counterInitState, primaryCounter, otherCounters]);

  React.useEffect(() => {
    if (!loading && (counterInitState || !mounted)) {
      if (
        primaryCounter.entity &&
        (primaryCounter?.apiSuccess !== true || !mounted)
      ) {
        setMounted(true);
        let reqURL = "/business/count/" + primaryCounter?.entity;
        let apiData = {};

        if (primaryCounter?.filterQuery) {
          apiData = {
            _defaultFilter: encodeURIComponent(
              JSON.stringify(primaryCounter?.filterQuery)
            ),
          };
        }

        dispatch(
          apiRequestAction(
            HTTP.GET,
            reqURL,
            true,
            apiData,
            COUNTER_WIDGET_GET_SUCCESS,
            COUNTER_WIDGET_GET_ERROR,
            null,
            null,
            null,
            null,
            null,
            {
              primary       : true,
              widgetEntity  : primaryCounter?.entity,
              widgetEntityId: primaryCounter?.id,
              widgetId,
            }
          )
        );
      }

      if (otherCounters && otherCounters?.length > 0) {
        otherCounters.forEach(async (eachCounter) => {
          if (eachCounter?.entity && eachCounter?.apiSuccess !== true) {
            let reqURL = "/business/count/" + eachCounter?.entity;
            let apiData = {};

            if (eachCounter?.filterQuery) {
              apiData = {
                _defaultFilter: encodeURIComponent(
                  JSON.stringify(eachCounter?.filterQuery)
                ),
              };
            }

            dispatch(
              apiRequestAction(
                HTTP.GET,
                reqURL,
                true,
                apiData,
                COUNTER_WIDGET_GET_SUCCESS,
                COUNTER_WIDGET_GET_ERROR,
                null,
                null,
                null,
                null,
                null,
                {
                  primary       : false,
                  widgetEntity  : eachCounter?.entity,
                  widgetEntityId: eachCounter?.id,
                  widgetId,
                }
              )
            );
          }
        });
      }
    }
  }, [loading, counterInitState, primaryCounter, otherCounters]);

  return (
    <CoreCard styleClasses={[CoreClasses.BORDER.BORDER_ROUNDED_5]}>
      <CoreCardHeader
        styleClasses={[CoreClasses.WIDGET.COUNTER_WIDGET_CARD_HEADER, CoreClasses.BG.BG_SECONDARY]}
        title={
          heading && (
            <CoreBox>
              <CoreBox
                styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]}
              >
                <CoreH5
                  styleClasses={[CoreClasses.TEXT.TEXT_START, CoreClasses.COLOR.TEXT_WHITE]}
                >
                  {heading}
                </CoreH5>

                <CoreIconButton styleClasses={[CoreClasses.COLOR.TEXT_WHITE]}>
                  <CoreIcon >
                    calendar_month_rounded
                  </CoreIcon>
                </CoreIconButton>
              </CoreBox>

              <CoreBox
                styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_STRETCH]}
              >
                <CoreIconButton styleClasses={[CoreClasses.COLOR.TEXT_WHITE]}>
                  <CoreIcon>keyboard_arrow_left</CoreIcon>
                </CoreIconButton>

                <CoreBox>
                  <CoreTypographyBody1
                    styleClasses={[CoreClasses.MARGIN.MY1, CoreClasses.COLOR.TEXT_WHITE]}
                  >
                    Today
                  </CoreTypographyBody1>
                </CoreBox>

                <CoreIconButton styleClasses={[CoreClasses.COLOR.TEXT_WHITE]}>
                  <CoreIcon>keyboard_arrow_right</CoreIcon>
                </CoreIconButton>
              </CoreBox>
            </CoreBox>
          )
        }
      />

      {/* <CoreIconText icon="mail" text="Mail"></CoreIconText> */}

      {/* -- <CoreIcon styleClasses={[CoreClasses.WIDGET.COUNTER_WIDGET_CARD_ICON]} options={icon} /> */}
      <CoreCardContent
        styleClasses={[CoreClasses.WIDGET.COUNTER_WIDGET_CARD_CONTENT]}
      >
        {loading ? (
          <CoreCircularProgress />
        ) : (
          <CoreStack
            direction="row"
            styleClasses={[CoreClasses.ALIGNMENT.SM.JUSTIFY_CONTENT_SPACE_EVENLY]}
          >
            {primaryCounter && (
              <CoreBox
                styleClasses={[CoreClasses.WIDGET.COUNTER_WIDGET_COUNTER, CoreClasses.WIDGET.COUNTER_WIDGET_COUNTER_PRIMARY]}
              >
                <CoreBox>
                  <CoreH6
                    styleClasses={[
                      CoreClasses.PADDING.P2,
                      CoreClasses.BORDER.BORDER,
                      CoreClasses.BORDER.BORDER_WIDTH_3,
                      CoreClasses.BORDER.BORDER_ROUNDED_PILL,
                      CoreClasses.WIDTH.MIN_W_25,
                      CoreClasses.BORDER.BORDER_COLOR_SECONDARY,
                      CoreClasses.COLOR.TEXT_SECONDARY,
                    ]}
                  >
                    {primaryCounter?.count || 0}
                  </CoreH6>
                </CoreBox>

                <CoreTypographyBody1
                  styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.COLOR.TEXT_SECONDARY]}
                >
                  {primaryCounter?.label}
                </CoreTypographyBody1>
              </CoreBox>
            )}

            {otherCounters &&
              otherCounters.length > 0 &&
              otherCounters.map((otherCounter, index) => {
                return (
                  <CoreBox
                    key={`otherCounter-${index}`}
                    styleClasses={[CoreClasses.WIDGET.COUNTER_WIDGET_COUNTER]}
                  >
                    <CoreBox>
                      <CoreH6
                        styleClasses={[
                          CoreClasses.PADDING.P2,
                          CoreClasses.BORDER.BORDER,
                          CoreClasses.BORDER.BORDER_WIDTH_3,
                          CoreClasses.BORDER.BORDER_ROUNDED_PILL,
                          CoreClasses.WIDTH.MIN_W_25,
                          CoreClasses.BORDER.BORDER_COLOR_SUCCESS_LIGHT,
                          CoreClasses.COLOR.TEXT_SUCCESS_LIGHT,
                        ]}
                      >
                        {otherCounter?.count || 0}
                      </CoreH6>
                    </CoreBox>

                    <CoreTypographyBody1
                      styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.COLOR.TEXT_SUCCESS_LIGHT]}
                    >
                      {otherCounter?.label}
                    </CoreTypographyBody1>
                  </CoreBox>
                );
              })}
          </CoreStack>
        )}
      </CoreCardContent>

      {/* -- <CardActions
      // styleClasses={[CoreClasses.WIDGET.COUNTER_WIDGET_CARD_ACTIONS]}
      >
        <Button size="small" color="primary">
          Share
        </Button>
      </CardActions> */}
    </CoreCard>
  );
}
