export function convertMetaToJSON(data) {
  const transformedData = {};
    
  data.forEach((datum) => {
    transformedData[datum.key] = datum.value;
  });
  return transformedData;
}

export function patientRawDataTransform(tableData) {

  let email = tableData?.RelatedPerson?.User?.email || tableData?.RelatedPerson?.PersonContacts?.filter(contact => contact.type === "email")[0].data || null;
  let phone = tableData?.RelatedPerson?.User?.phone || tableData?.RelatedPerson?.PersonContacts?.filter(contact => contact.type === "phone")[0].data || null;
  let addreess = tableData?.RelatedPerson?.PersonAddresses[0] || null;
  let personMeta = convertMetaToJSON(tableData?.RelatedPerson?.PersonMetas);

  return {
    addressLine1  : addreess?.addLine1,
    addressLine2  : addreess?.addLine2,
    bloodGroup    : personMeta?.bloodGroup,
    city          : addreess?.city,
    country       : addreess?.country,
    district      : addreess?.landmark,
    dob           : personMeta?.dob,
    email         : email,
    firstName     : personMeta?.firstName,
    gender        : personMeta?.gender,
    landmark      : personMeta?.landmark,
    lastName      : personMeta?.lastName,
    marritalStatus: personMeta?.marritalStatus,
    middleName    : personMeta?.middleName,
    personID      : tableData?.RelatedPerson?.id,
    phone         : phone,
    photo         : personMeta?.photoUrl,
    pincode       : tableData.pin,
    profileId     : personMeta?.profileId,
    state         : tableData.state,
    userID        : tableData?.RelatedPerson?.User?.id,
  };
}