/* eslint-disable etc/no-commented-out-code */
// /* eslint-disable etc/no-commented-out-code */
// /* eslint-disable no-console */
import React, { useEffect, useState } from "react";

import {
  CoreBox,
  CoreClasses,
  CoreTypographyBody1,
  CoreCard,
  CoreIconButton,
  CoreIcon,
  CoreTooltip,
  CoreCardActions,
  CoreGrid,
  CoreCardHeader, CoreTypographyBody2,
  CoreCardContent
} from "@wrappid/core";
import { useDispatch } from "react-redux";

import ModelAssociationViewer from "./ModelAssociationEditor";
import ModelAttributeViewer from "./ModelAttributeEditor";
import { updateAttribute, setToolboxContent, addAttribute } from "../../../actions/builder.actions";
import ModuleClasses from "../../../styles/ModuleClasses";
import { AssociationData } from "../../data/entity/ModelEntity.data";

// React Component (unchanged functionality, uses groupedForeignKeys)
export default function ModelEditor(props) {
  const { content } = props;
  const dispatch = useDispatch();
  const [attributeId, setAttributeId] = useState("");
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);

  const attributesData = content.attribute;
  const groupedForeignKeys = content.associations;

  // eslint-disable-next-line no-console

  const setValidPropsToToolboxDataInReducer = (validProps) => {
    dispatch(setToolboxContent(validProps));
  };

  // const currentState = useSelector((state: any) => state.BuilderOptions);
  // const builderId = GetDataFromReducer(getBuilderData()?.getId())?.builderId;
  const handleAddAttribute = () => {
    dispatch(addAttribute());
  };
  
  const handleSelectedAttribute = (attributeId) => {
    // Log the selected attribute ID
  
    // Update local state for selected attribute ID
    setSelectedAttributeId(attributeId);
  
    // Dispatch selected attribute ID
    dispatch(updateAttribute(attributeId));
  
    // Find the selected attribute details
    const selectedAttribute = attributesData.find(
      (attribute) => attribute.attributeId === attributeId
    );
  
    if (selectedAttribute != null) {
      // Push the selected attribute's data to the toolbox content
      dispatch(setToolboxContent(selectedAttribute));
    }
  };

  const checkAndDispatchToolboxData = () => {
    const hasAttributeSelected = attributesData?.some(
      (item: any) => item.attributeSelected === true
    );

    if (hasAttributeSelected) {
      const attributeIdFromReducer = attributesData?.find(
        (item: any) => item.attributeSelected === true
      )?.id;

      if (attributeId !== attributeIdFromReducer) {
        const matchingItem = attributesData?.find(
          (item: any) => item.id === attributeIdFromReducer
        );

        if (matchingItem?.validProps) {
          setValidPropsToToolboxDataInReducer(matchingItem.validProps);
        }
        setAttributeId(attributeIdFromReducer);
      }
    }
  };

  useEffect(() => {
    checkAndDispatchToolboxData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributesData]);

  return (
    <CoreBox styleClasses={CoreClasses.PADDING.P2}>
      <CoreBox
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.PADDING.P2]}
      >
        <CoreCard styleClasses={[ModuleClasses.ATTRIBUTE_CARD]}>
          {/* <CoreCardHeader
            styleClasses={[ModuleClasses.CARD_HEADER]}
            subheader={
              <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.MB0]}>
                {viewerContent.model || viewerContent.table}
              </CoreTypographyBody2>
            }
          /> */}
          <CoreCardContent styleClasses={[CoreClasses.PADDING.P1]}>
            {attributesData.length > 0 ? (
              attributesData.map((attribute:any) => (
                <ModelAttributeViewer
                  key={attribute.attributeId}
                  attribute={attribute}
                  selectedId={selectedAttributeId}
                  onSelect={handleSelectedAttribute}
                />
              ))
            ) : (
              <CoreTypographyBody1>No buttons available.</CoreTypographyBody1>
            )}
          </CoreCardContent>
          <CoreCardActions
            styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
          >
            <CoreTooltip title="Add Attributes" arrow placement="right">
              <CoreIconButton onClick={handleAddAttribute}>
                <CoreIcon icon="add" color="primary" />
              </CoreIconButton>
            </CoreTooltip>
          </CoreCardActions>
        </CoreCard>
      </CoreBox>

      <CoreGrid
        container
        spacing={3}
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.PADDING.P2]}
      >
        {Object.entries(groupedForeignKeys).length > 0 ? (
          Object.entries(groupedForeignKeys).map(([tableName, relations]: [string, AssociationData[]]) => (
            <CoreCard
              key={tableName}
              gridProps={{ gridSize: { sm: 3 } }}
              elevation={3}
              styleClasses={[ModuleClasses.ASSOCIATIONS_CARD]}
            >
              <CoreCardHeader
                styleClasses={[ModuleClasses.CARD_HEADER]}
                subheader={
                  <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.MB0]}>
                    {tableName}
                  </CoreTypographyBody2>
                }
              />

              <CoreCardContent styleClasses={[CoreClasses.PADDING.P1]}>
                {relations.map((relation, index) => (
                  <CoreBox
                    key={index}
                    styleClasses={[
                      index < relations.length - 1
                        ? CoreClasses.BORDER.BORDER_BOTTOM
                        : null,
                      CoreClasses.BORDER.BORDER_COLOR_GREY_300,
                    ]}
                  >
                    <ModelAssociationViewer key={index} relation={relation} />
                  </CoreBox>
                ))}
              </CoreCardContent>
            </CoreCard>
          ))
        ) : (
          <CoreGrid item xs={12}>
            <CoreTypographyBody1 styleClasses={CoreClasses.TEXT.TEXT_CENTER}>
              No foreign keys found.
            </CoreTypographyBody1>
          </CoreGrid>
        )}
      </CoreGrid>
    </CoreBox>
  );
}