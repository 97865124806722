import { getWrappidUUID } from "@wrappid/core/utils/appUtils";

import { AttributeData } from "./Attribute.data";
import { Property } from "../toolbox/property/Property.interface";
import { PropertyManager } from "../valid-props/PropertyManager";

export class FormattedAttributeData {
  attributeSelected: boolean;
  attributeId: string;
  selectedProps: AttributeData;
  validProps: Property[];

  constructor(attributeId: string, selectedProps: AttributeData) {
    this.attributeSelected = false;
    this.attributeId = attributeId || getWrappidUUID();
    this.selectedProps = selectedProps;
    this.validProps = this.getValidProps();
  }

  private getValidProps(): Property[] {
    const validPropsInstance = new PropertyManager();

    return validPropsInstance.getValidProperties();
  }
}