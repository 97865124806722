import { ModelEditorConverter } from "./ModelEditor.converter";
import { MODEL_EDITOR } from "../../../../constants/AppBuilder.constant";
import EditorFactory from "../../../../factory/Editor.factory";
import { EntityData } from "../../entity/Entity.data";
import { FormattedAttributeData } from "../../model/FormattedAttribute.data";
import { EditorData } from "../Editor.data";

export class ModelEditorData<TSchema> extends EditorData<TSchema>{
  static storedEditorContent: any;
  constructor(
    editorId: string,
    storedEditorContent: any,
    entityData: EntityData<TSchema>
  ){
    super(
      editorId,
      storedEditorContent,
      entityData,
      EditorFactory.VARIANTS.MODEL_EDITOR
    );
    ModelEditorData.storedEditorContent = storedEditorContent;
    this.setEditorRenderedComponent(MODEL_EDITOR);
  }

  prepareEditorContent(storedEditorContent: any, entityData: EntityData<TSchema>): void {
    Object.setPrototypeOf(entityData, EntityData.prototype);
    if (!entityData || !entityData.getSchema()) {
      throw new Error("Invalid entity data");
    }

    const schema = entityData.getSchema();

    if (!Array.isArray([schema])) {
      throw new Error("Input schema should be an array");
    }

    const extractedAttributes = new ModelEditorConverter().extractAttributes([schema]);
    const extractedAssociation = new ModelEditorConverter().extractAssociation([schema]);
    
    const formattedAttributes = extractedAttributes.map((attribute) => {
      if (storedEditorContent) {
      // Iterate over editorContent.attribute to find if any name matches
        const matchingAttribute = storedEditorContent.attribute.find(
          (singleAttribute) => singleAttribute.selectedProps.name === attribute.name
        );

        // If a match is found, use attributeId and attribute; otherwise, just use the attribute
        if (matchingAttribute) {
          return new FormattedAttributeData(matchingAttribute.attributeId, attribute);
        } else {
          return new FormattedAttributeData(undefined, attribute);
        }
      } else {
      // In case editorContent is undefined or null
        return new FormattedAttributeData(undefined, attribute);
      }
    });

    this.editorContent = { "associations": extractedAssociation, "attribute": formattedAttributes };    
  }
}