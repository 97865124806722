// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import React from "react";

import {
  CoreAppBar,
  CoreBox,
  CoreClasses,
  CoreCssBaseline,
  CoreFooter,
  CoreLayoutPlaceholder,
  CoreResourceContext
} from "@wrappid/core";

export default function RxefyAppLayout() {
  // eslint-disable-next-line no-unused-vars
  const resources = React.useContext(CoreResourceContext);

  return (
    <>
      <CoreBox
        styleClasses={[CoreClasses.HEIGHT.H_100, CoreClasses.DISPLAY.FLEX]}>

        <CoreCssBaseline />

        <CoreAppBar logo={resources?.appLogo} leftMenuEnabled={false} />

        <CoreBox
          component="main"
          styleClasses={[CoreClasses.LAYOUT.CONTENT_CONTAINER]}
        >
          <CoreBox styleClasses={[CoreClasses.LAYOUT.MAIN_CONTAINER]}>
            <CoreLayoutPlaceholder id={RxefyAppLayout.PLACEHOLDER.CONTENT} />
          </CoreBox>

          <CoreFooter/>
        </CoreBox>
      </CoreBox>

    </>
  );
}

RxefyAppLayout.PLACEHOLDER = { CONTENT: "content" };
