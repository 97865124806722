import { JSON_EDITOR } from "../../../../constants/AppBuilder.constant";
import EditorFactory from "../../../../factory/Editor.factory";
import { EntityData } from "../../entity/Entity.data";
import { EditorData } from "../Editor.data";

export class JsonEditorData<TSchema> extends EditorData<TSchema>{
  constructor(
    editorId: string,
    storedEditorContent: any,
    entityData: EntityData<TSchema>
  ){
    super(
      editorId,
      storedEditorContent,
      entityData,
      EditorFactory.VARIANTS.JSON_EDITOR
    );
    this.setEditorRenderedComponent(JSON_EDITOR);
  }

  prepareEditorContent(storedEditorContent: any, entityData: EntityData<TSchema>): void {
    Object.setPrototypeOf(entityData, EntityData.prototype); /** @todo this is temporary we need to remove that */
    this.editorContent = entityData?.getSchema();
  }
}