import {
  CoreBox,
  CoreClasses,
  CoreDivider,
  CoreGrid,
  CoreLabel,
  CoreTypographyBody2
} from "@wrappid/core";
import moment from "moment";

export default function PrescriptionGenratedData({ prescription }) {
  return (
    <>
      <CoreGrid>
        <CoreBox
          gridProps={{ gridSize: { sm: 6 } }}
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN]}
        >
          <CoreLabel>{"Assessment Date"}</CoreLabel>

          <CoreTypographyBody2 component="span">
            {prescription?.isCompleted && prescription?.generationDate
              ? moment(prescription.generationDate)
                .add(60 * 5 + 30, "minutes")
                .format("DD/MM/YYYY hh:mm a")
              : prescription?.createdAt
                ? moment(prescription.createdAt)
                  .add(60 * 5 + 30, "minutes")
                  .format("DD/MM/YYYY hh:mm a")
                : "dd/mm/yyyy hh:mm am/pm"}
          </CoreTypographyBody2>
        </CoreBox>

        <CoreBox
          gridProps={{ gridSize: { sm: 6 } }}
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_COLUMN]}
        >
          <CoreLabel>{"Assessment ID"}</CoreLabel>

          <CoreTypographyBody2 component="span">
            {prescription?.assessmentId
              ? prescription.assessmentId
              : "##########"}
          </CoreTypographyBody2>
        </CoreBox>
      </CoreGrid>

      <CoreDivider />
    </>
  );
}
