import React from "react";

import { CoreBox, CoreInputAdornment, CoreIcon, CoreTextField } from "@wrappid/core";

export default function RouteEditor(props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { content, contentExtraInfo } = props;

  return (
    <>
      <CoreBox component="pre">
        {JSON.stringify(content, null, 2)}
      </CoreBox>
      <CoreBox>
        <CoreTextField
          label="URL"
          defaultValue={content?.url}
          fullWidth
          multiline
          variant="outlined"
          InputProps={{
            endAdornment: (
              <CoreInputAdornment position="end">
                <CoreIcon icon="content_copy"
                  style={{ cursor: "pointer" }}
                />
              </CoreInputAdornment>
            ),
          }}
          style={{ fontSize: "16px" }} // Adjust font size if needed
        />
      </CoreBox>
    </>
  );
}