import { LAYOUT_TOOLBOX } from "../../../../constants/AppBuilder.constant";
import ToolBoxFactory from "../../../../factory/ToolBox.factory";
import { ToolBoxData } from "../ToolBox.data";

/**
 * Represents the layout toolbox data.
 * Extends the base `ToolBoxData` class to provide default configurations for a layout toolbox.
 */
export class LayoutToolBoxData extends ToolBoxData{
  
  constructor(
    toolboxId: string,
    toolboxTitle = "Layout",
    toolboxExpand = true,
    toolboxResize = "both",
    renderedComponent = LAYOUT_TOOLBOX
  ){
    super(
      toolboxId,
      ToolBoxFactory.VARIANTS.LAYOUT,
      toolboxTitle, toolboxExpand, toolboxResize
    );
    this.setToolboxRenderedComponent(renderedComponent);

  }

}