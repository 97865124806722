import { MenusRegistry as AppBuilderMenusRegistry } from "./app-builder/menus.registry";
import { MenusRegistry as AppointmentMenusRegistry } from "./appointment/menus.registry";
import { MenusRegistry as AuthMenusRegistry } from "./auth/menus.registry";
import { MenusRegistry as ClinicMenusRegistry } from "./clinic/menus.registry";
import { MenusRegistry as CommunicationMenusRegistry } from "./communication/menus.registry";
import { MenusRegistry as DashboardMenusRegistry } from "./dashboard/menus.registry";
import { MenusRegistry as DataManagementMenusRegistry } from "./data-management/menus.registry";
import { MenusRegistry as PatientMenusRegistry } from "./patient/menus.registry";
import { MenusRegistry as RxsettingsMenusRegistry } from "./rxsettings/menus.registry";
import { MenusRegistry as SupportMenusRegistry } from "./support/menus.registry";
import { MenusRegistry as UserManagementMenusRegistry } from "./user-management/menus.registry";

export default {
...AppBuilderMenusRegistry,
...AppointmentMenusRegistry,
...AuthMenusRegistry,
...ClinicMenusRegistry,
...CommunicationMenusRegistry,
...DashboardMenusRegistry,
...DataManagementMenusRegistry,
...PatientMenusRegistry,
...RxsettingsMenusRegistry,
...SupportMenusRegistry,
...UserManagementMenusRegistry,
}