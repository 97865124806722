import MediumappBuilderStyles from "../../modules/app-builder/styles/MediumModuleStyles";
import MediumappointmentStyles from "../../modules/appointment/styles/MediumModuleStyles";
import MediumauthStyles from "../../modules/auth/styles/MediumModuleStyles";
import MediumclinicStyles from "../../modules/clinic/styles/MediumModuleStyles";
import MediumcommunicationStyles from "../../modules/communication/styles/MediumModuleStyles";
import MediumdashboardStyles from "../../modules/dashboard/styles/MediumModuleStyles";
import MediumdataManagementStyles from "../../modules/data-management/styles/MediumModuleStyles";
import MediumpatientStyles from "../../modules/patient/styles/MediumModuleStyles";
import MediumprescriptionStyles from "../../modules/prescription/styles/MediumModuleStyles";
import MediumrxprofileStyles from "../../modules/rxprofile/styles/MediumModuleStyles";
import MediumrxsettingsStyles from "../../modules/rxsettings/styles/MediumModuleStyles";
import MediumsupportStyles from "../../modules/support/styles/MediumModuleStyles";
import MediumuserManagementStyles from "../../modules/user-management/styles/MediumModuleStyles";

const MediumModulesStyles = {
...new MediumappBuilderStyles().style,
...new MediumappointmentStyles().style,
...new MediumauthStyles().style,
...new MediumclinicStyles().style,
...new MediumcommunicationStyles().style,
...new MediumdashboardStyles().style,
...new MediumdataManagementStyles().style,
...new MediumpatientStyles().style,
...new MediumprescriptionStyles().style,
...new MediumrxprofileStyles().style,
...new MediumrxsettingsStyles().style,
...new MediumsupportStyles().style,
...new MediumuserManagementStyles().style,
};
 export default MediumModulesStyles