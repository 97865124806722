import {
  CoreAvatar,
  CoreBox,
  CoreClasses,
  CoreTypographyBody1,
  CoreTypographyBody2,
  CoreTypographySubtitle1,
  CoreTypographySubtitle2
} from "@wrappid/core";

export default function PrescriptionClinicCard(props) {
  const {
    fullName,
    phone,
    clinicLogo,
    addLine1,
    addLine2,
    country,
    state,
    district,
    city,
    pin
  } = props;

  const prepareAddressLine1 = () => {
    let address = "";

    if (addLine1) address += addLine1;
    if (addLine2) address += ", " + addLine2;
    if (city) address += ", " + city;
    if (pin) address += " - " + pin;
    return address;
  };

  const prepareAddressLine2 = () => {
    let address = "";

    if (district) address += district;
    if (state) address += ", " + state;
    if (country) address += ", " + country;
    return address;
  };

  return !fullName || fullName === "" ? (
    <CoreBox>
      <CoreTypographySubtitle2>No clinic selected</CoreTypographySubtitle2>
    </CoreBox>
  ) : (
    <CoreBox
      styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
    >
      <CoreBox
        styleClasses={[CoreClasses.TEXT.TEXT_END, CoreClasses.MARGIN.MR2]}
        // gridProps={{ gridSize: 9 }}
      >
        <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.MB0]}>{fullName}</CoreTypographyBody2>

        <CoreTypographySubtitle1 styleClasses={[CoreClasses.MARGIN.MB0]}>
          {prepareAddressLine1()}
        </CoreTypographySubtitle1>

        <CoreTypographySubtitle1 styleClasses={[CoreClasses.MARGIN.MB0]}>
          {prepareAddressLine2()}
        </CoreTypographySubtitle1>
        
        <CoreTypographyBody1
          styleClasses={[CoreClasses.TEXT.TEXT_END]}
        >
          {"Contact: " + phone}
        </CoreTypographyBody1>
      </CoreBox>

      <CoreAvatar
        variant="square"
        styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_XLARGE]}
        // gridProps={{
        //   gridSize    : 3,
        //   styleClasses: [CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER] 
        // }}
        src={clinicLogo}
      />
    </CoreBox>
  );
}
