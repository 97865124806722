// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface StackOperation<T> {
    value: T;
    type: "add" | "remove";
  }
  
export class ArrayManagerSingleStack<T> {
  private stack: T[][] = [];
  private currentPointer = -1;
  private maxSize: number;
    
  constructor(initialArray: T[] = [], maxSize = 10) {
    this.stack.push([...initialArray]);
    this.currentPointer = 0;
    this.maxSize = maxSize;
  }
  
  // Add value to array
  add(value: T): void {
    // Get current state
    const currentState = [...this.getCurrentArray()];

    currentState.push(value);
      
    // Remove any states after current pointer
    this.stack.splice(this.currentPointer + 1);
      
    // Add new state
    this.stack.push(currentState);
    this.currentPointer++;
  
    // Maintain size limit
    if (this.stack.length > this.maxSize) {
      this.stack.shift();
      this.currentPointer--;
    }
  }
  
  // Undo last action
  undo(): boolean {
    if (this.currentPointer > 0) {
      this.currentPointer--;
      return true;
    }
    return false;
  }
  
  // Redo last undone action
  redo(): boolean {
    if (this.currentPointer < this.stack.length - 1) {
      this.currentPointer++;
      return true;
    }
    return false;
  }
  
  // Get current array
  getCurrentArray(): T[] {
    return [...this.stack[this.currentPointer]];
  }
  
  // Check if undo is available
  canUndo(): boolean {
    return this.currentPointer > 0;
  }
  
  // Check if redo is available
  canRedo(): boolean {
    return this.currentPointer < this.stack.length - 1;
  }
}
  