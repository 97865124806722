import { Dispatch } from "redux";

import { AppBuilderData } from "./AppBuilder.data";
import { DEFAULT_ICON } from "../../../constants/AppBuilder.constant";
import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import { EntityData } from "../entity/Entity.data";

/**
 * A concrete implementation of the `AppBuilderData` abstract class.
 * This class specializes in managing and preparing toolbox, viewer, toolbar data for a default builder.
 */
export class DefaultBuilderData<TSchema> extends AppBuilderData<TSchema> {
  /** Static property to store meta information related to the toolbox.  
   * This updated or accessed across instances of this class. */
  private static toolboxMeta = {};

  /** Static property to store name of viewers. */
  private static viewerMeta = [];

  /** Static property to store icons related to the toolbar. */
  private static toolbarMeta = [];

  /**  Constructor that initializes an instance of `DefaultBuilderData`
   *  with the provided entity data and pre-defined constants. 
   * 
   * @param entityData The dynamic entity data that is passed to the base class constructor. 
   * */
  constructor(
    builderId: string,
    storedToolbarData: any,
    storedToolboxData: any,
    storedEditorData: any,
    entityData: EntityData<TSchema>,
    dispatch: Dispatch
  ) {
    super(
      builderId,
      storedToolbarData,
      storedToolboxData,
      storedEditorData,
      entityData,
      AppBuilderFactory.BUILDER_TYPES.DEFAULT, 
      DEFAULT_ICON, 
      DefaultBuilderData.toolboxMeta, 
      DefaultBuilderData.viewerMeta, 
      DefaultBuilderData.toolbarMeta,
      dispatch
    );
  }
}