import { getFormikRequiredMessage } from "@wrappid/core";
import moment from "moment";
import { string, date, boolean } from "yup";

const dateValidation = date()
  .typeError("Invalid date")
  .test("isValidDate", "Invalid date", (value) => value && moment(value).isValid());

export const createAppointment = {
  date   : dateValidation.required(getFormikRequiredMessage("date")),
  endTime: string()
    .required(getFormikRequiredMessage("endTime"))
    .test(
      "end time test",
      "End time must be greater than Start time",
      (value, props) => {
        const { startTime } = props.parent;

        return moment(startTime, "HH:mm").isBefore(moment(value, "HH:mm"));
      }
    ),
  isForce  : boolean(),
  startTime: string().required(getFormikRequiredMessage("startTime")),
};
