import { ResourcesRegistry as AppBuilderResourcesRegistry } from "./app-builder/resources.registry";
import { ResourcesRegistry as AppointmentResourcesRegistry } from "./appointment/resources.registry";
import { ResourcesRegistry as AuthResourcesRegistry } from "./auth/resources.registry";
import { ResourcesRegistry as ClinicResourcesRegistry } from "./clinic/resources.registry";
import { ResourcesRegistry as CommunicationResourcesRegistry } from "./communication/resources.registry";
import { ResourcesRegistry as DashboardResourcesRegistry } from "./dashboard/resources.registry";
import { ResourcesRegistry as DataManagementResourcesRegistry } from "./data-management/resources.registry";
import { ResourcesRegistry as PatientResourcesRegistry } from "./patient/resources.registry";
import { ResourcesRegistry as PrescriptionResourcesRegistry } from "./prescription/resources.registry";
import { ResourcesRegistry as RxsettingsResourcesRegistry } from "./rxsettings/resources.registry";
import { ResourcesRegistry as SupportResourcesRegistry } from "./support/resources.registry";
import { ResourcesRegistry as UserManagementResourcesRegistry } from "./user-management/resources.registry";

export default {
...AppBuilderResourcesRegistry,
...AppointmentResourcesRegistry,
...AuthResourcesRegistry,
...ClinicResourcesRegistry,
...CommunicationResourcesRegistry,
...DashboardResourcesRegistry,
...DataManagementResourcesRegistry,
...PatientResourcesRegistry,
...PrescriptionResourcesRegistry,
...RxsettingsResourcesRegistry,
...SupportResourcesRegistry,
...UserManagementResourcesRegistry,
}