import { BUILDER_HISTORY, CURRENT_POINTER_INC, CURRENT_POINTER_DEC } from "../types/builder.types";

interface HistoryState {
  upperPointer:number,
  data:object[],
  currentPointer:number,
}
const initialState: HistoryState = { currentPointer: 0, data: [], upperPointer: 0 };

const historyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    //adding the history to the state
    case BUILDER_HISTORY: {
      if((state.upperPointer - 1) > state.currentPointer){
        state.data = state.data.slice(0, state.currentPointer + 1);
        state.currentPointer = state.upperPointer ;
      }
      return {
        ...state,

        currentPointer: state.currentPointer + 1,
        data          : [...state.data, action.payload],
        upperPointer  : state.currentPointer + 1
      };
    }

    //deleting history from the state
    case (CURRENT_POINTER_INC): {
      return {
        ...state,
        currentPointer: (state.currentPointer + 1) > state.upperPointer ? state.upperPointer : state.currentPointer + 1
      };
    }

    case (CURRENT_POINTER_DEC): {
      return {
        ...state,
        currentPointer: (state.currentPointer - 1) < 0 ? 0 : state.currentPointer - 1
      };
    }

    default:
      return state;
  }
};
  
export default historyReducer;