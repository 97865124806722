import React from "react";

import { CoreTypographyBody1 } from "@wrappid/core";

import Toolbar from "./Toolbar";
import ToolbarFactory, { ToolType } from "../../factory/Toolbar.factory";
import { BuilderEntitySchema } from "../../utils/UtilityFunction";
import { ToolbarData } from "../data/toolbar/Toolbar.data";

type ToolbarGroupType = { toolbarData: ToolbarData<BuilderEntitySchema>[] };

export default function ToolbarGroup(props: ToolbarGroupType) {
  const { toolbarData } = props;
  // const funcArray = {
  //   [ToolbarFactory.ACTION.SAVE]: (entityData: EntityData<BuilderEntitySchema>) => {
  //     // eslint-disable-next-line no-console
  //     console.log(entityData);
  //   },
  //   [ToolbarFactory.ACTION.HISTORY]: (entityData: EntityData<BuilderEntitySchema>) => {
  //     // eslint-disable-next-line no-console
  //     console.log(entityData);
  //   }
  // };

  // const selectBuilderData = useSelector((state: any) =>
  //   state.BuilderOptions?.appBuilderData.find(
  //     (builderData) =>
  //       builderData.selected === true
  //   )
  // );
  // const entityData = selectBuilderData?.entityData;
  
  // const funcArray: Record<string, (entityData: EntityData<BuilderEntitySchema>) => void> = {
  //   historyOnClick: (entityData) => {
  //     // eslint-disable-next-line no-console
  //     console.log("SAVE action triggered:", entityData);
  //   },
  //   requestForReviewOnClick: (entityData) => {
  //     // eslint-disable-next-line no-console
  //     console.log("REQUEST_FOR_REVIEW action triggered:", entityData);
  //   },
  //   saveOnClick: (entityData) => {
  //     // eslint-disable-next-line no-console
  //     console.log("HISTORY action triggered:", entityData);
  //     const id = entityData.getId();

  //     dispatch(
  //       apiRequestAction(
  //         HTTP.PUT,
  //         `/data/ModelSchemas/${id}`,
  //         true,
  //         entityData,
  //         SAVE_ENTITY_DATA_SUCCESS,
  //         SAVE_ENTITY_DATA_FAILURE
  //       ) as any
  //     );
  //   },
  // };

  return (
    <>
      {toolbarData && toolbarData.length > 0 ? (
        toolbarData.map((eachToolbarData, index) => (
          <Toolbar 
            key={index} 
            toolbarData={eachToolbarData} 
            actionFunction={(toolbarData: ToolbarData<BuilderEntitySchema>) => {
              const toolType: ToolType = ToolbarFactory.getToolPrototype(toolbarData.getToolbarType());

              Object.setPrototypeOf(toolbarData, toolType);
              toolbarData.performAction();
            }}
          />
        ))
      ) : (
        <CoreTypographyBody1 gutterBottom={false} paragraph={false}>
          No toolbar data available.
        </CoreTypographyBody1>
      )}
    </>
  );
}
