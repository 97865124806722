/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { CoreButton, CoreClasses } from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { updatePageJson } from "../../../actions/app.action";
import Navigator from "../header-components/Navigator";

export default function NavigatorView() {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.appBuilderReducer?.savedPageJson);
  const [currentData, setCurrentData] = useState(initialData);
  const [resetTrigger, setResetTrigger] = useState(0);

  useEffect(() => {
    if (initialData) {
      setCurrentData(initialData);
    }
  }, [initialData]);
 
  const handleDataChange = (newData) => {
    setCurrentData(newData);
    console.log(currentData, "Hola new data");
    dispatch(updatePageJson(newData));
  };

  const handleReset = () => {
    setResetTrigger(prev => prev + 1);
    // Reset to initial data
    if (initialData) {
      setCurrentData(initialData);
      dispatch(updatePageJson(initialData));
    }
  };

  return (
    <>
      <CoreButton 
        onClick={()=>handleReset}
        variant="contained"
        styleClasses={[CoreClasses.MARGIN.MB2]}>
        Reset
      </CoreButton>

      <Navigator
        initialData={currentData}
        onDataChange={()=>handleDataChange}
        resetKey={resetTrigger}
      />
    </>
  );
}