import React from "react";

import { CoreAlert, CoreJSONEditor } from "@wrappid/core";

export default function JsonEditor(props) {
  const { content, setContent, contentExtraInfo, setContentExtraInfo } = props;

  const handleContentSchemaChange = (newContent) => {
    setContent(newContent);
  };

  const handleContentExtraInfoChange = (newExtraInfo) => {
    setContentExtraInfo(newExtraInfo);    
  };

  return (
    <>
      <CoreAlert severity="error">Height of this JSON editor should be increased</CoreAlert>
      <CoreJSONEditor
        label="Schema"
        value={content}
        onChange={handleContentSchemaChange}/>
      <CoreJSONEditor
        label="ExtraInfo"
        value={contentExtraInfo}
        onChange={handleContentExtraInfoChange}/>
    </>
  );
}