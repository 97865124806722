import { PropertyType } from "../toolbox/property/PropertyType.interface";

export class PropertyTypeBuilder {
  private type: string;
  private defaultValue: string;
  private validValues: string[];

  constructor() {
    this.type = "";
    this.defaultValue = "";
    this.validValues = [];
  }

  setType(type: string): PropertyTypeBuilder {
    this.type = type;
    return this;
  }

  setDefault(defaultValue: string): PropertyTypeBuilder {
    this.defaultValue = defaultValue;
    return this;
  }

  setValidValues(validValues: string[]): PropertyTypeBuilder {
    this.validValues = validValues;
    return this;
  }

  build(): PropertyType {
    return {
      default    : this.defaultValue,
      type       : this.type,
      validValues: this.validValues
    };
  }
}