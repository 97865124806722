
import {
  CoreTypographyBody2,
  CoreTypographyCaption,
  CoreTextButton,
  CoreBox,
  CoreGrid,
  CoreStack,
  CoreClasses 
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

// -- import { getAge } from "@wrappid/core";
import { RESET_APPOINTMENT_PATIENT } from "../types/appointmentTypes";
import { getname } from "../utils/default.util";

export default function SearchPatient() {
  const dispatch = useDispatch();

  const tempPatient = useSelector((state) => state.appointment?.patient);

  let patient = tempPatient?.Person
    ? {
      "Person.dob"       : tempPatient?.Person?.dob,
      "Person.firstName" : tempPatient?.Person?.firstName,
      "Person.gender"    : tempPatient?.Person?.gender,
      "Person.lastName"  : tempPatient?.Person?.lastName,
      "Person.middleName": tempPatient?.Person?.middleName,
      "Person.profileId" : tempPatient?.Person?.profileId,
    }
    : tempPatient;

  return (
    <>
      <CoreGrid>
        <CoreStack gridProps={{ gridSize: { sm: 4, xs: 6 } }}>
          <CoreTypographyCaption>{"Name: "}</CoreTypographyCaption>

          <CoreTypographyBody2 component="span">
            {patient &&
              getname({
                firstName : patient["Person.firstName"],
                lastName  : patient["Person.lastName"],
                middleName: patient["Person.middleName"],
              })}
          </CoreTypographyBody2>
        </CoreStack>

        <CoreStack gridProps={{ gridSize: { sm: 2, xs: 6 } }}>
          <CoreTypographyCaption>{"MRN: "}</CoreTypographyCaption>

          <CoreTypographyBody2 component="span">
            {patient && patient["Person.profileId"]}
          </CoreTypographyBody2>
        </CoreStack>

        <CoreStack gridProps={{ gridSize: { sm: 1, xs: 6 } }}>
          <CoreTypographyCaption>{"Sex: "}</CoreTypographyCaption>

          <CoreTypographyBody2 component="span">
            {patient && patient["Person.gender"]}
          </CoreTypographyBody2>
        </CoreStack>

        <CoreStack gridProps={{ gridSize: { sm: 3, xs: 6 } }}>
          <CoreTypographyCaption>{"Age: "}</CoreTypographyCaption>

          {/* -- <CoreTypographyBody2 component="span">
            {patient && getAge(patient["Person.dob"])}&nbsp;(
            {moment(patient["Person.dob"]).format("DD/MM/YYYY")})
          </CoreTypographyBody2> */}
        </CoreStack>

        <CoreBox
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
          gridProps={{ gridSize: { sm: 2 } }}
        >
          <CoreTextButton
            label="Change Patient"
            styleClasses={[CoreClasses.PADDING.P0]}
            onClick={() => {
              dispatch({ type: RESET_APPOINTMENT_PATIENT });
            }}
          />
        </CoreBox>
      </CoreGrid>
    </>
  );
}
