import { Dispatch } from "redux";

import { BusinessBuilderData } from "../components/data/app-builder/BusinessBuilder.data";
import { DefaultBuilderData } from "../components/data/app-builder/DefaultBuilder.data";
import { FormBuilderData } from "../components/data/app-builder/FormBuilder.data";
import { ModelBuilderData } from "../components/data/app-builder/ModelBuilder.data";
import { PageBuilderData } from "../components/data/app-builder/PageBuilder.data";
import { RouteBuilderData } from "../components/data/app-builder/RouteBuilder.data";
import { ThemeBuilderData } from "../components/data/app-builder/ThemeBuilder.data";
import { EntityData } from "../components/data/entity/Entity.data";

export type AppBuilderType = typeof DefaultBuilderData.prototype | typeof PageBuilderData.prototype | typeof ModelBuilderData.prototype | typeof FormBuilderData.prototype | typeof RouteBuilderData.prototype | typeof BusinessBuilderData.prototype | typeof ThemeBuilderData.prototype
/**
 * AppBuilderFactory class provides methods to create different builder based on the builder type.
 * The factory pattern is used to abstract the instantiation of specific builder data.
 */
export default class AppBuilderFactory {
  /** 
   * A constant object holding various builder type names for different builders available in the Appbuilder.
   * These builder types correspond to specific builder actions within the appbuilder.
   */
  static BUILDER_TYPES = {
    BUSINESS: "Business",
    DEFAULT : "Default",
    FORM    : "Form",
    MODEL   : "Model",
    PAGE    : "Page",
    ROUTE   : "Route",
    THEME   : "Theme"
  } as const; 

  /**
   * A static method to return the appropriate `AppBuilderData` based on the builder type.
   * This method acts as a factory for creating instances of different builder data classes.
   *
   * @param builderType - The type of builder (e.g., 'Business', 'Default', etc.).
   * @param entityData - The dynamic entity data to be passed to the builder data class.
   * @returns An instance of the corresponding `AppBuilderData` subclass.
   * @throws Error if the builder type is not recognized.
   */
  // @todo any type declearation should be removed
  static getAppBuilderData<TSchema>(
    builderId: string, // Pass the builderId if available
    storedToolbarData: any,
    storedToolboxData: any,
    storedEditorData: any,
    builderType: string,
    entityData: EntityData<TSchema>,
    dispatch: Dispatch
  ) :AppBuilderType {

    // Check if storedToolbarData is provided; if not, pass undefined
    const toolbarData = storedToolbarData ? storedToolbarData : undefined;
    const toolboxData = storedToolboxData ? storedToolboxData : undefined;
    const editorData = storedEditorData ? storedEditorData : undefined;
  
    switch (builderType) {
      case AppBuilderFactory.BUILDER_TYPES.DEFAULT:
        return new DefaultBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of DefaultBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.PAGE:
        return new PageBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of PageBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.MODEL:
        return new ModelBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of ModelBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.FORM:
        return new FormBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of FormBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.ROUTE:
        return new RouteBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of RouteBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.BUSINESS:
        return new BusinessBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of BusinessBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.THEME:
        return new ThemeBuilderData(builderId, toolbarData, toolboxData, editorData, entityData, dispatch); // Returning an instance of ThemeBuilderData.

      // If the builder type doesn't match any of the cases, throw an error.
      default:
        throw new Error(`Unknown entity type: ${builderType}`);
    }
  }

  /**
   * A static method to return the appropriate `EntityModel` based on the builder type.
   * This method acts as a factory for creating instances of different entity model classes.
   *
   * @param builderType - The type of builder (e.g., 'Business', 'Default', etc.).
   * @returns An instance of the corresponding `EntityModel` subclass.
   * @throws Error if the builder type is not recognized.
   */
  static getEntityModel(builderType:string):string{
    switch (builderType) {
      
      case AppBuilderFactory.BUILDER_TYPES.PAGE:
        return "PageBuilderData"; // Returning an instance of PageBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.MODEL:
        return "ModelSchemas"; // Returning an instance of ModelBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.FORM:
        return "FormBuilderData"; // Returning an instance of FormBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.ROUTE:
        return "RouteBuilderData"; // Returning an instance of RouteBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.BUSINESS:
        return "BusinessBuilderData"; // Returning an instance of BusinessBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.THEME:
        return "ThemeBuilderData"; // Returning an instance of ThemeBuilderData.

      case AppBuilderFactory.BUILDER_TYPES.DEFAULT:
        return null; // Returning an instance of DefaultBuilderData.

      // If the builder type doesn't match any of the cases, throw an error.
      default:
        throw new Error(`Unknown entity type: ${builderType}`);
    }
  }
}