import { prescriptionDataStructure } from "../config/constants";
import {
  CLEAR_PRESCRIPTION,
  CLONE_PRESCRIPTION_TEMPLATE_ERROR,
  CLONE_PRESCRIPTION_TEMPLATE_SUCCESS,
  CREATE_PRESCRIPTION_ERROR,
  CREATE_PRESCRIPTION_SUCCESS,
  DOWNLOAD_PRESCRIPTION_ERROR,
  DOWNLOAD_PRESCRIPTION_LOADING,
  DOWNLOAD_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_TEMPLATES_ERROR,
  GET_PRESCRIPTION_TEMPLATES_LOADING,
  GET_PRESCRIPTION_TEMPLATES_SUCCESS,
  GET_TEMPLATE_DESIGNS_ERROR,
  GET_TEMPLATE_DESIGNS_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_PATIENT,
  RESET_PRESCRIPTION,
  RESET_PRESCRIPTION_FLAGS,
  RESET_PRESCRIPTION_REDUCER,
  SET_CLINIC,
  SET_PATIENT,
  SET_PRESCRIPTION_LANGUAGE,
  SET_PRESCRIPTION_REDUCER,
  UPDATE_PRESCRIPTION_TEMPLATES_ERROR,
  UPDATE_PRESCRIPTION_TEMPLATES_LOADING,
  UPDATE_PRESCRIPTION_TEMPLATES_SUCCESS
} from "../types/prescription.types";
import { convertClinicData, convertPatientData } from "../utils/conversion.utils";

const initState = {
  clearFlag                       : false,
  clearMedFlag                    : false,
  clinic                          : null,
  clonePrescriptionTemplateError  : false,
  clonePrescriptionTemplateId     : null,
  clonePrescriptionTemplateMessage: null,
  clonePrescriptionTemplateSuccess: false,
  downloadPrescriptionError       : false,
  downloadPrescriptionLoading     : false,
  downloadPrescriptionMsg         : "",
  downloadPrescriptionSuccess     : false,
  getPrescriptionError            : false,
  getPrescriptionSuccess          : false,
  language                        : null,

  navData                     : null,
  prescription                : prescriptionDataStructure,
  prescriptionPdf             : null,
  prescriptionTemplates       : null,
  prescriptionTemplatesError  : false,
  prescriptionTemplatesLoading: false,

  prescriptionTemplatesMsg         : "",
  prescriptionTemplatesSuccess     : false,
  templateDesigns                  : null,
  updatePrescriptionTemplateError  : false,
  updatePrescriptionTemplateLoading: false,
  updatePrescriptionTemplateMsg    : false,
  updatePrescriptionTemplateSuccess: false
};

const prescriptionReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_DESIGNS_SUCCESS:
      return {
        ...state,
        templateDesigns: action?.payload?.data
      };

    case GET_TEMPLATE_DESIGNS_ERROR:
      return {
        ...state,
        templateDesigns: []
      };

    case CREATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        prescription: action.payload?.data
      };

    case CREATE_PRESCRIPTION_ERROR:
      return {
        ...state,
        prescription: { ...state.prescription }
      };

    case SET_PATIENT:
      return {
        ...state,
        navData: {
          ...state.navData,
          Person: convertPatientData(action?.payload)
        }
      };

    case RESET_PATIENT:
      return {
        ...state,
        navData: null
      };

    case SET_CLINIC:
      return {
        ...state,
        clinic: action.payload
      };

    case CLEAR_PRESCRIPTION: {
      let clearOb = { ...(prescriptionDataStructure || {}) };

      return {
        ...state,
        clearFlag: true,
        clinic   : action.payload === state.prescription?.id
          ? null
          : state.clinic,

        navData: action.payload === state.prescription?.id
          ? null
          : state.navData,

        prescription: action.payload === state.prescription?.id
          ? clearOb
          : state.prescription
      };
    }

    case RESET_PRESCRIPTION:
      return {
        ...state,
        clinic      : null,
        navData     : null,
        prescription: prescriptionDataStructure
      };

    case GET_PRESCRIPTION_TEMPLATES_LOADING:
      return {
        ...state,
        prescriptionTemplates       : null,
        prescriptionTemplatesError  : false,
        prescriptionTemplatesLoading: true,
        prescriptionTemplatesMsg    : null,
        prescriptionTemplatesSuccess: false
      };

    case GET_PRESCRIPTION_TEMPLATES_SUCCESS:
      return {
        ...state,
        prescriptionTemplates       : action.payload?.data?.rows,
        prescriptionTemplatesError  : false,
        prescriptionTemplatesLoading: false,
        prescriptionTemplatesMsg    : action.payload?.message,
        prescriptionTemplatesSuccess: true
      };

    case GET_PRESCRIPTION_TEMPLATES_ERROR:
      return {
        ...state,
        prescriptionTemplates       : null,
        prescriptionTemplatesError  : true,
        prescriptionTemplatesLoading: false,
        prescriptionTemplatesMsg    : action.payload?.message,
        prescriptionTemplatesSuccess: false
      };

    case UPDATE_PRESCRIPTION_TEMPLATES_LOADING:
      return {
        ...state,
        updatePrescriptionTemplateError  : false,
        updatePrescriptionTemplateLoading: true,
        updatePrescriptionTemplateMsg    : null,
        updatePrescriptionTemplateSuccess: false
      };

    case UPDATE_PRESCRIPTION_TEMPLATES_SUCCESS:
      return {
        ...state,
        updatePrescriptionTemplateError  : false,
        updatePrescriptionTemplateLoading: false,
        updatePrescriptionTemplateMsg    : action.payload?.message,
        updatePrescriptionTemplateSuccess: true
      };

    case UPDATE_PRESCRIPTION_TEMPLATES_ERROR:
      return {
        ...state,
        updatePrescriptionTemplateError  : true,
        updatePrescriptionTemplateLoading: false,
        updatePrescriptionTemplateMsg    : action.payload?.message,
        updatePrescriptionTemplateSuccess: false
      };

    case RESET_PRESCRIPTION_FLAGS:
      return {
        ...state,
        downloadPrescriptionError  : false,
        downloadPrescriptionLoading: false,
        downloadPrescriptionMsg    : "",
        downloadPrescriptionSuccess: false,
      };

    case RESET_PRESCRIPTION_REDUCER:
      return {
        ...state,
        ...action.payload
      };

    case SET_PRESCRIPTION_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };

    case CLONE_PRESCRIPTION_TEMPLATE_SUCCESS:
      return {
        ...state,
        clonePrescriptionTemplateError  : false,
        clonePrescriptionTemplateId     : action.payload?.clonedId,
        clonePrescriptionTemplateMessage: action?.payload?.message,
        clonePrescriptionTemplateSuccess: true
      };

    case CLONE_PRESCRIPTION_TEMPLATE_ERROR:
      alert(
        action?.payload?.data?.message
          ? "Oops! " + action?.payload?.data?.message
          : "Oops! Error to clone prescription template"
      );
      return {
        ...state,
        clonePrescriptionTemplateError: true,
        clonePrescriptionTemplateId   : null,
        clonePrescriptionTemplateMessage:
                    action?.payload?.data?.message,
        clonePrescriptionTemplateSuccess: false
      };

    case SET_PRESCRIPTION_REDUCER:
      return { ...state, ...action.data };

    case GET_PRESCRIPTION_SUCCESS:
      // alert("HIE", JSON.stringify(action.payload));
      // eslint-disable-next-line no-console
      console.log("%%%%%%%%%%%%%%%%%%%%%");
      // eslint-disable-next-line no-console
      console.log("GET_PRESCRIPTION_SUCCESS", action.payload);
      // eslint-disable-next-line no-console
      console.log("%%%%%%%%%%%%%%%%%%%%%");

      // eslint-disable-next-line no-case-declarations
      let patient = action.payload?.data?.Patient
        ? convertPatientData(action.payload?.data)
        : action.payload?.data?.Appointment?.Patient
          ? convertPatientData(action.payload?.data?.Appointment)
          : state.navData;

      // eslint-disable-next-line no-case-declarations
      let Person = { ...action.payload?.data?.Patient?.User, ...patient };

      // eslint-disable-next-line no-case-declarations
      let Clinic = action.payload?.data?.Clinic
        ? convertClinicData(action.payload?.data?.Clinic)
        : action.payload?.data?.Appointment?.Clinic
          ? convertClinicData(action.payload?.data?.Appointment?.Clinic)
          : state.clinic;

      return {
        ...state,
        clinic                : Clinic,
        getPrescriptionError  : false,
        getPrescriptionSuccess: true,
        navData               : {
          ...state.navData,
          Person: Person,
        },
        prescription: action.payload?.data
          ? action.payload?.data
          : state.prescription,
        prescriptionClinic: action.payload?.data?.Clinic
          ? action.payload?.data?.Clinic
          : action.payload?.data?.Appointment?.Clinic
            ? action.payload?.data?.Appointment?.Clinic
            : state.prescriptionClinic
      };

    case DOWNLOAD_PRESCRIPTION_LOADING:
      return {
        ...state,
        downloadPrescriptionError  : false,
        downloadPrescriptionLoading: true,
        downloadPrescriptionMsg    : null,
        downloadPrescriptionSuccess: false,
        prescriptionPdf            : null
      };

    case DOWNLOAD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        downloadPrescriptionError  : false,
        downloadPrescriptionLoading: false,
        downloadPrescriptionMsg    : action.message,
        downloadPrescriptionSuccess: true,
        prescriptionPdf            : action.data
      };

    case DOWNLOAD_PRESCRIPTION_ERROR:
      return {
        ...state,
        downloadPrescriptionError  : true,
        downloadPrescriptionLoading: false,
        downloadPrescriptionMsg    : action.message,
        downloadPrescriptionSuccess: false,
        prescriptionPdf            : null
      };

    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};

export default prescriptionReducer;
