export const GET_TEMPLATE_DESIGNS_SUCCESS = "GET_TEMPLATE_DESIGNS_SUCCESS";
export const GET_TEMPLATE_DESIGNS_ERROR = "GET_TEMPLATE_DESIGNS_ERROR";

export const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
export const CREATE_PRESCRIPTION_ERROR = "CREATE_PRESCRIPTION_ERROR";

export const SET_PATIENT = "SET_PATIENT";
export const RESET_PATIENT = "RESET_PATIENT";
export const SET_CLINIC = "SET_CLINIC";

export const CLEAR_PRESCRIPTION = "CLEAR_PRESCRIPTION";

export const GET_PRESCRIPTION_TEMPLATES_LOADING =
    "GET_PRESCRIPTION_TEMPLATES_LOADING";
export const GET_PRESCRIPTION_TEMPLATES_SUCCESS =
    "GET_PRESCRIPTION_TEMPLATES_SUCCESS";
export const GET_PRESCRIPTION_TEMPLATES_ERROR =
    "GET_PRESCRIPTION_TEMPLATES_ERROR";

export const UPDATE_PRESCRIPTION_TEMPLATES_LOADING =
    "UPDATE_PRESCRIPTION_TEMPLATES_LOADING";
export const UPDATE_PRESCRIPTION_TEMPLATES_SUCCESS =
    "UPDATE_PRESCRIPTION_TEMPLATES_SUCCESS";
export const UPDATE_PRESCRIPTION_TEMPLATES_ERROR =
    "UPDATE_PRESCRIPTION_TEMPLATES_ERROR";

export const RESET_PRESCRIPTION_REDUCER = "RESET_PRESCRIPTION_REDUCER";
export const RESET_PRESCRIPTION_FLAGS = "RESET_PRESCRIPTION_FLAGS";

export const SET_PRESCRIPTION_LANGUAGE = "SET_PRESCRIPTION_LANGUAGE";

export const CLONE_PRESCRIPTION_TEMPLATE_SUCCESS =
    "CLONE_PRESCRIPTION_TEMPLATE_SUCCESS";
export const CLONE_PRESCRIPTION_TEMPLATE_ERROR =
    "CLONE_PRESCRIPTION_TEMPLATE_ERROR";

export const SET_PRESCRIPTION_REDUCER = "SET_PRESCRIPTION_REDUCER";

export const RESET_PRESCRIPTION = "RESET_PRESCRIPTION";

export const GET_PRESCRIPTION_SUCCESS = "GET_PRESCRIPTION_SUCCESS";
export const GET_PRESCRIPTION_ERROR = "GET_PRESCRIPTION_ERROR";

export const DOWNLOAD_PRESCRIPTION_LOADING = "DOWNLOAD_PRESCRIPTION_LOADING";
export const DOWNLOAD_PRESCRIPTION_SUCCESS = "DOWNLOAD_PRESCRIPTION_SUCCESS";
export const DOWNLOAD_PRESCRIPTION_ERROR = "DOWNLOAD_PRESCRIPTION_ERROR";

/**
 * @todo should be removed and should be used from core
 */
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const FORM_DATA_SAVE = "FORM_DATA_SAVE";
