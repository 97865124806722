/* eslint-disable etc/no-commented-out-code */
// Import React and createElement

import { getWrappidUUID } from "@wrappid/core/utils/appUtils";

import ToolBoxFactory from "../../../factory/ToolBox.factory";
/* eslint-disable etc/no-commented-out-code */

/** Abstract base class representing common properties and behavior for toolbox components. */
export abstract class ToolBoxData {
  readonly toolboxId: string;

  readonly toolboxType: typeof ToolBoxFactory.VARIANTS[keyof typeof ToolBoxFactory.VARIANTS];

  /** Title of the toolbox */
  protected toolboxTitle: string; 

  /** State indicating whether the toolbox is expanded or collapsed */
  protected toolboxExpand: boolean; 

  /** Property for resizing behavior, e.g., "none", "horizontal", "vertical" */
  protected toolboxResize: string;

  /** Icon for the "up arrow" action */
  protected upArrowIcon: string;

  /** Icon for the "down arrow" action */
  protected downArrowIcon: string;

  /** Icon for the "remove" action */
  protected removeIcon: string;

  /** Content of the toolbox */
  protected toolboxContent: any;

  protected renderedComponent: string;

  constructor(
    toolboxId: string,
    toolboxType: typeof ToolBoxFactory.VARIANTS[keyof typeof ToolBoxFactory.VARIANTS],
    toolboxTitle = "Unknown",
    toolboxExpand = false,
    toolboxResize = "none",
    upArrowIcon = "arrow_drop_up",
    downArrowIcon = "arrow_drop_down",
    removeIcon = "remove_circle_outline"
    
  ){
    this.toolboxId = toolboxId || getWrappidUUID();
    this.toolboxType = toolboxType;
    this.toolboxTitle = toolboxTitle;
    this.toolboxExpand = toolboxExpand;
    this.toolboxResize = toolboxResize;
    this.upArrowIcon = upArrowIcon;
    this.downArrowIcon = downArrowIcon;
    this.removeIcon = removeIcon;
  }

  getToolboxType(): typeof ToolBoxFactory.VARIANTS[keyof typeof ToolBoxFactory.VARIANTS] {
    return this.toolboxType;
  }

  getToolboxTitle(): string {
    return this.toolboxTitle;
  }

  getToolboxExpand(): boolean {
    return this.toolboxExpand;
  }

  getToolboxResize(): string {
    return this.toolboxResize;
  }

  getUpArrowIcon(): string {
    return this.upArrowIcon;
  }

  getDownArrowIcon(): string {
    return this.downArrowIcon;
  }

  getRemoveIcon(): string {
    return this.removeIcon;
  }

  getToolboxContent(): any {
    return this.toolboxContent;
  }

  getToolboxRenderedComponent():string{
    return this.renderedComponent;
  }

  setToolboxContent(content: any): void {
    this.toolboxContent = content;
  }

  setToolboxRenderedComponent(renderedComponent: string): void {
    this.renderedComponent = renderedComponent;
  }
}

