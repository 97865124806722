import XLargeappBuilderStyles from "../../modules/app-builder/styles/XLargeModuleStyles";
import XLargeappointmentStyles from "../../modules/appointment/styles/XLargeModuleStyles";
import XLargeauthStyles from "../../modules/auth/styles/XLargeModuleStyles";
import XLargeclinicStyles from "../../modules/clinic/styles/XLargeModuleStyles";
import XLargecommunicationStyles from "../../modules/communication/styles/XLargeModuleStyles";
import XLargedashboardStyles from "../../modules/dashboard/styles/XLargeModuleStyles";
import XLargedataManagementStyles from "../../modules/data-management/styles/XLargeModuleStyles";
import XLargepatientStyles from "../../modules/patient/styles/XLargeModuleStyles";
import XLargerxprofileStyles from "../../modules/rxprofile/styles/XLargeModuleStyles";
import XLargerxsettingsStyles from "../../modules/rxsettings/styles/XLargeModuleStyles";
import XLargesupportStyles from "../../modules/support/styles/XLargeModuleStyles";
import XLargeuserManagementStyles from "../../modules/user-management/styles/XLargeModuleStyles";

const XLargeModulesStyles = {
...new XLargeappBuilderStyles().style,
...new XLargeappointmentStyles().style,
...new XLargeauthStyles().style,
...new XLargeclinicStyles().style,
...new XLargecommunicationStyles().style,
...new XLargedashboardStyles().style,
...new XLargedataManagementStyles().style,
...new XLargepatientStyles().style,
...new XLargerxprofileStyles().style,
...new XLargerxsettingsStyles().style,
...new XLargesupportStyles().style,
...new XLargeuserManagementStyles().style,
};
 export default XLargeModulesStyles