export class EntityData<TSchema> {
  private id: number;
  private name: string;
  private desc: string | null;
  private system: string | null;
  private schema: TSchema;
  private extraInfo: any | null;
  private _status: string;
  private entityRef: string | null;
  private deletedAt: Date | null;
  private createdAt: Date;
  private updatedAt: Date;
  private commitId: string | null;
  private comments: string | null;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.desc = data.desc || null;
    this.system = data.system || null;
    this.schema = data.schema || {};
    this.extraInfo = data.extraInfo || null;
    this._status = data._status;
    this.entityRef = data.entityRef || null;
    this.deletedAt = data.deletedAt ? new Date(data.deletedAt) : null;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.commitId = data.commitId || null;
    this.comments = data.comments || null;
  }

  // Getters
  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getDesc(): string | null {
    return this.desc;
  }

  public getSystem(): string | null {
    return this.system;
  }

  public getSchema(): TSchema {
    return this.schema;
  }

  public getExtraInfo(): any | null {
    return this.extraInfo;
  }

  public getStatus(): string {
    return this._status;
  }

  public getEntityRef(): string | null {
    return this.entityRef;
  }

  public getDeletedAt(): Date | null {
    return this.deletedAt;
  }

  public getCreatedAt(): Date {
    return this.createdAt;
  }

  public getUpdatedAt(): Date {
    return this.updatedAt;
  }

  public getCommitId(): string | null {
    return this.commitId;
  }

  public getComments(): string | null {
    return this.comments;
  }

  // Setters
  //   public setId(id: number): void {
  //     this.id = id;
  //   }

  //   public setName(name: string): void {
  //     this.name = name;
  //   }

  //   public setDesc(desc: string | null): void {
  //     this.desc = desc;
  //   }

  //   public setSystem(system: string | null): void {
  //     this.system = system;
  //   }

  public setSchema(schema: TSchema): void {
    this.schema = schema;
  }

  public setExtraInfo(extraInfo: any | null): void {
    this.extraInfo = extraInfo;
  }

  //   public setStatus(status: string): void {
  //     this._status = status;
  //   }

  //   public setEntityRef(entityRef: string | null): void {
  //     this.entityRef = entityRef;
  //   }

  //   public setDeletedAt(deletedAt: Date | null): void {
  //     this.deletedAt = deletedAt;
  //   }

  //   public setCreatedAt(createdAt: Date): void {
  //     this.createdAt = createdAt;
  //   }

  //   public setUpdatedAt(updatedAt: Date): void {
  //     this.updatedAt = updatedAt;
  //   }

  //   public setCommitId(commitId: string | null): void {
  //     this.commitId = commitId;
  //   }

//   public setComments(comments: string | null): void {
//     this.comments = comments;
//   }
}