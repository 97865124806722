import { getFormikRequiredMessage } from "@wrappid/core";
import { string, mixed } from "yup";

const indianPhoneRegex = /^(?:\+91|91|0)?[6-9]\d{9}$/;

export const editClinic = {
  city      : string().required("city is required"),
  clinicLogo: mixed()
    .test("fileSize", "Logo size is too large, should be less than 200 KB", (value) => {
      if (!value) {
        return true; // Allow empty value (optional logo)
      }
      if(typeof value === "string"){
        return true;
      }
      return value.size <= 200 * 1024; // Check if file size is less than 200KB
    })
    .test("fileType", "Invalid logo format", (value) => {
      if (!value) {
        return true; // Allow empty value (optional logo)
      }
      if(typeof value === "string"){
        return true;
      }
      const supportedTypes = ["image/jpeg", "image/png"];

      return supportedTypes.includes(value.type);
    }),
  country : string().required("country is required"),
  district: string().required("district is required"),
  email   : string()
    .required("Email is required")
    .email("Invalid email format"),   
  fullName: 
    string()
      .trim()
      .required(getFormikRequiredMessage("clinic name"))
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphabets are allowed for this field "),   
  phone: string()
    .required("Phone number is required")
    .matches(indianPhoneRegex, "Invalid phone number format"),   
  pin  : string().required("pin is required"),
  state: string().required("state is required")   
};