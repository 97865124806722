/* eslint-disable etc/no-commented-out-code */
import React from "react";

import { CoreBox } from "@wrappid/core";

export default function BusinessEditor(props) {
  const { content } = props;

  return (
    <>
      <CoreBox component="pre">
        {JSON.stringify(content, null, 2)}
      </CoreBox>
    </>
    
  );
}
