import { default as appBuilderModuleClasses } from "../../modules/app-builder/styles/ModuleClasses";
import { default as appointmentModuleClasses } from "../../modules/appointment/styles/ModuleClasses";
import { default as authModuleClasses } from "../../modules/auth/styles/ModuleClasses";
import { default as clinicModuleClasses } from "../../modules/clinic/styles/ModuleClasses";
import { default as communicationModuleClasses } from "../../modules/communication/styles/ModuleClasses";
import { default as dashboardModuleClasses } from "../../modules/dashboard/styles/ModuleClasses";
import { default as dataManagementModuleClasses } from "../../modules/data-management/styles/ModuleClasses";
import { default as patientModuleClasses } from "../../modules/patient/styles/ModuleClasses";
import { default as prescriptionModuleClasses } from "../../modules/prescription/styles/ModuleClasses";
import { default as rxprofileModuleClasses } from "../../modules/rxprofile/styles/ModuleClasses";
import { default as rxsettingsModuleClasses } from "../../modules/rxsettings/styles/ModuleClasses";
import { default as supportModuleClasses } from "../../modules/support/styles/ModuleClasses";
import { default as userManagementModuleClasses } from "../../modules/user-management/styles/ModuleClasses";

const ModulesClasses = {
...appBuilderModuleClasses,
...appointmentModuleClasses,
...authModuleClasses,
...clinicModuleClasses,
...communicationModuleClasses,
...dashboardModuleClasses,
...dataManagementModuleClasses,
...patientModuleClasses,
...prescriptionModuleClasses,
...rxprofileModuleClasses,
...rxsettingsModuleClasses,
...supportModuleClasses,
...userManagementModuleClasses,
}
export default ModulesClasses;