import {
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreStack,
  CoreTextButton,
  CoreTypographyBody2,
  CoreTypographyCaption,
  getAge
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { RESET_APPOINTMENT_PATIENT } from "../types/appointmentTypes";

export default function PatientCard() {
  const dispatch = useDispatch();
  const patient = useSelector(
    (state) => state.appointment.patient || {}
  );

  return (
    <CoreGrid>
      <CoreStack gridProps={{ gridSize: { sm: 4 } }}>
        <CoreTypographyCaption>{"Name: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {[patient?.firstName, patient?.middleName, patient?.lastName].join(" ")}
        </CoreTypographyBody2>
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 2 } }}>
        <CoreTypographyCaption>{"MRN: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {patient?.profileId}
        </CoreTypographyBody2>
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 1 } }}>
        <CoreTypographyCaption>{"Sex: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {patient?.gender}
        </CoreTypographyBody2>
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 3 } }}>
        <CoreTypographyCaption>{"Age: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {getAge(patient?.dob)}&nbsp;
        </CoreTypographyBody2>
      </CoreStack>

      <CoreBox
        gridProps={{ gridSize: { sm: 2 } }}
        styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTextButton
          styleClasses={[CoreClasses.PADDING.P0]}
          label="Change Patient"
          onClick={() => {
            dispatch({ payload: {}, type: RESET_APPOINTMENT_PATIENT });
          }}
        />
      </CoreBox>
    </CoreGrid>
  );
}
