// eslint-disable-next-line import/no-unresolved

const ModuleClasses = {
  AUTH: {
    CONTENT    : "authContent",
    CONTENT_BOX: "authContentBox",
    FOOTER     : "authFooter"
  }
};

export default ModuleClasses;
