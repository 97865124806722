import {
  BlankLayout,
  CoreAccordion,
  CoreAccordionDetail,
  CoreAccordionSummary,
  CoreBox,
  CoreClasses,
  CoreDivider,
  CoreGrid,
  CoreH3,
  CoreIcon,
  CoreImage,
  CoreLayoutItem,
  CoreLink,
  CoreTypographyBody1,
  CoreTypographyBody2
} from "@wrappid/core";
import corePackage from "@wrappid/core/package.json";
// eslint-disable-next-line import/no-unresolved
import nativePackage from "@wrappid/native/package.json";
import stylesPackage from "@wrappid/styles/package.json";

import RenderAppModuleDetails from "./RenderAppModuleDetails";
import packageJson from "../../package.json";
import applicationLogo from "../resources/images/logo_dark.png";

export default function About() {
  const {
    name, version, description, repository, homepage, author, license, bugs, readme
  } = packageJson;

  const renderPackageDetails = (packageName, packageDetails) => {
    const {
      author, bugs, homepage, repository, version, description, license, name
    } = packageDetails;
  
    // Define an array of sections to render
    const sections = [
      { label: "Name", value: name ? name.replace("@", "") : "Name not found" },
      { label: "Version", value: version ? version : "Version not found" },
      { label: "Author", value: author?.name ? author?.name : "Author not found" },
      { label: "Description", value: description ? description : "Description not found" },
      { label: "Homepage", link: homepage, value: homepage ? homepage : "Homepage not found" },
      { label: "Repository", link: repository?.url?.replace("git+", ""), value: repository?.url ? repository?.url.replace("git+", "") : "Repository not found" },
      { label: "Bug report", link: bugs?.url, value: bugs?.url ? bugs?.url : "Bug report not found" },
      { label: "License", value: license ? license : "License not found" }
    ];
  
    return (
      <CoreAccordion key={packageName}>
        <CoreAccordionSummary expandIcon={<CoreIcon icon="keyboard_arrow_down" />}>
          <CoreTypographyBody1 paragraph={false}>{`${name ? name : "Name not found"} ${version ? version : "Version not available"}`}</CoreTypographyBody1>
        </CoreAccordionSummary>
  
        <CoreAccordionDetail >
          {sections.map((section, index) => (
            <CoreTypographyBody1 key={index} paragraph={false}>
              {section.label}:

              {section.link ? (
                <CoreLink href={section.link}>{` ${section.value}`}</CoreLink>
              ) : (
                ` ${section.value}`
              )}
            </CoreTypographyBody1>
          ))}
        </CoreAccordionDetail>
      </CoreAccordion>
    );
  };

  const serviceApi = undefined;
  const serviceModules = undefined;

  return (
    <>
      <CoreLayoutItem id={BlankLayout.PLACEHOLDER.CONTENT}>

        <CoreGrid styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_AROUND]}>
          <CoreBox gridProps={{ gridSize: { md: 8 } }} styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.TEXT.MD.TEXT_START]}>

            <CoreGrid>
              <CoreBox gridProps={{ gridSize: { md: 2 } }} styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_AROUND]}>
                <CoreImage
                  styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_XLARGE, CoreClasses.OBJECT_FIT.CONTAIN]}
                  src={applicationLogo}/>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { md: 10 } }}>
                <CoreH3 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.TEXT.MD.TEXT_START]} >{`${name ? name : "Name not found"}`}</CoreH3>

                <CoreTypographyBody1 hideSeeMore={false} limitChars={50} >{`${description ? description : "Description not found"}`}</CoreTypographyBody1>
              </CoreBox>
            </CoreGrid>

            <CoreDivider />

            <CoreBox>
              <CoreTypographyBody1>
                {`Versions: ${version ? version : "Version not found"}`}  &nbsp; | &nbsp;

                <CoreLink href={""}>Release Notes(coming soon)</CoreLink> &nbsp; | &nbsp;

                <CoreLink href={author?.url ? author?.url : ""}>Author</CoreLink> &nbsp; | &nbsp;

                <CoreLink href={homepage ? homepage : ""}>Homepage(coming soon)</CoreLink> &nbsp; | &nbsp;

                <CoreLink href={repository?.url ? repository?.url.replace("git+", "") : ""}>Repository</CoreLink> &nbsp; | &nbsp;

                <CoreLink href={bugs?.url ? bugs?.url : ""}>Bug report url(coming soon)</CoreLink> &nbsp; | &nbsp;

                <CoreLink href={readme ? readme : ""}>Read Me(coming soon)</CoreLink>
              </CoreTypographyBody1>
            </CoreBox>

            <CoreBox>
              <CoreTypographyBody2>
                {`License: ${license ? license : "License not found"}`},

            Copyright ©{new Date().getFullYear()}. All rights reserved.
              </CoreTypographyBody2>
            </CoreBox>

            <CoreDivider />

            <CoreBox>
              <CoreTypographyBody1>
                {`${name ? name : "Name not found"}`} is built using the

                <CoreLink href={"https://github.com/wrappid"}> wrappid</CoreLink> open source project.
              </CoreTypographyBody1>
            </CoreBox>

            <CoreDivider />

            <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses?.FLEX?.DIRECTION_COLUMN, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER]}>
              <CoreTypographyBody2 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MY2]} >
                Wrappid Packages:
              </CoreTypographyBody2>

              <CoreAccordionDetail styleClasses={[CoreClasses.PADDING.P0]}>

                {renderPackageDetails("core", corePackage)}

                {renderPackageDetails("native", nativePackage)}

                {renderPackageDetails("styles", stylesPackage)}
              </CoreAccordionDetail>

              {serviceApi && (
                <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MY2]}>
                  Service API version: {serviceApi}
                </CoreTypographyBody1>
              )}

              <CoreTypographyBody2 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MY2]}>
                App Modules:
              </CoreTypographyBody2>

              <CoreBox styleClasses={[CoreClasses.borderRounded1]}>
                <RenderAppModuleDetails />
              </CoreBox>

              {serviceModules && (
                <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MY2]}>
                  Service modules:
                </CoreTypographyBody1>
              )}

            </CoreBox>

            <CoreBox>
              <CoreTypographyBody2 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MY2]}>
              Technologies Used:
                <CoreTypographyBody1>
              React | React Native | Express.js (Node.js) | Postgres
                </CoreTypographyBody1>

              </CoreTypographyBody2>

              <CoreTypographyBody2 styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MY2]}>
              Contact Information:
                <CoreTypographyBody1>
              contact@rxefy.com | (+91) 7890777700
                </CoreTypographyBody1>
              </CoreTypographyBody2>
             
            </CoreBox>

          </CoreBox>
        </CoreGrid>
      </CoreLayoutItem>
    </>
  );
}