import AppBuilder from "./components/AppBuilder.tsx";
import BusinessEditor from "./components/editor/business/BusinessEditor.tsx";
import FormEditor from "./components/editor/form/FormEditor.tsx";
import JsonEditor from "./components/editor/json/JsonEditor.tsx";
import ModelEditor from "./components/editor/model/ModelEditor.tsx";
import ReduxStoreDataViewer from "./components/editor/model/ReduxStoreDataViewer.tsx";
import PageEditor from "./components/editor/page/PageEditor.tsx";
import RoutesEditor from "./components/editor/route/RouteEditor.tsx";
import ThemeEditor from "./components/editor/theme/ThemeEditor.tsx";
import HistoryChecker from "./components/HistoryChecker.tsx";
import BusinessEntityComp from "./components/NOT_USED_FOR_NOW/BusinessEntityComp.js";
import BusinessEntityManager from "./components/NOT_USED_FOR_NOW/BusinessEntityManager.js";
import DataCreator from "./components/NOT_USED_FOR_NOW/DataCreator.js";
import DataViewer from "./components/NOT_USED_FOR_NOW/DataViewer.js";
import FormBuilder from "./components/NOT_USED_FOR_NOW/FormBuilder.js";
import FormPreview from "./components/NOT_USED_FOR_NOW/FormPreview.js";
import FormsManager from "./components/NOT_USED_FOR_NOW/FormsManager.js";
import History from "./components/NOT_USED_FOR_NOW/History.js";
import ModelSchemaManager from "./components/NOT_USED_FOR_NOW/ModelSchemaManager.js";
import PagesManager from "./components/NOT_USED_FOR_NOW/PagesManager.js";
import RoutesManager from "./components/NOT_USED_FOR_NOW/RoutesManager.js";
import StatusChangeCommentHistory from "./components/NOT_USED_FOR_NOW/StatusChangeCommentHistory.js";
import StatusChangeForm from "./components/NOT_USED_FOR_NOW/StatusChangeForm.js";
import ThemeSchemaComponent from "./components/NOT_USED_FOR_NOW/ThemeSchemaComponent.js";
import ThemesManager from "./components/NOT_USED_FOR_NOW/ThemesManager.js";
import PageBuilder from "./components/NOT_USED_OLD_CODE_SAMPLE/page-builder/PageBuilder.js";
import ComponentToolBox from "./components/toolbox/ComponentToolBox.tsx";
import DeviceToolBox from "./components/toolbox/DeviceToolBox.tsx";
import LayoutToolBox from "./components/toolbox/LayoutToolBox.tsx";
import PropertyToolBox from "./components/toolbox/PropertyToolBox.tsx";

export const ComponentsRegistry = {
  AppBuilder                : { comp: AppBuilder },
  BusinessEditor            : { comp: BusinessEditor },
  BusinessEntityComp        : { comp: BusinessEntityComp },
  BusinessEntityManager     : { comp: BusinessEntityManager },
  ComponentToolBox          : { comp: ComponentToolBox },
  DataCreator               : { comp: DataCreator },
  DataViewer                : { comp: DataViewer },
  DeviceToolBox             : { comp: DeviceToolBox },
  FormBuilder               : { comp: FormBuilder },
  FormEditor                : { comp: FormEditor },
  FormPreview               : { comp: FormPreview },
  FormsManager              : { comp: FormsManager },
  History                   : { comp: History },
  HistoryChecker            : { comp: HistoryChecker },
  JsonEditor                : { comp: JsonEditor },
  LayoutToolBox             : { comp: LayoutToolBox },
  ModelEditor               : { comp: ModelEditor },
  ModelSchemaManager        : { comp: ModelSchemaManager },
  PageBuilder               : { comp: PageBuilder },
  PageEditor                : { comp: PageEditor },
  PagesManager              : { comp: PagesManager },
  PropertyToolBox           : { comp: PropertyToolBox },
  ReduxStoreDataViewer      : { comp: ReduxStoreDataViewer },
  RoutesEditor              : { comp: RoutesEditor },
  RoutesManager             : { comp: RoutesManager },
  StatusChangeCommentHistory: { comp: StatusChangeCommentHistory },
  StatusChangeForm          : { comp: StatusChangeForm },
  ThemeEditor               : { comp: ThemeEditor },
  ThemeSchemaComponent      : { comp: ThemeSchemaComponent },
  ThemesManager             : { comp: ThemesManager },
};
