import {
  CoreCardHeader,
  CoreClasses,
  CoreStack,
  CoreTypographyBody1,
  CoreTypographySubtitle2,
  viewFormattedDate
} from "@wrappid/core";

export default function ExperienceCard(props) {
  const { designation, organization, location, startDate, endDate } = props;

  return (
    <CoreCardHeader
      styleClasses={[CoreClasses.PADDING.P0]}  
      title={ 
        <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_SEMIBOLD, CoreClasses.MARGIN.MB0]}>
          {designation}
        </CoreTypographyBody1>
      }
      subheader={
        <CoreStack>
          <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.MB0]} >
            {organization}</CoreTypographySubtitle2>

          <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.MB0]}>
            {location}</CoreTypographySubtitle2>

          <CoreTypographySubtitle2 styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_MEDIUM, CoreClasses.MARGIN.MB3]} >
            {viewFormattedDate(startDate) + " - " + viewFormattedDate(endDate, "endDate")}
          </CoreTypographySubtitle2>
        </CoreStack>
      }
    />
  );
}
