
import {
    AppContainerLayout,
    CoreAvatar,
    CoreDataTable,
    CoreLayoutItem,
    CoreTypographyBody1,
    FORM_VIEW_MODE
} from "@wrappid/core";
import { useSelector } from "react-redux";

import { FORM_IDS } from "../constants/formIds.registry";
import SummaryComponent from "./ClinicSummaryComponent";
export default function ManageClinics() {
  const personID = useSelector((state) => state?.auth?.user?.personID);
  const createFormId = FORM_IDS.__CLINIC_FORM;
  const updateFormId = FORM_IDS.__CLINIC_FORM;
  const updateFormAPIMode = FORM_VIEW_MODE;

  const tableColumns = [
    {
      id    : "picture",
      render: CoreAvatar,
    },
    {
      id   : "fullName",
      label: "Name",
    },
    {
      id    : "address",
      label : "Address",
      render: (cellData, rowData) => {
        return (
          <CoreTypographyBody1>
            {rowData["addLine1"] +
              ", " +
              rowData["addLine2"] +
              ", " +
              rowData["city"] +
              ", " +
              rowData["district"] +
              ", " +
              rowData["state"] +
              ", " +
              rowData["country"] +
              ", " +
              "Pincode: " +
              rowData["pin"] +
              ", " +
              "Landmark: " +
              rowData["landmark"] +
              ", " +
              "Phone: " +
              rowData["phone"]}
          </CoreTypographyBody1>
        );
      },
    },
  ];
  
  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreDataTable
          entity={"ProfileClinic"}
          formMode={FORM_VIEW_MODE}
          updateFormAPIMode={updateFormAPIMode}
          createFormID={createFormId}
          updateFormID={updateFormId}
          // hideUpdateForm={false}
          createEntityButtonText={"My Clinic"}
          selectable={false}
          filterQuery={{ filter: { personId: personID } }}
          columns={tableColumns}
          summaryRendererComponent={SummaryComponent}
        />
      </CoreLayoutItem>
    </>
  );
}

