import { COMPONENT_TOOLBOX } from "../../../../constants/AppBuilder.constant";
import ToolBoxFactory from "../../../../factory/ToolBox.factory";
import { ToolBoxData } from "../ToolBox.data";

/**
 * Represents the component toolbox data.
 * Extends the base `ToolBoxData` class to provide default configurations for a component toolbox.
 */
export class ComponentToolBoxData extends ToolBoxData{
  constructor(
    toolboxId: string,
    toolboxTitle = "Component",
    toolboxExpand = true,
    toolboxResize = "both",
    renderedComponent = COMPONENT_TOOLBOX
  ){
    super(
      toolboxId,
      ToolBoxFactory.VARIANTS.COMPONENT,
      toolboxTitle,
      toolboxExpand,
      toolboxResize
    );
    this.setToolboxRenderedComponent(renderedComponent);

  }
}