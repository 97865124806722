import { useContext } from "react";

import { AppContainerLayout, CoreDataTable, CoreLayoutItem, CoreRouteRegistryContext } from "@wrappid/core";
import { useSelector } from "react-redux";

import PrescriptionSummaryComponent from "./PrescriptionSummaryComponent";

export default function MyPrescriptions() {
  const state = useSelector((state) => state);
  const { personID } = useSelector((state) => state.auth?.user);
  const routeRegistry = useContext(CoreRouteRegistryContext);
    
  // eslint-disable-next-line no-console
  console.log("STATE", state);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreDataTable
          entity="MyPrescriptions"
          filterQuery={{ filter: { doctorId: personID } }}
          summaryRendererComponent={PrescriptionSummaryComponent}
          hideCreateForm={true}
          hideUpdateForm={true}
          postRenderDetailsPaneComponent={PrescriptionSummaryComponent}
          navigationOnCreateUrl={"/" + routeRegistry.prescription}
        />
      </CoreLayoutItem>
    </>
  );
}
