import { coreOpenUrl, FORM_IDS, queryBuilder } from "@wrappid/core";

import { ApiRegistry } from "../apis.registry";
import {
  AUTHENTICATION_ERROR,
  CLEAR_PRESCRIPTION,
  DOWNLOAD_PRESCRIPTION_ERROR,
  DOWNLOAD_PRESCRIPTION_LOADING,
  DOWNLOAD_PRESCRIPTION_SUCCESS,
  FORM_DATA_SAVE,
  GET_TEMPLATE_DESIGNS_ERROR,
  GET_TEMPLATE_DESIGNS_SUCCESS,
  SET_CLINIC,
  SET_PRESCRIPTION_LANGUAGE,
  SET_PRESCRIPTION_REDUCER
} from "../types/prescription.types";

export const clearPrescription = (id) => {
  return (dispatch) => {
    dispatch({ payload: id, type: CLEAR_PRESCRIPTION });
  };
};

export const getTemplateDesigns = (backendUrl, token) => {
  return (dispatch) => {
    // -- dispatch({ type: GET_TEMPL });
    let newUrl = ApiRegistry.GET_PRESCRIPTION_TEMPLATE_DESIGNS_API;

    fetch(backendUrl + newUrl, {
      headers: {
        Accept        : "application/json",
        Authorization : `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
      .then((res) =>
        res.json().then((data) => {
          if (res.status === 200) {
            dispatch({
              message: data.message,
              payload: data,
              type   : GET_TEMPLATE_DESIGNS_SUCCESS
            });
          } else if (res.status === 403 || res.status === 401) {
            dispatch({ type: AUTHENTICATION_ERROR });
          } else {
            dispatch({
              message: data.message,
              type   : GET_TEMPLATE_DESIGNS_ERROR
            });
          }
        })
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);

        dispatch({
          message: "Internal Error",
          type   : GET_TEMPLATE_DESIGNS_ERROR
        });
      });
  };
};

export const setClinic = (data) => {
  return (dispatch) => {
    dispatch({ payload: data, type: SET_CLINIC });
    dispatch({
      payload: {
        apiDetails: null,
        data      : {
          addLine1  : data?.addLine1,
          addLine2  : data?.addLine2,
          city      : data?.city,
          clinicLogo: data?.Clinic?.photoUrl,
          country   : data?.country,
          district  : data?.district,
          fullName  : data?.fullName,
          phone     : data?.phone,
          pin       : data?.pin,
          state     : data?.state
        },
        id: FORM_IDS.__PRESCRIPTION_CLINIC
      },
      type: FORM_DATA_SAVE
    });
  };
};

export const setLanguage = (data) => {
  return (dispatch) => {
    dispatch({ payload: data, type: SET_PRESCRIPTION_LANGUAGE });
  };
};

export const setPrescriptionReducer = (data) => {
  return (dispatch) => {
    dispatch({ data: data, type: SET_PRESCRIPTION_REDUCER });
  };
};

export const downloadPrescription = (backendUrl, query, id, token) => {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_PRESCRIPTION_LOADING });
    let newUrl = queryBuilder(
      ApiRegistry.PRESCRIPTION_PDF_API.replace(":id", id),
      query
    );

    fetch(backendUrl + newUrl, {
      headers: {
        Authorization        : `Bearer ${token}`,
        "Content-Type"       : "application/json",
        "Content-disposition": "attachment; filename=prescription.pdf"
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const data = window.URL.createObjectURL(blob);

              if (query && query.download) {
                let link = document.createElement("a");

                link.href = data;
                link.download = "prescription.pdf";
                link.click();
                setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(data);
                }, 100);
              }
              dispatch({
                data   : data,
                message: "Prescription dowloaded successfully.",
                type   : DOWNLOAD_PRESCRIPTION_SUCCESS
              });
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error("ERROR IN BLOB", err);
              dispatch({
                message: "ERROR IN BLOB",
                type   : DOWNLOAD_PRESCRIPTION_SUCCESS
              });
            });
        } else {
          response
            .json()
            .then((data) => {
              if (response.status === 500) {
                dispatch({
                  message: data.message,
                  type   : DOWNLOAD_PRESCRIPTION_ERROR
                });
              } else if (response.status === 201) {
                if (data?.data) {
                  try {
                    coreOpenUrl(data?.data);
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log("errror in link open");
                  }
                }
                dispatch({
                  data   : data,
                  message: data?.message,
                  type   : DOWNLOAD_PRESCRIPTION_SUCCESS
                });
              } else if (response.status === 403) {
                dispatch({ type: AUTHENTICATION_ERROR });
              } else {
                dispatch({
                  message: "No prescription found",
                  type   : DOWNLOAD_PRESCRIPTION_ERROR
                });
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);

              dispatch({
                message: "No prescription found",
                type   : DOWNLOAD_PRESCRIPTION_ERROR
              });
            });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);

        dispatch({
          message: "internal Error",
          type   : DOWNLOAD_PRESCRIPTION_ERROR
        });
      });
  };
};
