
import {
  CoreAvatar,
  CoreBox,
  CoreCard,
  CoreCardActions,
  CoreCardHeader,
  CoreClasses,
  CoreEmailLink,
  CoreLink,
  CorePhoneLink,
  CoreStack,
  CoreTextButton,
  CoreTypographyCaption,
  coreUseNavigate,
  getFullName
} from "@wrappid/core";

import { APP_ROLES } from "../constants/constants";
import { RoutesRegistry } from "../routes.registry";

export default function SearchUserCard(props) {
  const navigate = coreUseNavigate();
  const { data, optionProps, optionCompProps } = props;
  const { email, phone } = data;
  const createRelative = (data) => {
    navigate(
      "/" + RoutesRegistry.CREATE_PATIENT_RELATIVE.replace(":id", data?.Person?.id),
      { state: data }
    );
  };

  return (
    <CoreBox {...optionProps}>
      {data.inputValue ? (
        <CoreBox>
          <CoreTypographyCaption>
            {"Create new " + (optionCompProps?.role || "user")}

            {" with "}

            <CoreLink>{data?.inputValue}</CoreLink>
          </CoreTypographyCaption>
        </CoreBox>
      ) : (
        <>
          <CoreCard styleClasses={[CoreClasses.PADDING.P0]}>
            <CoreCardHeader
              avatar={
                <CoreAvatar
                  styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_MEDIUM]}
                  src={data?.photoUrl}
                />
              }
              // -- action={}
              title={
                <CoreStack
                  // styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]}
                  direction="row"
                  spacing={1}
                >
                  <CoreTypographyCaption>
                    {data.Person && getFullName(data.Person)}
                  </CoreTypographyCaption>

                  {optionCompProps?.role === APP_ROLES.PATIENT &&
                    data.Relation && (
                    <CoreTypographyCaption
                      styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY]}
                    >
                      {`${data?.Relation?.name} of `}

                      <CoreTypographyCaption
                        styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY]}
                      >
                        {getFullName(data?.RootPerson)}
                      </CoreTypographyCaption>
                    </CoreTypographyCaption>
                  )}
                </CoreStack>
              }
              subheader={
                <>
                  <CoreStack direction="row" spacing={1}>
                    {email && <CoreEmailLink email={email} size="small" />}

                    <CoreTypographyCaption>
                      {email && phone && "|"}
                    </CoreTypographyCaption>

                    {/* -- <CoreDivider flexItem={true} orientation="vertical" /> */}
                    {phone && <CorePhoneLink phone={phone} size="small" />}
                  </CoreStack>
                </>
              }
            />

            <CoreCardActions
              styleClasses={[CoreClasses.PADDING.PY0, CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
            >
              {optionCompProps?.role === APP_ROLES.PATIENT &&
                !data?.Relation && (
                <CoreTextButton
                  onClick={() => {
                    createRelative(data);
                  }}
                  label="Create Relative"
                />
              )}
            </CoreCardActions>
          </CoreCard>
        </>
      )}
    </CoreBox>
  );
}
