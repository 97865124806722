import { ComponentsRegistry as AppBuilderComponentsRegistry } from "./app-builder/components.registry";
import { ComponentsRegistry as AppointmentComponentsRegistry } from "./appointment/components.registry";
import { ComponentsRegistry as AuthComponentsRegistry } from "./auth/components.registry";
import { ComponentsRegistry as ClinicComponentsRegistry } from "./clinic/components.registry";
import { ComponentsRegistry as CommunicationComponentsRegistry } from "./communication/components.registry";
import { ComponentsRegistry as DashboardComponentsRegistry } from "./dashboard/components.registry";
import { ComponentsRegistry as DataManagementComponentsRegistry } from "./data-management/components.registry";
import { ComponentsRegistry as PatientComponentsRegistry } from "./patient/components.registry";
import { ComponentsRegistry as RxprofileComponentsRegistry } from "./rxprofile/components.registry";
import { ComponentsRegistry as RxsettingsComponentsRegistry } from "./rxsettings/components.registry";
import { ComponentsRegistry as SupportComponentsRegistry } from "./support/components.registry";
import { ComponentsRegistry as UserManagementComponentsRegistry } from "./user-management/components.registry";

export default {
...AppBuilderComponentsRegistry,
...AppointmentComponentsRegistry,
...AuthComponentsRegistry,
...ClinicComponentsRegistry,
...CommunicationComponentsRegistry,
...DashboardComponentsRegistry,
...DataManagementComponentsRegistry,
...PatientComponentsRegistry,
...RxprofileComponentsRegistry,
...RxsettingsComponentsRegistry,
...SupportComponentsRegistry,
...UserManagementComponentsRegistry,
}