import LargeappBuilderStyles from "../../modules/app-builder/styles/LargeModuleStyles";
import LargeappointmentStyles from "../../modules/appointment/styles/LargeModuleStyles";
import LargeauthStyles from "../../modules/auth/styles/LargeModuleStyles";
import LargeclinicStyles from "../../modules/clinic/styles/LargeModuleStyles";
import LargecommunicationStyles from "../../modules/communication/styles/LargeModuleStyles";
import LargedashboardStyles from "../../modules/dashboard/styles/LargeModuleStyles";
import LargedataManagementStyles from "../../modules/data-management/styles/LargeModuleStyles";
import LargepatientStyles from "../../modules/patient/styles/LargeModuleStyles";
import LargeprescriptionStyles from "../../modules/prescription/styles/LargeModuleStyles";
import LargerxprofileStyles from "../../modules/rxprofile/styles/LargeModuleStyles";
import LargerxsettingsStyles from "../../modules/rxsettings/styles/LargeModuleStyles";
import LargesupportStyles from "../../modules/support/styles/LargeModuleStyles";
import LargeuserManagementStyles from "../../modules/user-management/styles/LargeModuleStyles";

const LargeModulesStyles = {
...new LargeappBuilderStyles().style,
...new LargeappointmentStyles().style,
...new LargeauthStyles().style,
...new LargeclinicStyles().style,
...new LargecommunicationStyles().style,
...new LargedashboardStyles().style,
...new LargedataManagementStyles().style,
...new LargepatientStyles().style,
...new LargeprescriptionStyles().style,
...new LargerxprofileStyles().style,
...new LargerxsettingsStyles().style,
...new LargesupportStyles().style,
...new LargeuserManagementStyles().style,
};
 export default LargeModulesStyles