import React, { useState } from "react";

import { CoreAlert, CoreBox, CoreSnackbar } from "@wrappid/core";

/** Create a global variable to store the show notification function */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let showGlobalNotification = (message: string, severity: string) => {
  // eslint-disable-next-line no-console
  console.warn("Snackbar not initialized yet");
};

/** Export the notifyUsingSnackbar function to be used in other files (like reducers) */
export const notifyUsingSnackbar = (message: string, severity = "success") => {
  showGlobalNotification(message, severity);
};

export default function GlobalSnackbar() { 
  /** State for snack message */
  const [snackAlertOpen, setSnackAlertOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");
    
  /* Initialize the global show notification(snack message) function */
  showGlobalNotification = (message: string, severity = "success") => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackAlertOpen(true);
  };
    
  /** Function for handle snack message */
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackAlertOpen(false);
  };

  return (
    <CoreSnackbar 
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }} 
      open={snackAlertOpen} 
      autoHideDuration={6000} 
      onClose={handleClose}
    >
      <CoreBox>
        <CoreAlert
          onClose={handleClose}
          severity={snackSeverity}
          variant="filled"
          width="100%"
        >
          {snackMessage}
        </CoreAlert>
      </CoreBox>
    </CoreSnackbar>
  );
}