import { useContext, useEffect, useState } from "react";

import {
  CoreBox,
  CoreButton,
  CoreClasses,
  CoreDialogContext,
  CoreGrid,
  CoreH6,
  CoreIcon,
  CoreIconButton,
  CoreSelect,
  CoreStack,
  CoreToolBox,
  CoreTooltip,
  CoreTypographyBody1
} from "@wrappid/core";
import { useSelector, useDispatch } from "react-redux";

import ComponentSelector from "./ComponentSelector";
import EventsSelector from "./EventsSelector";
import LayoutSelector from "./LayoutSelector";
import NavigatorView from "./NavigatorView";
import PropsSelector from "./PropsSelector";
import {
  reorderToolbox,
  toggleToolboxOpen,
  toolboxCollapseExpand
} from "../../../actions/app.action";

// ToolBoxManager component defined outside to prevent recreation on each render
const ToolBoxManager = ({ toolboxes, toolboxesState, dispatch }) => {
  // Get sorted toolboxes based on order
  const sortedToolboxes = [...toolboxes].sort((boxA, boxB) => 
    (toolboxesState[boxA.id]?.order ?? 0) - (toolboxesState[boxB.id]?.order ?? 0)
  );

  // Get visible toolboxes
  const visibleToolboxes = sortedToolboxes.filter(
    toolbox => toolboxesState[toolbox.id]?.isOpenToolBox ?? true
  );

  const handleVisibilityToggle = (toolboxId, currentState) => {
    dispatch(toggleToolboxOpen(toolboxId, !currentState));
  };

  const handleReorder = (toolboxId, direction) => {
    dispatch(reorderToolbox(toolboxId, direction));
  };

  return (
    <CoreBox styleClasses={[CoreClasses.PADDING.P2]}>
      {sortedToolboxes.map((toolbox) => {
        const isOpenToolBox = toolboxesState[toolbox.id]?.isOpenToolBox ?? true;
        const visibleIndex = visibleToolboxes.findIndex(tBox => tBox.id === toolbox.id);
        const isFirst = visibleIndex === 0;
        const isLast = visibleIndex === visibleToolboxes.length - 1;
        
        return (
          <CoreBox 
            key={toolbox.id} 
            styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_START, CoreClasses.MARGIN.MY2]}
          >
            <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.GAP.GAP_2]}>
              <CoreIconButton
                onClick={() => handleVisibilityToggle(toolbox.id, isOpenToolBox)}
              >
                <CoreIcon 
                  icon={isOpenToolBox ? "visibility" : "visibility_off"}
                  color={isOpenToolBox ? "primary" : "default"}
                />
              </CoreIconButton>

              {!isFirst && (
                <CoreIconButton
                  onClick={() => handleReorder(toolbox.id, "up")}
                >
                  <CoreIcon icon="keyboard_double_arrow_up" />
                </CoreIconButton>
              )}

              {!isLast && (
                <CoreIconButton
                  onClick={() => handleReorder(toolbox.id, "down")}
                >
                  <CoreIcon icon="keyboard_double_arrow_down" />
                </CoreIconButton>
              )}
            </CoreBox>

            <CoreH6 styleClasses={[CoreClasses.MARGIN.ML2]}>{toolbox.toolTitle}</CoreH6>
          </CoreBox>
        );
      })}
    </CoreBox>
  );
};

export default function RightDrawerComp() {
  const dispatch = useDispatch();
  const toolBoxExpand = useSelector((state) => state.appBuilderReducer?.isExpandToolBox);
  const toolboxesState = useSelector((state) => 
    state.appBuilderReducer?.toolboxes || {
      1: { isOpenToolBox: true, order: 0 },
      2: { isOpenToolBox: true, order: 1 },
      3: { isOpenToolBox: true, order: 2 },
      4: { isOpenToolBox: true, order: 3 },
      5: { isOpenToolBox: true, order: 4 },
      6: { isOpenToolBox: true, order: 5 },
      7: { isOpenToolBox: true, order: 6 }
    }
  );

  const { setDialog } = useContext(CoreDialogContext);
  const [toolBoxController, setToolBoxController] = useState(false);

  const toolboxes = [
    {
      content  : <LayoutSelector />,
      id       : 1,
      resize   : "horizontal",
      toolTitle: "Select Layout",
    },
    {
      content  : <ComponentSelector />,
      id       : 2,
      resize   : "both",
      toolTitle: "Select any Component",
    },
    {
      content  : <PropsSelector />,
      id       : 3,
      resize   : "both",
      toolTitle: "Select Props for Component",
    },
    {
      content  : <CoreTypographyBody1>Theme</CoreTypographyBody1>,
      id       : 4,
      resize   : "both",
      toolTitle: "Select Theme",
    },
    {
      content  : <EventsSelector />,
      id       : 5,
      resize   : "both",
      toolTitle: "Select Event",
    },
    {
      content: (
        <CoreGrid>
          <CoreSelect
            gridProps={{ gridSize: { md: 12 } }}
            label="Device"
            selectID="currentDevice"
            options={[{ id: "", label: "Laptop" }, { id: "10", label: "Tab" }, { id: "20", label: "Android" }, { id: "30", label: "iPhone" }]}
          />

          <CoreSelect
            gridProps={{ gridSize: { md: 8, xs: 4 } }}
            label="Zoom"
            selectID="currentZoom"
            options={[{ id: "", label: "25%" }, { id: "10", label: "50%" }, { id: "20", label: "75%" }, { id: "30", label: "100%" }]}
          />

          <CoreBox
            gridProps={{
              gridSize    : { md: 4, xs: 4 },
              styleClasses: [CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]
            }}
          >
            <CoreIconButton>
              <CoreIcon>screen_rotation</CoreIcon>
            </CoreIconButton>

            <CoreIconButton title="Device posture">
              <CoreIcon>devices_fold</CoreIcon>
            </CoreIconButton>
          </CoreBox>
        </CoreGrid>
      ),
      id       : 6,
      resize   : "both",
      toolTitle: "Select Device",
    },
    {
      content  : <NavigatorView />,
      id       : 7,
      resize   : "both",
      toolTitle: "Navigator",
    },
  ];

  useEffect(() => {
    if (toolBoxController) {
      setDialog({
        doneButton: () => {
          setToolBoxController(false);
          setDialog(null); 
        },
        doneButtonLabel: "Close",
        noCancelButton : true,
        noDoneButton   : false,
        showDialog     : true,
        subtitle       : <ToolBoxManager toolboxes={toolboxes} toolboxesState={toolboxesState} dispatch={dispatch} />,
        title          : "Manage your Toolbox",
        type           : "info"
      });

      // Reset controller state after dialog opens
      const timer = setTimeout(() => {
        setToolBoxController(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [setDialog, toolboxes, toolboxesState, dispatch]);

  // Sort toolboxes based on their order in the state
  const sortedToolboxes = [...toolboxes].sort((boxA, boxB) =>
    (toolboxesState[boxA.id]?.order ?? 0) - (toolboxesState[boxB.id]?.order ?? 0)
  );

  // Get only visible toolboxes
  const visibleToolboxes = sortedToolboxes.filter(
    toolbox => toolboxesState[toolbox.id]?.isOpenToolBox ?? true
  );

  return (
    <CoreStack
      styleClasses={[CoreClasses.HEIGHT.VH_92, CoreClasses.OVERFLOW.OVERFLOW_Y_SCROLL, CoreClasses.BORDER.BORDER_START, CoreClasses.BORDER.BORDER_COLOR_GREY_400]}
    >
      <CoreBox
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.PADDING.P1]}
      >
        <CoreBox
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.GAP.GAP_3]}
        >
          <CoreTooltip title={!toolBoxExpand ? "" : "Collapse All Selector"} arrow>
            <CoreButton
              label="Collapse"
              variant="text"
              disabled={!toolBoxExpand}
              onClick={() => dispatch(toolboxCollapseExpand(false))}
            />
          </CoreTooltip>

          <CoreTooltip title={toolBoxExpand ? "" : "Expand All Selector"} arrow>
            <CoreButton
              label="Expand"
              disabled={toolBoxExpand}
              variant="text"
              onClick={() => dispatch(toolboxCollapseExpand(true))}
            />
          </CoreTooltip>
        </CoreBox>

        <CoreTooltip title="Select Selector Box" arrow>
          <CoreIconButton onClick={() => setToolBoxController(true)}>
            <CoreIcon icon="more_vert" />
          </CoreIconButton>
        </CoreTooltip>
      </CoreBox>

      {visibleToolboxes.map((toolbox) => {
        const isFirst = visibleToolboxes[0].id === toolbox.id;
        const isLast = visibleToolboxes[visibleToolboxes.length - 1].id === toolbox.id;
        const buttons = [];

        if (!isFirst) buttons.push("keyboard_double_arrow_up");
        if (!isLast) buttons.push("keyboard_double_arrow_down");
        buttons.push("remove_circle");

        return (
          <CoreToolBox
            key={toolbox.id}
            toolTitle={toolbox.toolTitle}
            resize={toolbox.resize}
            expandProp={toolBoxExpand}
            toolboxActionButton={buttons.map((buttonIcon, buttonIndex) => (
              <CoreIconButton
                key={buttonIndex}
                onClick={() => {
                  if (buttonIcon === "remove_circle") {
                    dispatch(toggleToolboxOpen(toolbox.id, false));
                  } else if (buttonIcon === "keyboard_double_arrow_up") {
                    dispatch(reorderToolbox(toolbox.id, "up"));
                  } else if (buttonIcon === "keyboard_double_arrow_down") {
                    dispatch(reorderToolbox(toolbox.id, "down"));
                  }
                }}
              >
                <CoreIcon
                  icon={buttonIcon}
                  color={buttonIcon === "remove_circle" ? "primary" : "inherit"}
                />
              </CoreIconButton>
            ))}
          >
            {toolbox.content}
          </CoreToolBox>
        );
      })}
    </CoreStack>
  );
}