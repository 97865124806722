import { ToolBoxData } from "./ToolBox.data";
import { DEVICE_TOOLBOX } from "../../../constants/AppBuilder.constant";
import ToolBoxFactory from "../../../factory/ToolBox.factory";

/**
 * Represents the device toolbox data.
 * Extends the base `ToolBoxData` class to provide default configurations for a device toolbox.
 */
export class DeviceToolBoxData extends ToolBoxData{
  
  constructor(
    toolboxId: string,
    toolboxTitle = "Device",
    renderedComponent = DEVICE_TOOLBOX
  ){
    super(
      toolboxId,
      ToolBoxFactory.VARIANTS.DEVICE,
      toolboxTitle
    );
    this.setToolboxRenderedComponent(renderedComponent);

  }

}