import {
    __EntityStatus,
    CoreDataTable
} from "@wrappid/core";
import { useSelector } from "react-redux";

import AppointmentDetails from "./AppointmentDetails";
import AppointmentSummaryComponent from "./AppointmentSummaryComponent";

// -- import StatusText from "@wrappid/core";

export default function CompletedAppointment() {
  const personID = useSelector((state) => state?.auth?.user?.personID);

  return (
    <CoreDataTable
      entity="CompletedAppointments"
      enableCreateEntity={false}
      hideForm={true}
      filterQuery={{
        filter: {
          _status : __EntityStatus.COMPLETED,
          doctorId: personID,
        },
      }}
      noHeaderInApp={true}
      summaryRendererComponent={AppointmentSummaryComponent}
      preRenderDetailsPaneComponent={AppointmentDetails}
    />
  );
}
