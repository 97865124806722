import { useEffect, useState } from "react";

import {
  CoreBox,
  CoreButton,
  CoreClasses,
  CoreIcon,
  CoreIconButton,
  CoreStack,
  CoreTooltip,
  CoreToolBox,
  CoreH6
} from "@wrappid/core";

export class AppBuilderMaker {
  __VISIBILITY = "visibility";
  __VISIBILITY_OFF = "visibility_off";
  __PRIMARY = "primary";
  __DEFAULT = "default";
  __INHERIT = "inherit";
  __UP = "up";
  __DOWN = "down";
  __ICON_ARROW_UP = "keyboard_double_arrow_up";
  __ICON_ARROW_DOWN = "keyboard_double_arrow_down";
  __ICON_MORE_VERT = "more_vert";
  __ICON_REMOVE_CIRCLE = "remove_circle";
  __CLOSE = "Close";
  __MESSAGE_SHOW_TOOLBOX = "Manage your Toolbox";
  __INFO = "info";
  __EMPTY_STRING = "";
  __COLLAPSE_MESSAGE = "Collapse All Selector";
  __EXPAND_MESSAGE = "Expand All Selector";
  __COLLAPSE = "Collapse";
  __EXPAND = "Expand";
  __VARIANT_TEXT = "text";
  __SELECT_SELECTOR_BOX = "Select Selector Box";
  
  constructor() {
    this.dispatch = null;
    this.setDialog = null;
    this.toolboxExpand = false;
    this.toolboxesState = {};
    this.defaultConfig = {
      defaultState: {
        isExpandToolBox: false,
        toolboxes      : {}
      },
      toolboxes: []
    };
  }

  initialize(dispatch, setDialog, toolboxExpand, toolboxesState) {
    this.dispatch = dispatch;
    this.setDialog = setDialog;
    this.toolboxExpand = toolboxExpand;
    this.toolboxesState = toolboxesState;
  }

  handleToolboxVisibilityToggle = (toolboxId, currentState) => {
    this.dispatch(this.actions.toggleToolboxOpen(toolboxId, !currentState));
  };

  handleReorder = (toolboxId, direction) => {
    this.dispatch(this.actions.reorderToolbox(toolboxId, direction));
  };

  handleToolboxCollapseExpand = (expand) => {
    this.dispatch(this.actions.toolboxCollapseExpand(expand));
  };

  renderToolBoxManager = (toolboxes) => {
    const sortedToolboxes = [...toolboxes].sort((boxA, boxB) => 
      (this.toolboxesState[boxA.id]?.order ?? 0) - (this.toolboxesState[boxB.id]?.order ?? 0)
    );

    const visibleToolboxes = sortedToolboxes.filter(
      toolbox => this.toolboxesState[toolbox.id]?.isOpenToolBox ?? true
    );

    return (
      <CoreBox styleClasses={[CoreClasses.PADDING.P2]}>
        {sortedToolboxes.map((toolbox) => {
          const isOpenToolBox = this.toolboxesState[toolbox.id]?.isOpenToolBox ?? true;
          const visibleIndex = visibleToolboxes.findIndex(tBox => tBox.id === toolbox.id);
          const isFirst = visibleIndex === 0;
          const isLast = visibleIndex === visibleToolboxes.length - 1;
          
          return (
            <CoreBox 
              key={toolbox.id} 
              styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_START, CoreClasses.MARGIN.MY2]}
            >
              <CoreBox styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.GAP.GAP_2]}>
                <CoreIconButton
                  onClick={() => this.handleToolboxVisibilityToggle(toolbox.id, isOpenToolBox)}
                >
                  <CoreIcon 
                    icon={isOpenToolBox ? this.__VISIBILITY : this.__VISIBILITY_OFF}
                    color={isOpenToolBox ? this.__PRIMARY : this.__DEFAULT}
                  />
                </CoreIconButton>

                {!isFirst && (
                  <CoreIconButton
                    onClick={() => this.handleReorder(toolbox.id, this.__UP)}
                  >
                    <CoreIcon icon={this.__ICON_ARROW_UP} />
                  </CoreIconButton>
                )}

                {!isLast && (
                  <CoreIconButton
                    onClick={() => this.handleReorder(toolbox.id, this.__DOWN)}
                  >
                    <CoreIcon icon={this.__ICON_ARROW_DOWN} />
                  </CoreIconButton>
                )}
              </CoreBox>

              <CoreH6 styleClasses={[CoreClasses.MARGIN.ML2]}>{toolbox.toolTitle}</CoreH6>
            </CoreBox>
          );
        })}
      </CoreBox>
    );
  };

  showToolBoxManager = (toolboxes, setToolBoxController) => {
    this.setDialog({
      doneButton: () => {
        setToolBoxController(false);
        this.setDialog(null);
      },
      doneButtonLabel: this.__CLOSE,
      noCancelButton : true,
      noDoneButton   : false,
      showDialog     : true,
      subtitle       : this.renderToolBoxManager(toolboxes),
      title          : this.__MESSAGE_SHOW_TOOLBOX,
      type           : this.__INFO
    });
  };

  renderRightDrawer = (config) => {
    const { toolboxes } = { ...this.defaultConfig, ...config };
    
    const sortedToolboxes = [...toolboxes].sort((boxA, boxB) =>
      (this.toolboxesState[boxA.id]?.order ?? 0) - (this.toolboxesState[boxB.id]?.order ?? 0)
    );

    const visibleToolboxes = sortedToolboxes.filter(
      toolbox => this.toolboxesState[toolbox.id]?.isOpenToolBox ?? true
    );

    const [toolboxController, setToolBoxController] = useState(false);

    useEffect(() => {
      if (toolboxController) {
        this.showToolBoxManager(toolboxes, setToolBoxController);
        const timer = setTimeout(() => {
          setToolBoxController(false);
        }, 500);

        return () => clearTimeout(timer);
      }
    }, [toolboxController, toolboxes]);

    return (
      <CoreStack
        styleClasses={[CoreClasses.HEIGHT.VH_92, CoreClasses.OVERFLOW.OVERFLOW_Y_SCROLL, CoreClasses.BORDER.BORDER_START, CoreClasses.BORDER.BORDER_COLOR_GREY_400]}
      >
        <CoreBox
          styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.PADDING.P1]}
        >
          <CoreBox
            styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER, CoreClasses.GAP.GAP_3]}
          >
            <CoreTooltip title={!this.toolboxExpand ? this.__EMPTY_STRING : this.__COLLAPSE_MESSAGE} arrow>
              <CoreButton
                label={this.__COLLAPSE}
                variant={this.__VARIANT_TEXT}
                disabled={!this.toolboxExpand}
                onClick={() => this.handleToolboxCollapseExpand(false)}
              />
            </CoreTooltip>

            <CoreTooltip title={this.toolboxExpand ? this.__EMPTY_STRING : this.__EXPAND_MESSAGE} arrow>
              <CoreButton
                label={this.__EXPAND}
                disabled={this.toolboxExpand}
                variant={this.__VARIANT_TEXT}
                onClick={() => this.handleToolboxCollapseExpand(true)}
              />
            </CoreTooltip>
          </CoreBox>

          <CoreTooltip title={this.__SELECT_SELECTOR_BOX} arrow>
            <CoreIconButton onClick={() => setToolBoxController(true)}>
              <CoreIcon icon={this.__ICON_MORE_VERT} />
            </CoreIconButton>
          </CoreTooltip>
        </CoreBox>

        {visibleToolboxes.map((toolbox) => {
          const isFirst = visibleToolboxes[0].id === toolbox.id;
          const isLast = visibleToolboxes[visibleToolboxes.length - 1].id === toolbox.id;
          const buttons = [];

          if (!isFirst) buttons.push(this.__ICON_ARROW_UP);
          if (!isLast) buttons.push(this.__ICON_ARROW_DOWN);
          buttons.push(this.__ICON_REMOVE_CIRCLE);

          return (
            <CoreToolBox
              key={toolbox.id}
              toolTitle={toolbox.toolTitle}
              resize={toolbox.resize}
              expandProp={this.toolboxExpand}
              toolboxActionButton={buttons.map((buttonIcon, buttonIndex) => (
                <CoreIconButton
                  key={buttonIndex}
                  onClick={() => {
                    if (buttonIcon === this.__ICON_REMOVE_CIRCLE) {
                      this.handleToolboxVisibilityToggle(toolbox.id, true);
                    } else if (buttonIcon === this.__ICON_ARROW_UP) {
                      this.handleReorder(toolbox.id, this.__UP);
                    } else if (buttonIcon === this.__ICON_ARROW_DOWN) {
                      this.handleReorder(toolbox.id, this.__DOWN);
                    }
                  }}
                >
                  <CoreIcon
                    icon={buttonIcon}
                    color={buttonIcon === this.__ICON_REMOVE_CIRCLE ? this.__PRIMARY : this.__INHERIT}
                  />
                </CoreIconButton>
              ))}
            >
              {toolbox.content}
            </CoreToolBox>
          );
        })}
      </CoreStack>
    );
  };

  setActions(actions) {
    this.actions = actions;
  }
}