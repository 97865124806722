import {
  CoreForm,
  FORM_IDS,
  FORM_VIEW_MODE,
  CoreCard,
  CoreCardContent,
  CoreClasses
} from "@wrappid/core";

import TemplateButtonBlock from "./TemplateButtonBlock";
import VitalsView from "./VitalsView";

export default function TemplateSection({
  el,
  disabled,
  onOperate,
  present,
  vitals,
  currentTemplate,
  mainMasterData,
  allTemplates,
  label
}) {
  // eslint-disable-next-line no-console
  console.log("######s##### EL", el, label, present);

  return (
    <CoreCard>
      <CoreCardContent
        sx={present.enabled ? { backgroundColor: "gray" } : {}}
        styleClasses={[(present.enabled ? [CoreClasses.BG.BG_PRIMARY_LIGHT] : [CoreClasses.BG.BG_SECONDARY_LIGHT])]}
      >
        <TemplateButtonBlock
          operation={onOperate}
          name={el.name}
          label={label ? label : el.name}
          disabled={disabled}
        />

        <CoreForm
          allowDelete={false}
          formId={el.name}
          coreId={el.name}
          gridProps={el?.design || {}}
          // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
          mode={FORM_VIEW_MODE}
          allowEdit={false}
          onMountRead={false}
          preview={true}
        />

        {el.name === FORM_IDS.__PRESCRIPTION_VITALS && (
          <VitalsView
            currentTemplate={currentTemplate}
            mainMasterData={mainMasterData}
            allTemplates={allTemplates}
            disabled={disabled}
            onOperate={onOperate}
            vitals={vitals}
          />
        )}
      </CoreCardContent>
    </CoreCard>
  );
}
