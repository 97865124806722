import { getWrappidUUID } from "@wrappid/core/utils/appUtils";
import { Dispatch } from "redux";

import { STAR_ICON } from "../../../constants/AppBuilder.constant";
import AppBuilderFactory from "../../../factory/AppBuilder.factory";
import EditorFactory from "../../../factory/Editor.factory";
import ToolbarFactory from "../../../factory/Toolbar.factory";
import ToolBoxFactory from "../../../factory/ToolBox.factory";
import { EditorData } from "../editor/Editor.data";
import { EntityData } from "../entity/Entity.data";
import { ToolbarData } from "../toolbar/Toolbar.data";
import { ToolBoxData } from "../toolbox/ToolBox.data";

/** 
 * An abstract base class for managing builder data in application builders. 
 * This class provides core functionalities to set up and manage builder components such as toolboxes, editors, and toolbars.
 */
export abstract class AppBuilderData<TSchema> {
  private dispatch: Dispatch;
  
  /** readonly property to store builder id. */
  readonly builderId:string;

  /** Private property to store builder type. */
  private builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES];
  
  /** Private property to store entity data. */
  private entityData: EntityData<TSchema>;

  /** Private property to store entity model. */
  private entityModel:string;

  /** Private property to store toolbox-related data. */
  private toolboxes: ToolBoxData[] = [];

  /** Private property to store viewer-related data. */
  private editors: EditorData<TSchema>[] = [];

  /** Private property to store toolbar-related data. */
  private toolbars: ToolbarData<TSchema>[] = [];
  
  /** Private property to store builder icon. */
  private builderIcon: string;

  /** Private property to store selected or not selected */
  private selected = false;

  /**
   * Constructor to initialize the builder with the given data.
   * 
   * @param entityData - The dynamic entity data for this builder.
   * @param builderType - The type of builder (e.g., Default, Business, etc.).
   * @param builderIcon - The icon for the builder (defaults to STAR_ICON).
   * @param defaultToolboxeMeta - Metadata for initializing toolboxes.
   * @param defaultEditorMeta - Metadata for initializing editors.
   * @param toolbarMeta - Icons for initializing the toolbar.
   */
  constructor(
    builderId: string,
    storedToolbarData: any,
    storedToolboxData: any,
    storedEditorData: any,
    entityData: EntityData<TSchema>,
    builderType: typeof AppBuilderFactory.BUILDER_TYPES[keyof typeof AppBuilderFactory.BUILDER_TYPES], 
    builderIcon = STAR_ICON,
    defaultToolboxeMeta: { [key: string]: any }, 
    defaultEditorMeta: string[],
    toolbarMeta: string[],
    dispatch: Dispatch
  ) {
    this.builderId = builderId || getWrappidUUID(); // set builderId a Universally Unique Identifier
    this.builderType = builderType;
    this.builderIcon = builderIcon;
    this.entityData = entityData;
    this.dispatch = dispatch;
    this.entityModel = AppBuilderFactory.getEntityModel(builderType);
    this.prepareToolboxes(storedToolboxData, defaultToolboxeMeta);
    this.prepareEditors(storedEditorData, defaultEditorMeta);
    this.prepareToolbars(storedToolbarData, toolbarMeta, dispatch);
  }

  /**
   * Getter method to retrieve the builder type.
   * @returns The builder type.
   */
  getBuilderType() {
    return this.builderType;
  }

  /**
   * Getter method to retrieve the current entity data.
   * @returns The current entity data.
   */
  getEntityData() {
    return this.entityData;
  }

  /**
   * Getter method to retrieve the current entity model.
   * @returns The current entity model.
   */
  getEntityModel(){
    return this.entityModel;
  }

  /**
   * Getter method to retrieve the current toolbox data.
   * @returns An array of toolbox data.
   */
  getToolBoxes(): ToolBoxData[] {
    return this.toolboxes;
  }

  /**
   * Getter method to retrieve the current viewer data.
   * @returns An array of viewer data.
   */
  getEditors(): EditorData<TSchema>[] {
    return this.editors;
  }

  /**
   * Getter method to retrieve the current toolbar data.
   * @returns An array of toolbar data.
   */
  getToolbars(): ToolbarData<TSchema>[] {
    return this.toolbars;
  }

  /**
   * Getter method to retrieve the builder icon.
   * @returns The builder icon.
   */
  getBuilderIcon() {
    return this.builderIcon;
  }

  /**
   * Getter method to check if the builder is selected.
   * @returns True if the builder is selected, false otherwise.
   */
  isSelected(): boolean {
    return this.selected;
  }
  
  /**
   * Setter for entity data.
   * @param data - New entity data to set.
   */
  setEntityData(data: any) {
    this.entityData = data;
  }

  /**
   * Setter for editors.
   * @param editors - The new editors data to set.
   */
  setEditors(editors: EditorData<TSchema>[]): void {
    this.editors = editors; // Update the editors with the new list
  }

  /**
   * Setter method to set the selected state of the builder.
   * @param selected - The new selected state.
   */
  setSelected(selected: boolean): void {
    this.selected = selected;
  }

  /**
   * Prepare toolboxes based on the provided metadata.
   * This method will process the metadata and initialize the toolboxes.
   * 
   * @param defaultToolboxeMeta - The metadata for initializing toolboxes.
   */
  private prepareToolboxes(storedToolboxData: any, defaultToolboxeMeta: { [key: string]: any } = {}): void {
    // Iterate through the metadata for each toolbox variant and initialize them.
    Object.keys(defaultToolboxeMeta).forEach((eachToolBoxVariant, index) => {
      if(storedToolboxData && storedToolboxData[index] && storedToolboxData[index].toolboxId){
        const toolboxData = ToolBoxFactory.getToolBoxData(storedToolboxData[index].toolboxId, eachToolBoxVariant);

        toolboxData.setToolboxContent(storedToolboxData[index].toolboxContent);
        this.addToolBox(toolboxData);
      } else {
        const toolboxData = ToolBoxFactory.getToolBoxData(undefined, eachToolBoxVariant);

        toolboxData.setToolboxContent(defaultToolboxeMeta[eachToolBoxVariant]);
        this.addToolBox(toolboxData);
      }
    });
  }

  /**
   * Helper method to add a toolbox to the toolboxes array.
   * 
   * @param toolboxData - The toolbox data to add.
   */
  private addToolBox(toolboxData: ToolBoxData): void {
    this.toolboxes.push(toolboxData);
  }

  /**
   * Prepare editors based on the provided metadata and entity data.
   * This method will initialize editors using the factory and the given metadata.
   * 
   * @param entityData - The dynamic entity data.
   * @param defaultEditorMeta - The metadata for initializing editors.
   * @throws Error if entityData is invalid or empty.
   */
  private prepareEditors(storedEditorData:any, defaultEditorMeta: string[]): void {
    // @todo need to update when we handle enpty entityData
    if (!this.entityData || Object.keys(this.entityData).length === 0) {
      throw new Error("Entity data is not set. Unable to prepare editors.");
    }

    // If entityData is undefined, throw an error (redundant but ensures completeness).
    if (this.entityData === undefined) {
      throw new Error("Entity data undefined. Unable to prepare editors.");
    }

    // Iterate over the viewer metadata and initialize each viewer.
    defaultEditorMeta.forEach((viewerVariant, index) => {
      if(storedEditorData && storedEditorData[index] && storedEditorData[index].editorId){
        const EditorData = EditorFactory.getEditorData(
          storedEditorData[index].editorId,
          storedEditorData[index].editorContent,
          viewerVariant,
          this.entityData
        );

        // eslint-disable-next-line etc/no-commented-out-code
        // EditorData.setEditorContent(storedEditorData[index].editorContent);
        this.addEditor(EditorData);
      } else{
        const EditorData = EditorFactory.getEditorData(
          undefined,
          undefined,
          viewerVariant,
          this.entityData
        );

        this.addEditor(EditorData);
      }
    });
  }

  /**
   * Helper method to add a viewer to the editors array.
   * 
   * @param EditorData - The viewer data to add.
   */
  private addEditor(EditorData: EditorData<TSchema>): void {
    this.editors.push(EditorData);
  }

  /**
   * Prepare toolbars based on the provided icons.
   * This method will initialize toolbar data using the provided icons.
   * 
   * @param toolbarMeta - The icons for initializing toolbars.
   * @returns An array of toolbar data.
   */
  private prepareToolbars(storedToolbarData: any, toolbarMeta: string[], dispatch: Dispatch): void {
  // Map the icons to create toolbar data using the factory.
    toolbarMeta.forEach((icon, index) => {
    // Check if storedToolbarData is available and if the index exists in storedToolbarData
      if (storedToolbarData && storedToolbarData[index] && storedToolbarData[index].toolbarId) {
      // Pass the toolbarId from storedToolbarData for the current index to ToolbarFactory
        const toolbarData = ToolbarFactory.getEditorData(
          storedToolbarData[index].toolbarId, // Passing toolbarId from storedToolbarData
          icon,
          this.entityData,
          this.builderType,
          dispatch
        );

        this.addToolbar(toolbarData); // Add each toolbarData individually
      } else {
      // Fallback when storedToolbarData is not available or toolbarId is missing
        const toolbarData = ToolbarFactory.getEditorData(
          undefined, // Passing undefined for toolbarId when storedToolbarData is not available
          icon,
          this.entityData,
          this.builderType,
          dispatch
        );

        this.addToolbar(toolbarData); // Add toolbar data without storedToolbarData
      }
    });
  }

  /**
   * Helper method to add a viewer to the editors array.
   * 
   * @param toolbarData - The viewer data to add.
   */
  private addToolbar(toolbarData: ToolbarData<TSchema>): void {
    this.toolbars.push(toolbarData);
  }
}