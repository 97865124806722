import React from "react";

import {
  CoreClasses,
  CoreTypographyBody1,
  CoreGrid,
  CoreStack,
  CoreForm,
  FORM_EDIT_MODE,
  CoreLayoutItem,
  AppContainerLayout,
  CoreFunctionsRegistryContext
} from "@wrappid/core";
import { useSelector } from "react-redux";

import PatientCard from "./PatientCard";
import SearchUser from "./SearchUser";

export default function CreateAppointment() {
  const functionRegistry = React.useContext(CoreFunctionsRegistryContext);

  const { user: { personID } } = useSelector((state) => state.auth);

  const patient = useSelector((state) => state?.appointment?.patient);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreGrid
          // styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER]}
          styleClasses={[CoreClasses.LAYOUT.FULL_WIDTH]}
        >
          <CoreStack gridProps={{ gridSize: { sm: 12 } }}>
            <CoreTypographyBody1>Create Appointment</CoreTypographyBody1>

            {patient ? (
              <>
                <PatientCard patient={patient} />

                <CoreForm
                  apiMode="create"
                  formId="createAppointment"
                  mode={FORM_EDIT_MODE}
                  initProps={{ clinicId: { query: { _defaultFilter: encodeURIComponent(JSON.stringify({ personId: personID })) } } }
                  }
                />
              </>
            ) : (
              <SearchUser
                label="Search User"
                query={{ input: "a", role: "patient" }}
                itemKey="existingUsers"
                endpoint="/userSearchPaginated"
                gridSize={12}
                creatable={true}
                optionComp="searchUserCard"
                navigateUrl="/create_patient"
                getOptionLabel={functionRegistry.__SEARCHPATIENT_GET_OPTION_LABEL}
                // getOptionLabel={(data) => {
                //   return JSON.stringify(data);
                // }}
                getOptionValue={functionRegistry.__SEARCHPATIENT_GET_OPTION_VALUE}
                optionCompProps={{ role: "patient" }}
                onChangeDispatch="SET_APPOINTMENT_PATIENT"
                isOptionsEqualToValue={functionRegistry.__SEARCHPATIENT_IS_OPTIONS_EQUAL_TO_VALUE}
              />
            )}

            {/* -- <CoreForm formId="searchPatient" mode={FORM_EDIT_MODE} /> */}
          </CoreStack>
        </CoreGrid>
      </CoreLayoutItem>
    </>

  );
}
