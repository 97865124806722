import { CoreComponentsRegistry, defaultValidProps } from "@wrappid/core";
import { useSelector } from "react-redux";

import { getSelectedComponent } from "./propUtils";
import SelectProps from "./SelectProps";
import StyleSelector from "./StyleSelector";

export default function PropsContainer() {
  const propsComponentPath = useSelector((state) => state.appBuilderReducer?.propsComponentPath);
  const componentsInBoxes = useSelector((state) => state.appBuilderReducer?.componentsInBoxes);
  const activeBox = propsComponentPath?.placeholderIndex;

  const selectedComponent = getSelectedComponent(componentsInBoxes, activeBox, propsComponentPath?.componentPath);

  if (!selectedComponent) {
    return null;
  }

  const component = CoreComponentsRegistry[selectedComponent.component];
  const availableProps = [...(component?.comp?.validProps || []), ...defaultValidProps];
  
  return (
    <SelectProps
      componentPath={propsComponentPath}
      component={selectedComponent.component}
      initialProps={selectedComponent.props}
      availableProps={availableProps}
      StyleSelector={StyleSelector}
    />
  );
}