import React from "react";

import { CoreList, CoreListItem, CoreListItemText } from "@wrappid/core";

export default function LayoutToolBox(props) {
  const { toolboxContent } = props;

  return (
    <CoreList>
      {Array.isArray(toolboxContent) ? (
        toolboxContent.map((item, index) => (
          <CoreListItem key={`layout-item-${index}`} disablePadding>
            <CoreListItemText 
              primary={item.layoutName} 
            />
          </CoreListItem>
        ))
      ) : (
        <CoreListItem disablePadding>
          <CoreListItemText 
            primary={toolboxContent.layoutName} 
          />
        </CoreListItem>
      )}
    </CoreList>
  );
}