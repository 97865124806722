import { useEffect } from "react";

import {
  AppContainerLayout,
  CoreDataTable,
  CoreLayoutItem,
  HTTP,
  __IconTypes,
  apiRequestAction,
  coreUseNavigate
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import TemplateEditor from "./TemplateEditor";
import { setPrescriptionReducer } from "../../actions/prescription.action";
import { ApiRegistry } from "../../apis.registry";
import {
  CLONE_PRESCRIPTION_TEMPLATE_ERROR,
  CLONE_PRESCRIPTION_TEMPLATE_SUCCESS,
  GET_PRESCRIPTION_TEMPLATES_ERROR,
  GET_PRESCRIPTION_TEMPLATES_SUCCESS,
  GET_TEMPLATE_DESIGNS_ERROR,
  GET_TEMPLATE_DESIGNS_SUCCESS
} from "../../types/prescription.types";

export default function ManagePrescriptonTemplate() {
  const navigate = coreUseNavigate();
  const dispatch = useDispatch();

  const { personID } = useSelector((state) => state?.auth?.user);

  const { clonePrescriptionTemplateSuccess, clonePrescriptionTemplateId } = useSelector((state) => state.prescription);

  const query = {
    or: {
      name    : { like: "sys_gen_%" },
      personId: personID
    }
  };

  const tableRowActions = [
    {
      action: (data) => {
        dispatch(
          apiRequestAction(
            HTTP.POST,
            "/prescriptionTemplate/:id/clone".replace(
              ":id",
              data.id
            ),
            true,
            {},
            CLONE_PRESCRIPTION_TEMPLATE_SUCCESS,
            CLONE_PRESCRIPTION_TEMPLATE_ERROR
          )
        );
      },
      icon: {
        icon: "fa-file-prescription",
        type: __IconTypes.FONTAWESOME_V5_SOLID_ICON
      },
      label: "Clone Template",
      type : "action"
    },
    {
      action: (data) => {
        const route = ApiRegistry.EDIT_PRESCRIPTION_TEMPLATE.replace(
          ":id",
          data?.id
        );

        navigate(route);
      },
      hide: (rowData) => {
        if (rowData.personId !== personID) return true;
      },

      icon: {
        icon: "fa-file-prescription",
        type: __IconTypes.FONTAWESOME_V5_SOLID_ICON
      },

      label: "Edit Template",

      type: "action"
    }
  ];

  const tableColumns = [
    {
      id   : "id",
      label: "ID"
    },
    {
      id   : "label",
      label: "Label"
    },
    {
      id    : "type",
      label : "Type",
      render: (cellData, rowData) => {
        return <>{rowData.createdBy === 0 ? "System" : "Custom"}</>;
      },
      renderType: "function"
    },
    {
      id   : "_status",
      label: "Status"
    }
  ];

  useEffect(() => {
    if (clonePrescriptionTemplateSuccess) {
      navigate(
        `${ApiRegistry.EDIT_PRESCRIPTION_TEMPLATE.replace(
          ":id",
          clonePrescriptionTemplateId
        )}`
      );
      dispatch(
        apiRequestAction(
          HTTP.GET,
          ApiRegistry.GET_PRESCRIPTION_TEMPLATE_DESIGNS_API,
          true,
          {},
          GET_TEMPLATE_DESIGNS_SUCCESS,
          GET_TEMPLATE_DESIGNS_ERROR
        )
      );

      dispatch(
        apiRequestAction(
          HTTP.GET,
          "/business/all/prescriptionTemplate",
          true,
          {
            _defaultFilter: encodeURIComponent(
              JSON.stringify(query)
            )
          },
          GET_PRESCRIPTION_TEMPLATES_SUCCESS,
          GET_PRESCRIPTION_TEMPLATES_ERROR
        )
      );
      dispatch(
        setPrescriptionReducer({ clonePrescriptionTemplateSuccess: false })
      );
    }
  }, [clonePrescriptionTemplateSuccess]);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreDataTable
          entity={"prescriptionTemplate"}
          rowActions={tableRowActions}
          columns={tableColumns}
          filterQuery={{ filter: query }}
          postRenderDetailsPaneComponent={TemplateEditor}
        />
      </CoreLayoutItem>
    </>
  );
}
