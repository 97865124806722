/* eslint-disable no-unused-vars */

export const SanCreateAppointment = (formData, apiMeta, state, others) => {
  // -- console.log("formData", formData);
  return {
    endpoint : apiMeta.endpoint,
    reduxData: apiMeta.reduxData,
    values   : {
      ...formData,
      clinicId:
                Number(formData?.clinicId?.Clinic?.id) === 0
                  ? null
                  : Number(formData?.clinicId?.Clinic?.id),
      doctorId : state?.auth?.user?.personID,
      patientId: state?.appointment?.patient?.Person?.id,
    },
  };
};
      
export const SanEditAppointment = (form, apiMeta, state, others) => {
  let formData = { ...form };
  const endpoint = apiMeta.endpoint.replace(":id", form?.id);
      
  // --  console.error("form", form);
  // --  console.error("endpoint", endpoint);
  // --  console.error("reduxData", apiMeta.reduxData);
  // --  console.error("state", state);
  return {
    endpoint : endpoint,
    reduxData: apiMeta.reduxData,
    values   : {
      clinicId:
                Number(formData?.clinicId?.Clinic?.id) === 0
                  ? null
                  : Number(formData?.clinicId?.Clinic?.id),
      date     : formData.date,
      doctorId : state?.auth?.user?.personID,
      endTime  : formData.endTime,
      patientId: formData["Patient.id"],
      startTime: formData.startTime,
    },
  };
};
      
export const SanReadAppointment = (data) => {
  return {
    ...data,
    clinicId: { fullName: data?.Clinic?.Address?.fullName, id: data?.Clinic?.id, label: data?.Clinic?.Address?.fullName }
  };
};

export const SanDeleteAppointment = (formData, apiMeta, state, others) => {
  return {
    endpoint: apiMeta.endpoint.replace(":id", others.deleting),
    values  : formData,
  };
};