/* eslint-disable etc/no-commented-out-code */

import React from "react";

import {
  HTTP,
  CoreAvatar,
  CoreBox,
  CoreClasses,
  CoreCustomTabs,
  CoreGrid,
  CoreStack,
  CoreTypographyCaption,
  CoreIconText,
  __IconTypes,
  CoreEmailLink,
  CoreIcon,
  CoreIconButton,
  getAge,
  apiRequestAction,
  CoreTypographyBody1,
  CoreTooltip,
  CoreLink
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { capitalizeFirstLetter, getAddress, getDOB, getFromMasterData } from "../functions/helper.functions";
import { GET_RELATIVE_ERROR, GET_RELATIVE_SUCCESS } from "../types/patient.types";

export const CustomIconTooltip = (props) => {
  return (
    <CoreStack direction="row" spacing={.5} styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
      <CoreTooltip
        title={props.title}
        arrow>
        <CoreIcon
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon={props.icon}
          color="primary"
        />
      </CoreTooltip>

      {props.textTitle ? (
        <CoreTooltip title={props.textTitle} arrow>
          <CoreTypographyCaption>
            {props?.text}
          </CoreTypographyCaption>
        </CoreTooltip>)
        :
        (<CoreTypographyCaption>
          {props?.text}
        </CoreTypographyCaption>)
      }

    </CoreStack>
  );
};

function ParentInfo(prpos) {

  const { relativeInfo } = prpos;

  return (
    <CoreBox styleClasses={[CoreClasses.PADDING.P0]}>
      <CoreTypographyBody1
        styleClasses={[CoreClasses.PADDING.PB0, CoreClasses?.COLOR?.TEXT_WHITE]}
      >
        Name: {[relativeInfo?.data.firstName, relativeInfo?.data.middleName, relativeInfo?.data.lastName].join(" ")}
      </CoreTypographyBody1>

      <CoreStack
        direction="column"
        spacing={0}
        styleClasses={[CoreClasses?.MARGIN?.MT1]}>
        <CoreBox>
          <CoreIconText
            styleClasses={[CoreClasses?.COLOR?.TEXT_WHITE]}
            icon="call"
            link={true}
            type={__IconTypes.MATERIAL_OUTLINED_ICON}
            href={`tel:${relativeInfo?.data.phone}`}
            size="small"
            text={relativeInfo?.data.phone}
          />
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses?.COLOR?.TEXT_WHITE]} >
          <CoreIconText
            styleClasses={[CoreClasses?.COLOR?.TEXT_WHITE]}
            icon="email"
            link={true}
            type={__IconTypes.MATERIAL_OUTLINED_ICON}
            href={`email:${relativeInfo?.data.phone}`}
            size="small"
            text={relativeInfo?.data.email}
          />
        </CoreBox>
      </CoreStack>

      <CoreTypographyBody1
        styleClasses={[CoreClasses.PADDING.PB0, CoreClasses?.COLOR?.TEXT_WHITE]}
      >
        Relation: {relativeInfo.data.relationType}
      </CoreTypographyBody1>
    </CoreBox>

  );
}

export default function PatientDetailsCard(props) {
  const { initData = {} } = props || {};
  const data = initData;
  const dispatch = useDispatch();
  const options = useSelector(state => state?.selectOptions?.options);
  const relativeInfo = useSelector(state => state?.patient?.relativeInfo);

  const tabsContent = [
    {
      comp : "PatientUpcomingAppointment",
      id   : "patientUpcomingAppointment",
      label: "Upcoming Appointment",
      props: { data: data },
    },
    {
      comp : "PastAppointments",
      id   : "pastAppointment",
      label: "Past Appointment",
      props: { data: data, patientID: data?.relatedPersonId },
    },
    {
      comp : "PatientHistory",
      id   : "patientHistory",
      label: "Patient History",
      props: { data: data },
    },
    {
      comp : "MedicalBackground",
      id   : "medicalBackground",
      label: "Medical Background",
      props: { data: data },
    },
    {
      comp : "Medication",
      id   : "medication",
      label: "Medication",
      props: { data: data },
    },
    {
      comp : "Vitals",
      id   : "vitals",
      label: "Vitals",
      props: { data: data },
    },
    {
      comp : "PrivateNotes",
      id   : "privateNotes",
      label: "Private Notes",
      props: { data: data },
    }
  ];

  React.useEffect(() => {
    const relatedPersonId = data?.personID;

    if (!data?.userID) {
      try {
        const endpoint = `/GetRelativeInfo/${relatedPersonId}`;
        const authRequired = true;
        const apiData = undefined;

        dispatch(
          apiRequestAction(
            HTTP.GET,
            endpoint,
            authRequired,
            apiData,
            GET_RELATIVE_SUCCESS,
            GET_RELATIVE_ERROR
          )
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching data:", error);
      }
    }
  }, [data?.id]);

  return (
    <>
      {data?.userID ? (
        <>

          <CoreGrid styleClasses={[CoreClasses.PADDING.P1]}>
            <CoreBox
              gridProps={{ gridSize: { md: 1, sm: 2, xs: 3 } }}
              styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
              <CoreAvatar
                styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
                src={data.photoUrl}
              />
            </CoreBox>

            <CoreBox
              gridProps={{ gridSize: { md: 11, sm: 10, xs: 9 } }}
              styleClasses={[CoreClasses.PADDING.PL2]}>
              <CoreTypographyBody1
                paragraph={false}
                gutterBottom={false}
                styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_SEMIBOLD]}
              >
                {[data.firstName, data.middleName, data.lastName].join(" ")}
              </CoreTypographyBody1>

              <CoreTypographyCaption>
                MRN{": "}

                <CoreTypographyCaption
                  component="span"
                  color="primary">
                  {data.profileId || "N/A"}
                </CoreTypographyCaption>
              </CoreTypographyCaption>

              <CoreStack direction="row" spacing={.5} styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
                <CustomIconTooltip
                  gridProps={{ gridSize: { md: 3, xs: 3 } }}
                  title="Date of Birth"
                  textTitle={getDOB(data.dob)}
                  icon="cake"
                  text={getAge(data.dob)}
                />

                <CoreTypographyCaption>
                  {"|"}
                </CoreTypographyCaption>

                <CustomIconTooltip
                  title={getFromMasterData(
                    data.gender,
                    "gender",
                    options
                  )?.toLowerCase()}
                  icon={getFromMasterData(
                    data.gender,
                    "gender",
                    options
                  )?.toLowerCase()}
                  text={capitalizeFirstLetter(getFromMasterData(
                    data.gender,
                    "gender",
                    options
                  ))}
                />
              </CoreStack>

              <CoreStack
                direction="row"
                spacing={1}
                styleClasses={[CoreClasses?.MARGIN?.MT1]}>
                <CustomIconTooltip
                  title={data.phone}
                  icon="phone"
                />

                <CustomIconTooltip
                  title={data.email}
                  icon="email"
                />
              </CoreStack>
            </CoreBox>
          </CoreGrid>

          <CoreGrid>
            <CoreBox
              gridProps={
                { gridSize: 6 }
              }
              styleClasses={[CoreClasses.TEXT.TEXT_START, CoreClasses.PADDING.PB2]}>
              <CoreLink varient="body1" styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_NORMAL]}>Connect ABHA</CoreLink>

              <CoreBox styleClasses={[CoreClasses.MARGIN.MB1]}>
                <CoreTypographyCaption>Basic Info</CoreTypographyCaption>
              </CoreBox>

              <CustomIconTooltip
                icon="bloodtype"
                title="Blood Group"
                text={ data.bloodGroup
                 || "Not Available"}
              />

              {/* <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="location_on" text="E-20 Jadavpur, Kolkata-700032" /> */}
              <CustomIconTooltip
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon="location_on"
                title="Address"
                text={getAddress(data, options)}
              />

              <CustomIconTooltip
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon="people"
                title="Relation"
                text="Self"
              />

              <CustomIconTooltip
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon="favorite_border"
                title="Marrital Status"
                text={capitalizeFirstLetter(
                  data.marritalStatus)
                 || "Not Available"}
              />

            </CoreBox>
          </CoreGrid>

          <CoreCustomTabs
            tabsContent={tabsContent}
            varient="scrollable"
            tabHeadClasses={
              [CoreClasses.BORDER.BORDER, CoreClasses.BORDER.BORDER_TOP, CoreClasses.BORDER.BORDER_BOTTOM, CoreClasses.BORDER.BORDER_COLOR_GREY_300]
            } />
        </>
      ) : (
        <>

          <CoreGrid styleClasses={[CoreClasses.PADDING.P1]}>
            <CoreBox
              gridProps={{ gridSize: { md: 1, sm: 2, xs: 3 } }}
              styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
              <CoreAvatar
                styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
                src={data.photoUrl}
              />
            </CoreBox>

            <CoreBox
              gridProps={{ gridSize: { md: 11, sm: 10, xs: 9 } }}
              styleClasses={[CoreClasses.PADDING.PL2]}>
              <CoreTypographyBody1
                gutterBottom={false}
                styleClasses={[CoreClasses.PADDING.PB0]}
              /* styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]} */
              >
                {[data.firstName, data.middleName, data.lastName].join(" ")}
              </CoreTypographyBody1>

              <CoreTypographyCaption>
                MRN{": "}

                <CoreTypographyCaption
                  component="span"
                  color="primary">
                  {data.profileId || "N/A"}
                </CoreTypographyCaption>
              </CoreTypographyCaption>

              <CoreStack direction="row" spacing={1} divider={true}>
                <CoreBox>
                  <CustomIconTooltip
                    title={getDOB(data.dob)}
                    text={getAge(data.dob)}
                    icon="cake"
                    type={__IconTypes.MATERIAL_OUTLINED_ICON}
                  />

                </CoreBox>

                <CoreBox>
                  <CoreIconButton
                    type={__IconTypes.MATERIAL_OUTLINED_ICON}
                    title=""
                    disabled={true}
                    disableFocusRipple={true} >

                    <CoreIconText
                      type={__IconTypes.MATERIAL_OUTLINED_ICON}
                      icon={getFromMasterData(
                        data.gender,
                        "gender",
                        options
                      )?.toLowerCase()}
                      text={capitalizeFirstLetter(getFromMasterData(
                        data.gender,
                        "gender",
                        options
                      ))}
                    />
                  </CoreIconButton>
                </CoreBox>
              </CoreStack>

              <CoreStack
                direction="column"
                spacing={1}
                styleClasses={[CoreClasses?.MARGIN?.MT1]}>
                <CoreBox>
                  <CoreIconText
                    icon="call"
                    link={true}
                    type={__IconTypes.MATERIAL_OUTLINED_ICON}
                    href={`tel:${data.phone}`}
                    size="small"
                    text={data.phone}
                  />
                </CoreBox>

                <CoreBox>
                  <CoreEmailLink
                    size="small"
                    email={data.email}
                  />
                </CoreBox>
              </CoreStack>
            </CoreBox>
          </CoreGrid>

          <CoreGrid>
            <CoreBox
              gridProps={
                { gridSize: 12 }
              }
              styleClasses={[CoreClasses.TEXT.TEXT_START, CoreClasses.PADDING.PB2]}>
              {/* -- <CoreLink>Connect ABHA</CoreLink> */}

              <CoreBox styleClasses={[CoreClasses.MARGIN.MB1]}>
                <CoreTypographyCaption>Basic Info</CoreTypographyCaption>
              </CoreBox>

              <CustomIconTooltip
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon="bloodtype"
                title="Blood Group"
                text={
                  data.bloodGroup
                 || "Not Available"}
              />

              {/* <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="location_on" text="E-20 Jadavpur, Kolkata-700032" /> */}
              <CustomIconTooltip
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon="location_on"
                title="Address"
                text={getAddress(data, options)}
              />

              <CustomIconTooltip
                title={<ParentInfo relativeInfo={relativeInfo} />}
                icon="people"
                text="Relative"
                styleClasses={[CoreClasses.BG.BG_SUCCESS_LIGHT]}
                disableFocusRipple={true}
                disableRipple={true}
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
              />

              <CustomIconTooltip
                icon="favorite_border"
                title="Marrital Status"
                text={capitalizeFirstLetter(
                  data.marritalStatus)
                 || "Not Available"}
              />

            </CoreBox>
          </CoreGrid>

          <CoreCustomTabs
            tabsContent={tabsContent}
            varient="scrollable"
            tabHeadClasses={
              [CoreClasses.BORDER.BORDER, CoreClasses.BORDER.BORDER_TOP, CoreClasses.BORDER.BORDER_BOTTOM, CoreClasses.BORDER.BORDER_COLOR_GREY_300]
            } />
        </>
      )}
    </>
  );
}