import { AssociationData } from "../../entity/ModelEntity.data";
import { AttributeData } from "../../model/Attribute.data";

export class ModelEditorConverter {
  extractAttributes(data: any): AttributeData[] {
    if (!Array.isArray(data)) {
      throw new Error("Input data should be an array");
    }

    return data.flatMap((item) =>
      item.attributes
        ? Object.entries(item.attributes).map(([key, value]: [string, AttributeData]) => ({
          name: key,
          ...value,
        }))
        : []
    );
  }

  extractAssociation(data: any): AssociationData[] {
    if (!Array.isArray(data)) {
      throw new Error("Input data should be an array");
    }
    const associationData = data.flatMap((item) =>
      item.associations
        ? Object.entries(item.associations).map(([key, value]: [string, any]) => ({
          name: key,
          ...value,
        }))
        : []
    );
  
    function convertData(associationData) {
      return associationData.reduce((result, item) => {
        const tableName = item.model;
        const relations = item.data.map(relation => ({
          as        : relation.data.as || null,
          foreignKey: relation.data.foreignKey,
          sourceKey : relation.data.sourceKey,
          type      : relation.type
        }));
  
        result[tableName] = relations;
        return result;
      }, {});
    }
    const AssociationArray = convertData(associationData);
  
    return AssociationArray ; 
  }
}