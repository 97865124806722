/* eslint-disable etc/no-commented-out-code */
import { ComponentToolBoxData } from "../components/data/toolbox/component/ComponentToolBox.data";
import { DeviceToolBoxData } from "../components/data/toolbox/DeviceToolBox.data";
import { LayoutToolBoxData } from "../components/data/toolbox/layout/LayoutToolBox.data";
import { NavigatorToolBoxData } from "../components/data/toolbox/NavigatorToolBox.data";
import { PropertyToolBoxData } from "../components/data/toolbox/property/PropertyToolBox.data";
import { ThemeToolBoxData } from "../components/data/toolbox/ThemeToolBox.data";
import { ToolBoxData } from "../components/data/toolbox/ToolBox.data";

/**
 * ToolboxFactory class provides methods to create different toolbox components based on the icon type.
 * The factory pattern is used to abstract the instantiation of specific toolbox data.
 */
export default class ToolBoxFactory {
  /** 
   * A constant object holding various toolbox names for different toolboxes available in the toolbox group.
   * These variants correspond to specific toolbox actions or categories within the toolbox group.
   */
  static VARIANTS = {
    COMPONENT: "component",
    DEVICE   : "device",
    LAYOUT   : "layout",
    NAVIGATOR: "navigator",
    PROPERTY : "property",
    THEME    : "theme",
  } as const; 

  /**
   * Factory method to create and return the appropriate toolbox data based on the provided toolbox variant.
   * This method abstracts the creation logic and returns instances of the corresponding toolbox data class.
   * 
   * @param toolboxVariant - The type of toolbox (e.g., "component", "device", "layout").
   * @returns A new instance of the appropriate ToolBoxData subclass based on the toolboxVariant.
   * @throws Error if the provided toolboxVariant is not recognized.
   */
  static getToolBoxData<T extends ToolBoxData>(
    toolboxId: string,
    toolboxVariant: string
  ) :T {
    switch (toolboxVariant) {
      case ToolBoxFactory.VARIANTS.PROPERTY:
        return new PropertyToolBoxData(toolboxId) as T; // Return a new instance of PropertyToolBoxData when the toolboxVariant is "property".

      case ToolBoxFactory.VARIANTS.DEVICE:
        return new DeviceToolBoxData(toolboxId) as T; // Return a new instance of DeviceToolBoxData when the toolboxVariant is "device".

      case ToolBoxFactory.VARIANTS.LAYOUT:
        return new LayoutToolBoxData(toolboxId) as T; // Return a new instance of LayoutToolBoxData when the toolboxVariant is "layout".
      
      case ToolBoxFactory.VARIANTS.COMPONENT:
        return new ComponentToolBoxData(toolboxId) as T; // Return a new instance of LayoutToolBoxData when the toolboxVariant is "component".

      case ToolBoxFactory.VARIANTS.THEME:
        return new ThemeToolBoxData(toolboxId) as T; // Return a new instance of LayoutToolBoxData when the toolboxVariant is "theme".

      case ToolBoxFactory.VARIANTS.NAVIGATOR:
        return new NavigatorToolBoxData(toolboxId) as T; // Return a new instance of LayoutToolBoxData when the toolboxVariant is "navigator".

      // If the toolboxVariant doesn't match any of the defined variants, throw an error.
      default:
        throw new Error(`Unknown entity type: ${toolboxVariant}`);
    }
  }
}