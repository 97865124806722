import { getFormikRequiredMessage } from "@wrappid/core";
import { string, date, mixed } from "yup";

const indianPhoneRegex = /^\+?([ -这让\u0915-\u0939])\d{9}$/;

export const createPatient = {
  city    : string().required(getFormikRequiredMessage("city")),
  country : string().required(getFormikRequiredMessage("country")),
  district: string().required(getFormikRequiredMessage("district")),
  dob     : date("Not valid input").required(getFormikRequiredMessage("dob", true)),
  
  email: string()
    .required("Email is required")
    .email("Invalid email format"),
  firstName: 
    string()
      .trim()
      .required(getFormikRequiredMessage("firstName"))
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  gender: string("Not valid input").required(getFormikRequiredMessage("gender")),
  lastName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  middleName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  phone: string()
    .required("Phone number is required")
    .matches(indianPhoneRegex, "Invalid phone number format")
    .required("Phone number is required")  
    .test("startsWithValidPrefix", "Phone number must start with a valid prefix (6-9)", (value) => {
      if (!value) {
        return false; // Allow empty string for optional fields
      }
      const firstDigit = value[0] || value[1]; // Handle cases with or without '+'
  
      return firstDigit >= 6 && firstDigit <= 9;
    }),
  photo: mixed()
    .test("fileType", "Only JPG and PNG files are allowed", (value) => {
      if (!value) return true;
      const allowedFileTypes = ["image/jpeg", "image/png"];

      return allowedFileTypes.includes(value.type);
    })
    .test("fileSize", "Photo size shouldn't be more than 200KB", (value) => {
      if (!value) return true;
      return value.size <= 200000;
    }),
  state: string().required(getFormikRequiredMessage("state"))
};

export const createPatientRelative = {

  dob  : date("Not valid input").required(getFormikRequiredMessage("dob", true)),
  email: string()
    .required("Email is required")
    .email("Invalid email format"),
     
  firstName: 
    string()
      .trim()
      .required(getFormikRequiredMessage("firstName"))
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  gender: string("Not valid input").required(getFormikRequiredMessage("gender")),
  lastName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  middleName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  phone: string()
    .matches(indianPhoneRegex, "Invalid phone number format")
    .required("Phone number is required")  
    .test("startsWithValidPrefix", "Phone number must start with a valid prefix (6-9)", (value) => {
      if (!value) {
        return false; // Allow empty string for optional fields
      }
      const firstDigit = value[0] || value[1]; // Handle cases with or without '+'
  
      return firstDigit >= 6 && firstDigit <= 9;
    }),
  photo: mixed()
    .test("fileSize", "File size must be less than 5MB", (value) => {
      if (!value) return true; // Skip validation if no file selected
      return value.size <= 5242880; // Check if file size is less than 25MB in bytes
    }),
};

export const updatePatient =  {
  city    : string().required(getFormikRequiredMessage("city")),
  country : string().required(getFormikRequiredMessage("country")),
  district: string().required(getFormikRequiredMessage("district")),
  dob     : date("Not valid input").required(getFormikRequiredMessage("dob", true)),
  
  email: string()
    .required("Email is required")
    .email("Invalid email format"),
  firstName: 
    string()
      .trim()
      .required(getFormikRequiredMessage("firstName"))
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  gender: string("Not valid input").required(getFormikRequiredMessage("gender")),
  lastName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  middleName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  phone: string()
    .required("Phone number is required")
    .matches(indianPhoneRegex, "Invalid phone number format")
    .required("Phone number is required")  
    .test("startsWithValidPrefix", "Phone number must start with a valid prefix (6-9)", (value) => {
      if (!value) {
        return false; // Allow empty string for optional fields
      }
      const firstDigit = value[0] || value[1]; // Handle cases with or without '+'
  
      return firstDigit >= 6 && firstDigit <= 9;
    }),
  photo: mixed()
    .test("fileSize", "Photo size shouldn't be more than 200KB", (value) => {
      if (!value) {
        return true; // Allow empty value (optional photo)
      }
      if(typeof value === "string"){
        return true;
      }
      return value.size <= 200000; // 200KB in bytes
    })
    .test("fileType", "Invalid photo format", (value) => {
      if (!value) {
        return true; // Allow empty value (optional photo)
      }
      if(typeof value === "string"){
        return true;
      }
      const supportedTypes = ["image/jpeg", "image/png"];

      return supportedTypes.includes(value.type);
    }),
  state: string().required(getFormikRequiredMessage("state")),
};

export const updatePatientRelative = {
  dob  : date("Not valid input").required(getFormikRequiredMessage("dob", true)),
  email: string()
    .required("Email is required")
    .email("Invalid email format"),
  firstName: 
    string()
      .trim()
      .required(getFormikRequiredMessage("firstName"))
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  gender: string("Not valid input").required(getFormikRequiredMessage("gender")),
  lastName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  middleName: 
    string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  phone: string()
    .required("Phone number is required")
    .matches(indianPhoneRegex, "Invalid phone number format")
    .required("Phone number is required")  
    .test("startsWithValidPrefix", "Phone number must start with a valid prefix (6-9)", (value) => {
      if (!value) {
        return false; // Allow empty string for optional fields
      }
      const firstDigit = value[0] || value[1]; // Handle cases with or without '+'
  
      return firstDigit >= 6 && firstDigit <= 9;
    }),
  photo: mixed()
    .test("fileSize", "File size must be less than 5MB", (value) => {
      if (!value) return true; // Skip validation if no file selected
      return value.size <= 5242880; // Check if file size is less than 25MB in bytes
    }),
};