import React from "react";

import { CoreIconButton, CoreIcon, CoreTooltip } from "@wrappid/core";

import { ToolbarData } from "../data/toolbar/Toolbar.data";

// eslint-disable-next-line etc/no-commented-out-code
// type ToolbarType<T extends ToolbarData> = {toolbarData: T};

type ToolbarType<TSchema> = {
  toolbarData: ToolbarData<TSchema>; // The toolbar data will be of type ToolbarData<TSchema>
  actionFunction: (toolbarData: ToolbarData<TSchema>) => void; // The action function
};

export default function ToolbarToolbarData<TSchema>(props: ToolbarType<TSchema>) {
  const { toolbarData, actionFunction } = props;
  
  Object.setPrototypeOf(toolbarData, ToolbarData.prototype);

  return (
    <CoreTooltip title={toolbarData.getToolbarIconTitle()} arrow>
      <CoreIconButton onClick={() => actionFunction(toolbarData)}>
        <CoreIcon icon={toolbarData.getActionIcon().iconName}/>
      </CoreIconButton>
    </CoreTooltip>
  );
}