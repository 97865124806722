import { ToolBoxData } from "./ToolBox.data";
import ToolBoxFactory from "../../../factory/ToolBox.factory";

/**
 * Represents the navigator toolbox data.
 * Extends the base `ToolBoxData` class to provide default configurations for a navigator toolbox.
 */
export class NavigatorToolBoxData extends ToolBoxData{
  
  constructor(
    toolboxId: string,
    toolboxTitle = "Navigator",
    toolBoxExpand = true,
    toolboxResize = "both"
  ){
    super(
      toolboxId,
      ToolBoxFactory.VARIANTS.NAVIGATOR,
      toolboxTitle, 
      toolBoxExpand, 
      toolboxResize);
  }

}