import { useState, useEffect } from "react";

import {
  CoreBox,
  CoreSelect,
  CoreMenuItem,
  CoreTable,
  CoreTableBody,
  CoreTableRow,
  CoreTableCell,
  CoreClasses,
  CoreTypographyBody2,
  CoreTypographyBody1
} from "@wrappid/core";
import { useDispatch } from "react-redux";

import { handlePropChange, renderInputForType } from "./propUtils";
import { updateComponentProps } from "../../../../actions/app.action";

export default function SelectProps({
  componentPath,
  component,
  initialProps = {},
  availableProps = [],
  StyleSelector = null
}) {
  const dispatch = useDispatch();
  const [propsValues, setPropsValues] = useState(initialProps);
  const [selectedType, setSelectedType] = useState({});

  useEffect(() => {
    setPropsValues(initialProps);
  }, [initialProps]);

  const handleChange = (propName, value, propType) => {
    const parsedValue = handlePropChange(value, propType);
    
    if (parsedValue === null) return;

    const newProps = {
      ...propsValues,
      [propName]: parsedValue,
    };

    setPropsValues(newProps);
    
    if (componentPath) {
      dispatch(updateComponentProps(componentPath, newProps));
    }
  };

  const renderPropInput = (prop) => {
    const currentValue = propsValues[prop.name] ?? "";
    const selectedPropType = selectedType[prop.name];

    if (prop.name === "styleClasses" && StyleSelector) {
      return <StyleSelector />;
    }

    if (prop.types && prop.types.length > 1) {
      return (
        <>
          <CoreSelect
            fullWidth
            value={selectedPropType || ""}
            onChange={(event) =>
              setSelectedType((prev) => ({
                ...prev,
                [prop.name]: event.target.value,
              }))
            }
            displayEmpty
            variant="standard"
          >
            <CoreMenuItem value="" disabled>
              Select Type
            </CoreMenuItem>

            {prop.types.map((typeObj, idx) => (
              <CoreMenuItem key={idx} value={typeObj.type}>
                {typeObj.type}
              </CoreMenuItem>
            ))}
          </CoreSelect>

          {selectedPropType &&
            renderInputForType({
              currentValue,
              onChange: handleChange,
              prop,
              type    : selectedPropType,
            })}
        </>
      );
    }

    const type = prop.types?.[0]?.type || typeof currentValue || "string";

    return renderInputForType({
      currentValue,
      onChange: handleChange,
      prop,
      type,
    });
  };

  if (!component) {
    return <CoreTypographyBody1>No component selected</CoreTypographyBody1>;
  }

  return (
    <CoreBox>
      <CoreBox
        styleClasses={[CoreClasses.DISPLAY.FLEX, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN]}
      >
        <CoreTypographyBody2>Properties - {component}</CoreTypographyBody2>
      </CoreBox>

      <CoreTable>
        <CoreTableBody styleClasses={[CoreClasses.BORDER.BORDER_STYLE_HIDDEN]}>
          {availableProps.map((prop) => (
            <CoreTableRow key={prop.name}>
              <CoreTableCell styleClasses={[CoreClasses.PADDING.P0]}>
                {prop.name}:
              </CoreTableCell>

              <CoreTableCell
                styleClasses={[CoreClasses.PADDING.P0, CoreClasses.BORDER.BO]}
              >
                {renderPropInput(prop)}
              </CoreTableCell>
            </CoreTableRow>
          ))}
        </CoreTableBody>
      </CoreTable>
    </CoreBox>
  );
}