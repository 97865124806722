import { AppBuilderContainerLayout } from "@wrappid/core";

import AppBuilder from "./components/AppBuilder.tsx";
import ReduxStoreDataViewer from "./components/editor/model/ReduxStoreDataViewer.tsx";
import HistoryChecker from "./components/HistoryChecker.tsx";
import PageBuilder from "./components/NOT_USED_OLD_CODE_SAMPLE/page-builder/PageBuilder.js";

export const RoutesRegistry = {
  AppBuilder: {
    Page        : { appComponent: AppBuilder.name, layout: AppBuilderContainerLayout.name },
    authRequired: true,
    entityRef   : "appBuilder",
    url         : "appBuilder"
  },
  HistoryChecker: {
    Page        : { appComponent: HistoryChecker.name, layout: AppBuilderContainerLayout.name },
    authRequired: true,
    entityRef   : "historyChecker",
    url         : "historyChecker"
  },
  PageBuilder: {
    Page        : { appComponent: PageBuilder.name, layout: AppBuilderContainerLayout.name },
    authRequired: true,
    entityRef   : "pageBuilder",
    url         : "pageBuilder"
  },
  ReduxStoreDataViewer: {
    Page        : { appComponent: ReduxStoreDataViewer.name, layout: AppBuilderContainerLayout.name },
    authRequired: true,
    entityRef   : "history",
    url         : "history"
  }
};
