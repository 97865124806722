export function convertPatientData(data){
  let convertedData = {};

  convertedData = data?.Patient || data?.Person;
    
  if(convertedData && convertedData?.PersonMetas && convertedData?.PersonMetas?.length > 0){
    convertedData?.PersonMetas?.forEach((item) => {
      convertedData[item?.key] = item?.value;
    });
      
    delete convertedData.PersonMetas;
  }
    
  return convertedData;
}

export function convertClinicData(data) {
  return {
    addLine1  : data?.Address?.addLine1,
    addLine2  : data?.Address?.addLine2,
    city      : data?.Address?.city,
    clinicLogo: data?.photoUrl,
    country   : data?.Address?.country,
    district  : data?.Address?.district,
    fullName  : data?.name,
    phone     : data?.Address?.phone,
    pin       : data?.Address?.pin,
    state     : data?.Address?.state
  };
}