/* eslint-disable etc/no-commented-out-code */
import { useEffect, useState } from "react";

import {
  apiRequestAction,
  AppContainerLayout,
  CoreClasses,
  CoreDivider,
  CoreForm,
  CoreGrid,
  CoreLayoutItem,
  FORM_EDIT_MODE,
  FORM_IDS,
  FORM_VIEW_MODE,
  HTTP
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

// -- import RssFeed from "../utility/RssFeed";

function UserProfile() {
  const dispatch = useDispatch();
  const [formsMode, setFormsMode] = useState({
    [FORM_IDS.__PROFILE_BASIC]       : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_CONTACT]     : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_CLINIC]      : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_REGISTRATION]: FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_EDUCATION]   : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_EXPERIENCE]  : FORM_VIEW_MODE,
  });

  const { user: { id: userID, personID }, role } = useSelector((state) => state.auth); // --- auth, navData
  const state = useSelector((state) => state); // --- auth, navData

  // eslint-disable-next-line no-console
  console.log("STATE", state);

  /**
   * @todo
   * Remove this api call
   */
  // --- componentDidMount
  useEffect(() => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        "/addressType",
        true,
        null,
        "GET_ADRESS_TYPE_SUCCESS",
        "GET_ADRESS_TYPE_ERROR"
      )
    );
  }, []);

  // eslint-disable-next-line no-unused-vars
  const ToggleMode = (formId) => {
    setFormsMode({
      ...formsMode,
      [formId]:
        formsMode[formId] === FORM_VIEW_MODE ? FORM_EDIT_MODE : FORM_VIEW_MODE,
    });
  };

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreGrid
          coreId="userProfileGrid"
          styleClasses={[CoreClasses.PADDING.P1, CoreClasses.MARGIN.MT]}>
          <CoreForm
            coreId="basicInfo"
            allowDelete={false}
            formId={FORM_IDS.__PROFILE_BASIC}
            mode={formsMode[FORM_IDS.__PROFILE_BASIC]}
            query={{
              _defaultFilter: encodeURIComponent(
                JSON.stringify({ userId: userID })
              ),
            }}
          />

          <CoreDivider />

          <CoreForm
            coreId="contactInfo"
            allowDelete={false}
            allowEdit={false}
            formId={FORM_IDS.__PROFILE_CONTACT}
            notEditable={true}
          />

          <CoreDivider />

          <CoreGrid columnSpacing={6}>
            {role?.role &&
                  role?.role.toLowerCase() === "doctor" && (
              <CoreForm
                coreId="registrationInfo"
                formId={FORM_IDS.__PROFILE_REGISTRATION}
                allowDelete={false}
                gridProps={{
                  gridSize: {
                    sm: 6,
                    xs: 12,
                  },
                }}
                mode={formsMode[FORM_IDS.__PROFILE_REGISTRATION]}
              />
            )}

            {role?.role &&
                  role?.role.toLowerCase() === "doctor" && (
              <CoreForm
                arrayView={true}
                coreId="clinicInfo"
                formId={FORM_IDS.__PROFILE_CLINIC}
                gridProps={{
                  gridSize: {
                    sm: 6,
                    xs: 12,
                  },
                }}
                mode={formsMode[FORM_IDS.__PROFILE_CLINIC]}
                arrayDataLimit={2}
                query={{
                  _defaultFilter: encodeURIComponent(
                    JSON.stringify({ personId: personID })
                  ),
                }}
              />
            )}

            {role?.role &&
                  role?.role.toLowerCase() === "doctor" && <CoreDivider />}
          </CoreGrid>

          <CoreForm
            styleClasses={[CoreClasses.PADDING.PR1]}
            arrayView={true}
            coreId="educationInfo"
            formId={FORM_IDS.__PROFILE_EDUCATION}
            gridProps={{
              gridSize: {
                sm: 6,
                xs: 12,
              },
            }}
            mode={formsMode[FORM_IDS.__PROFILE_EDUCATION]}
            arrayDataLimit={2}
            query={{
              _defaultFilter: encodeURIComponent(
                JSON.stringify({ personId: personID })
              ),
            }}
          />
                
          <CoreForm
            styleClasses={[CoreClasses.PADDING.PR1]}
            arrayView={true}
            coreId="experienceInfo"
            formId={FORM_IDS.__PROFILE_EXPERIENCE}
            gridProps={{
              gridSize: {
                sm: 6,
                xs: 12,
              },
            }}
            mode={formsMode[FORM_IDS.__PROFILE_EXPERIENCE]}
            arrayDataLimit={2}
            query={{
              _defaultFilter: encodeURIComponent(
                JSON.stringify({ personId: personID })
              ),
            }}
            // --- afterCreateError={() => {
            //   alert("CREATE error hook");
            // }}
            // afterEditError={() => {
            //   alert("EDIT error hook");
            // }}
            // afterDeleteError={() => {
            //   alert("DELETE error hook");
            // }}
            // afterCreateSuccess={() => {
            //   alert("CREATE success hook");
            // }}
            // afterEditSuccess={() => {
            //   alert("EDIT success hook");
            // }}
            // afterDeleteSuccess={() => {
            //   alert("DELETE success hook");
            // }}
          />
                
          {/* <CoreForm
                  arrayView={true}
                  formId={FORM_IDS.__CREATE_PATIENT}
                  gridProps={{
                    gridSize: {
                      sm: 6,
                      xs: 12,
                    },
                  }}
                  mode={FORM_VIEW_MODE}
                /> */}
        </CoreGrid>

        {/* -- <CoreCard gridProps={{ gridSize: { sm: 4 } }}>
            <CoreCardContent><RssFeed /></CoreCardContent>
          </CoreCard> */}
      </CoreLayoutItem>
    </>
  );
}

export default UserProfile;
